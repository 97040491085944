import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getFirestore, setDoc, doc } from 'firebase/firestore';
import AdditionalInfoForm, { AdditionalInfo } from '../../components/forms/AdditionalInfoForm';

const AdditionalInfoFormWrapper: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const additionalInfo = location.state?.additionalInfo;

  const [info, setInfo] = useState<AdditionalInfo>(additionalInfo || { name: '', city: '', grade: '', subject: '', school: '' });

  const handleAdditionalInfoSubmit = async (info: AdditionalInfo) => {
    // Save the updated info to Firestore
    const auth = getAuth();
    const db = getFirestore();
    if (auth.currentUser) {
      const userDoc = doc(db, 'users', auth.currentUser.uid);
      await setDoc(userDoc, info, { merge: true });
      // Check if we have a saved location to return to
      const state = location.state as { from?: { pathname: string } };
      navigate(state?.from?.pathname || '/views');
    }
  };

  return (
    <AdditionalInfoForm
      additionalInfo={info}
      setAdditionalInfo={setInfo}
      handleAdditionalInfoSubmit={handleAdditionalInfoSubmit}
    />
  );
};

export default AdditionalInfoFormWrapper; 