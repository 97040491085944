import { v4 as uuidv4 } from "uuid";

export interface Question {
  id: string;
  text: string;
  options?: string[];
  correctOption?: number;
  explanation?: string;
  type: "multiple-choice" | "short-answer" | "essay";
  points: number;
}

export interface Assignment {
  id: string;
  title: string;
  description: string;
  subject: string;
  gradeLevel: string;
  questions: Question[];
  dueDate?: Date;
  maxPoints?: number;
  created: Date;
  modified: Date;
  createdBy: string;
  googleClassroomCourseId?: string;
  googleClassroomAssignmentId?: string;
  googleDocUrl?: string;
  published: boolean;
  tags?: string[];
  content?: string; // Full markdown content of the assignment
}

export interface StudentAssignmentResponse {
  assignmentId: string;
  studentId: string;
  answers: {
    questionId: string;
    answer: string | number;
  }[];
  score?: number;
  feedback?: string;
  submitted: Date;
  gradedBy?: string;
  gradedAt?: Date;
}

export function createNewAssignment(teacherId: string): Assignment {
  return {
    id: uuidv4(),
    title: "",
    description: "",
    subject: "",
    gradeLevel: "",
    questions: [],
    created: new Date(),
    modified: new Date(),
    createdBy: teacherId,
    published: false,
  };
}

export function createNewQuestion(
  type: "multiple-choice" | "short-answer" | "essay"
): Question {
  return {
    id: uuidv4(),
    text: "",
    type,
    points: 1,
    options: type === "multiple-choice" ? ["", "", "", ""] : undefined,
    correctOption: type === "multiple-choice" ? 0 : undefined,
  };
}

export function calculateAssignmentScore(
  assignment: Assignment,
  responses: { questionId: string; answer: string | number }[]
): number {
  let totalScore = 0;

  for (const response of responses) {
    const question = assignment.questions.find(
      (q) => q.id === response.questionId
    );

    if (!question) continue;

    if (
      question.type === "multiple-choice" &&
      question.correctOption !== undefined
    ) {
      if (Number(response.answer) === question.correctOption) {
        totalScore += question.points;
      }
    }
    // For short-answer and essay questions, they need manual grading
  }

  return totalScore;
}
