import React, { useEffect, useRef, useLayoutEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../features/auth/AuthProvider';
import GoogleClassroomIntegrationPage from  '../../features/classroom/GoogleClassroomIntegrationPage';
import { logWithTimestamp } from '../../utils/logging';

// ClassroomPageWrapper component
const ClassroomPageWrapper: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const renderCountRef = useRef(0);
  const pathStabilityCheck = useRef(false);
  
  // Use immediate code execution for critical path protection
  if (location.pathname !== '/classroom') {
    // Immediately correct path without waiting for effects
    window.history.replaceState(null, '', '/classroom');
  }
  
  // Set flag that we're on classroom page
  sessionStorage.setItem('on_classroom_page', 'true');
  
  // Increment render count
  renderCountRef.current++;
  
  logWithTimestamp('ClassroomPageWrapper', `Render #${renderCountRef.current}`, location.pathname);
  
  // Safety check to ensure we stay on classroom path
  useLayoutEffect(() => {
    logWithTimestamp('ClassroomPageWrapper', 'Layout Effect running', location.pathname);
    
    // Stabilize the path if needed
    if (location.pathname !== '/classroom') {
      logWithTimestamp('ClassroomPageWrapper', 'Path drift detected in wrapper, correcting to /classroom');
      // Use both navigate and direct history manipulation
      navigate('/classroom', { replace: true });
      window.history.replaceState(null, '', '/classroom');
    } else if (!pathStabilityCheck.current) {
      // Mark that we're stable on first correct render
      pathStabilityCheck.current = true;
      logWithTimestamp('ClassroomPageWrapper', 'Path is stable at /classroom');
    }
    
    // Create a MutationObserver as a backup for path protection
    const observer = new MutationObserver(() => {
      if (window.location.pathname !== '/classroom') {
        logWithTimestamp('ClassroomPageWrapper', 'MutationObserver caught path change, restoring');
        window.history.replaceState(null, '', '/classroom');
      }
    });
    
    // Start observing
    observer.observe(document, { subtree: true, childList: true });
    
    return () => {
      logWithTimestamp('ClassroomPageWrapper', 'Layout Effect cleanup', location.pathname);
      observer.disconnect();
    };
  }, [location.pathname, navigate]);

  // Handle page refresh/unload
  useEffect(() => {
    // Setup unload handler
    const handleBeforeUnload = () => {
      // Ensure path preservation flag is set
      sessionStorage.setItem('on_classroom_page', 'true');
      logWithTimestamp('ClassroomPageWrapper', 'BeforeUnload: Classroom flag set');
    };
    
    window.addEventListener('beforeunload', handleBeforeUnload);
    
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      
      // Only clear flag if we're not refreshing
      if (document.visibilityState !== 'hidden') {
        sessionStorage.removeItem('on_classroom_page');
        logWithTimestamp('ClassroomPageWrapper', 'Normal unmount, clearing classroom flag');
      } else {
        logWithTimestamp('ClassroomPageWrapper', 'Page being hidden/refreshed, keeping session data');
      }
    };
  }, []);

  return <GoogleClassroomIntegrationPage />;
};

// Main ClassroomRoute component
const ClassroomRoute: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const mountedRef = useRef(false);
  const isRenderingRef = useRef(true);
  const inClassroomRef = useRef(false);
  
  logWithTimestamp('ClassroomRoute', 'Initializing...', location.pathname);
  
  // Use immediate code execution to force the path if needed
  if (location.pathname !== '/classroom') {
    logWithTimestamp('ClassroomRoute', 'IMMEDIATE PATH CORRECTION from', location.pathname);
    // Use history API directly for immediate effect
    window.history.replaceState(null, '', '/classroom');
  }
  
  // Set session flag immediately
  sessionStorage.setItem('on_classroom_page', 'true');
  
  // Use layout effect to immediately intercept and capture the classroom path
  useLayoutEffect(() => {
    logWithTimestamp('ClassroomRoute', 'Layout Effect - Initializing for path', location.pathname);
    
    // Mark that we're in classroom route
    inClassroomRef.current = true;
    
    // Ensure we're on the exact classroom path with no trailing characters
    // This fixes issues with trailing slashes or other fragments
    if (location.pathname !== '/classroom') {
      logWithTimestamp('ClassroomRoute', 'Correcting path from', location.pathname, 'to /classroom');
      // Use the navigate API as a backup
      navigate('/classroom', { replace: true });
      // Also use history API directly for immediate effect
      window.history.replaceState(null, '', '/classroom');
    }
    
    // Create a MutationObserver to check if URL is changing via DOM
    const observer = new MutationObserver((mutations) => {
      // If we detect we're not on classroom path, force it back
      if (window.location.pathname !== '/classroom' && inClassroomRef.current) {
        logWithTimestamp('ClassroomRoute', 'MUTATION DETECTED: Forcing back to /classroom');
        window.history.replaceState(null, '', '/classroom');
      }
    });
    
    // Start observing
    observer.observe(document, { subtree: true, childList: true });
    
    // Setup capture for all key events to prevent browser-level navigation
    const handleKeyDown = (e: KeyboardEvent) => {
      // If F5 is pressed alongside any modifier, don't interfere (e.g., Ctrl+R)
      if ((e.key === 'F5' || e.key === 'r') && (e.ctrlKey || e.metaKey)) {
        logWithTimestamp('ClassroomRoute', 'Allowing refresh via keyboard', e.key);
        return;
      }
    };
    
    // Setup beforeunload handler to ensure we keep the classroom flag
    const handleBeforeUnload = () => {
      logWithTimestamp('ClassroomRoute', 'Page unloading, preserving classroom flag');
      sessionStorage.setItem('on_classroom_page', 'true');
    };
    
    // Add listeners
    window.addEventListener('keydown', handleKeyDown, true);
    window.addEventListener('beforeunload', handleBeforeUnload);
    
    return () => {
      logWithTimestamp('ClassroomRoute', 'Layout Effect cleanup');
      inClassroomRef.current = false;
      window.removeEventListener('keydown', handleKeyDown, true);
      window.removeEventListener('beforeunload', handleBeforeUnload);
      observer.disconnect();
    };
  }, [navigate, location.pathname]);
  
  // Run a regular effect to handle authentication
  useEffect(() => {
    logWithTimestamp('ClassroomRoute', 'Effect running', location.pathname, 'User:', !!user);
    
    if (!mountedRef.current) {
      mountedRef.current = true;
      logWithTimestamp('ClassroomRoute', 'First mount of ClassroomRoute');
    }
    
    // Only check if user is authenticated
    if (!user) {
      logWithTimestamp('ClassroomRoute', 'User not authenticated, redirecting to login');
      sessionStorage.setItem('redirect_after_login', '/classroom');
      navigate('/login', { state: { from: { pathname: '/classroom' } }, replace: true });
      return;
    }
    
    // If we're not on the classroom path for some reason, correct it
    if (location.pathname !== '/classroom') {
      logWithTimestamp('ClassroomRoute', 'Path drift detected, correcting to /classroom');
      navigate('/classroom', { replace: true });
      // Also use history API directly
      window.history.replaceState(null, '', '/classroom');
    }
    
    return () => {
      isRenderingRef.current = false;
      // Only remove if we're actually navigating away, not refreshing
      if (document.visibilityState !== 'hidden') {
        sessionStorage.removeItem('on_classroom_page');
      }
    };
  }, [user, navigate, location.pathname]);
  
  // Always render classroom page if we reach this point
  logWithTimestamp('ClassroomRoute', 'Rendering classroom page');
  return user ? <ClassroomPageWrapper /> : null;
};

export { ClassroomRoute, ClassroomPageWrapper };
export default ClassroomRoute; 