import React, { useState, useEffect, useCallback } from 'react';
import { Button, Select, Form, message, Typography, Spin, Modal, Alert, Space, Tooltip, Card } from 'antd';
import FirebaseClassroomService from '../../services/FirebaseClassroomService';
import GoogleClassroomService from '../../services/GoogleClassroomService';
import { Assignment } from '../../models/Assignment';
import { getAuth } from 'firebase/auth';
import { DisconnectOutlined, BugOutlined } from '@ant-design/icons';
import axios from 'axios';
import { DocumentLinksService } from '../../services/documentLinksService';

const { Title, Text } = Typography;
const { Option } = Select;

interface ClassroomCourse {
  id: string;
  name: string;
  section?: string;
  description?: string;
}

// Extend the Assignment interface to include content
interface ExtendedAssignment extends Assignment {
  content?: string;
}

interface GoogleClassroomIntegrationProps {
  assignment: ExtendedAssignment;
  onAssignmentUpdated: (updatedAssignment: Assignment) => void;
  onSuccessClose?: () => void; // Optional callback when assignment is successfully created
}

const GoogleClassroomIntegration: React.FC<GoogleClassroomIntegrationProps> = ({
  assignment,
  onAssignmentUpdated,
  onSuccessClose
}) => {
  const [courses, setCourses] = useState<ClassroomCourse[]>([]);
  const [selectedCourseId, setSelectedCourseId] = useState<string>(assignment.googleClassroomCourseId || '');
  const [loading, setLoading] = useState<boolean>(false);
  const [googleConnected, setGoogleConnected] = useState<boolean>(false);
  const [publishModalVisible, setPublishModalVisible] = useState<boolean>(false);
  const [successModalVisible, setSuccessModalVisible] = useState<boolean>(false);
  const [classroomService] = useState(new FirebaseClassroomService());
  const [firebaseService] = useState(GoogleClassroomService);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [hasReconnected, setHasReconnected] = useState<boolean>(false);

  useEffect(() => {
    // If the assignment already has a Google Classroom course ID, select it
    if (assignment.googleClassroomCourseId) {
      setSelectedCourseId(assignment.googleClassroomCourseId);
    }
    
    // Check if user is already connected to Google Classroom
    checkClassroomConnection();
  }, [assignment]);

  const checkClassroomConnection = useCallback(async () => {
    try {
      const accessResult = await classroomService.checkClassroomAccess();
      if (accessResult.hasAccess) {
        setGoogleConnected(true);
        
        // Check token status
        const tokenStatus = await classroomService.checkTokens();
        console.log('Token status:', tokenStatus);
        
        if (!tokenStatus.hasTokens) {
          console.warn('No Google Classroom tokens found for this user');
          message.warning('Your Google connection needs to be refreshed. Please reconnect to Google Classroom.');
          setGoogleConnected(false);
          return;
        }
        
        if (tokenStatus.isExpired) {
          console.warn('Google Classroom tokens are expired');
          message.warning('Your Google connection has expired. Please reconnect to Google Classroom.');
          setGoogleConnected(false);
          return;
        }
        
        if (!tokenStatus.hasClassroomCoursesScope) {
          console.warn('Missing classroom.courses.readonly scope');
          message.warning('Your Google connection is missing required permissions. Please reconnect to Google Classroom.');
          setGoogleConnected(false);
          return;
        }
        
        // If we get here, the token is valid
        await fetchCourses();
      }
    } catch (error) {
      console.error('Error checking Google Classroom connection:', error);
    }
  }, [classroomService]);

  const handleGoogleSignIn = useCallback(async () => {
    try {
      setLoading(true);
      
      // We'll use the existing Firebase Authentication with Google
      // This is handled through GoogleClassroomConsentFlow
      // For now, we just check if the access is already available
      const accessResult = await classroomService.checkClassroomAccess();
      
      if (accessResult.hasAccess) {
        setGoogleConnected(true);
        await fetchCourses();
        message.success('Connected to Google Classroom successfully!');
      } else {
        // Show the consent flow modal in the parent component
        message.error('Please complete the Google Classroom authorization flow.');
      }
    } catch (error: any) {
      console.error('Error connecting to Google Classroom:', error);
      message.error(`Error connecting to Google Classroom: ${error.message || 'Please try again.'}`);
    } finally {
      setLoading(false);
    }
  }, [classroomService]);

  const fetchCourses = useCallback(async () => {
    try {
      setLoading(true);
      console.log('Fetching courses from Google Classroom');
      const fetchedCourses = await classroomService.getCourses();
      console.log(`Fetched ${fetchedCourses.length} courses from Google Classroom:`, fetchedCourses);
      setCourses(fetchedCourses);
      
      if (fetchedCourses.length === 0) {
        message.warning(
          'No courses found in your Google Classroom account. Please make sure you have created courses in Google Classroom and have the appropriate permissions.'
        );
      }
      
      return fetchedCourses;
    } catch (error: any) {
      console.error('Error fetching courses:', error);
      message.error(`Error fetching courses from Google Classroom: ${error.message || 'Unknown error'}`);
      return [];
    } finally {
      setLoading(false);
    }
  }, [classroomService]);

  const handleCourseChange = useCallback((courseId: string) => {
    setSelectedCourseId(courseId);
    
    // Update the assignment with the selected course ID
    const updatedAssignment = {
      ...assignment,
      googleClassroomCourseId: courseId
    };
    
    onAssignmentUpdated(updatedAssignment);
  }, [assignment, onAssignmentUpdated]);

  const handlePublishToClassroom = useCallback(async () => {
    if (!selectedCourseId) {
      message.warning('Please select a Google Classroom course first.');
      return;
    }

    try {
      setLoading(true);
      message.info('Creating assignment in Google Classroom...');

      // First, export the content to Google Drive
      const api_uri = process.env.REACT_APP_API_URI ?? "";
      if (!api_uri) {
        message.error('API URI is not configured');
        return;
      }
      
      // Get current user
      const auth = getAuth();
      if (!auth.currentUser) {
        message.error('User not authenticated');
        return;
      }
      
      // Create Google Doc first
      message.loading('Creating Google Doc...', 1);
      
      // Get content from assignment
      let markdownContent = '';
      if (assignment.content) {
        markdownContent = assignment.content;
      } else if (assignment.questions && assignment.questions.length > 0) {
        // If no content but has questions, format questions as content
        markdownContent = `# ${assignment.title || 'Assignment'}\n\n`;
        
        if (assignment.description) {
          markdownContent += `${assignment.description}\n\n`;
        }
        
        assignment.questions.forEach((q, index) => {
          markdownContent += `## Question ${index + 1}: ${q.text}\n`;
          if (q.options && q.options.length > 0) {
            q.options.forEach((opt, i) => {
              markdownContent += `${String.fromCharCode(65 + i)}. ${opt}\n`;
            });
          }
          markdownContent += '\n';
        });
      } else {
        markdownContent = assignment.description || assignment.title || 'Assignment';
      }
      
      // Create document in Google Drive
      const serverResponse = await axios.post(`${api_uri}/create-doc`, {
        firebase_user_id: auth.currentUser.uid,
        markdown_text: markdownContent,
        card_id: 'assignment',
        topic: assignment.title
      });

      const { document_url } = serverResponse.data;
      
      // Save the document link to Firestore
      const documentLinksService = new DocumentLinksService();
      await documentLinksService.saveDocumentLink({
        userId: auth.currentUser.uid,
        documentUrl: document_url,
        topic: assignment.title,
        cardId: 'assignment',
        title: assignment.title
      });

      // If we have content, create a more detailed description
      let description = assignment.description || '';
      
      // If description is empty, set a default
      if (!description || description.trim() === '') {
        description = assignment.title || 'Assignment';
      }
      
      // Append content excerpt if available
      if (assignment.content) {
        // description = `${description}\n\n${assignment.content.substring(0, 500)}${assignment.content.length > 500 ? '...' : ''}`;
        description = `${description}\nTopic: ${assignment.title}\nSubject: ${assignment.subject}\nGrade : ${assignment.gradeLevel}`;
      }
      
      console.log('Using description for Classroom assignment:', description);

      // Log the data we're sending to the API
      console.log('Creating Google Classroom assignment with data:', {
        courseId: selectedCourseId,
        assignment: {
          title: assignment.title || 'Untitled Assignment',
          description: description,
          // workType: 'ASSIGNMENT',
          // state: 'DRAFT',
          maxPoints: 100,
          materials: [{ link: { url: document_url } }]
        }
      });

      // Create the assignment in Google Classroom using our Firebase Function
      const classroomAssignment = await classroomService.createAssignment(selectedCourseId, {
        title: assignment.title || 'Untitled Assignment',
        description: description,
        maxPoints: assignment.maxPoints || assignment.questions.reduce((total, q) => total + q.points, 0),
        materials: [{ 
          link: { 
            url: document_url
          } 
        }]
      });

      // Log the response
      console.log('Google Classroom assignment created:', classroomAssignment);

      if (classroomAssignment) {
        // Update the assignment with Google Classroom assignment ID
        const updatedAssignment = {
          ...assignment,
          googleClassroomAssignmentId: classroomAssignment.id,
          googleClassroomCourseId: selectedCourseId,
          googleDocUrl: document_url, // Store the Google Doc URL
          published: true // Mark as published immediately
        };
        
        onAssignmentUpdated(updatedAssignment);
        setSuccessModalVisible(true);
        message.success('Assignment created in Google Classroom!');
        
        // No auto-close functionality
      }
    } catch (error: any) {
      console.error('Error publishing to Google Classroom:', error);
      
      // Display a more detailed error message
      message.error(`Error creating assignment in Google Classroom: ${error.message || 'Unknown error'}`);
      
      // Check if it might be a scope/permission issue
      if (error.message && error.message.toLowerCase().includes('permission')) {
        message.warning('This might be a permission issue. Try reconnecting to Google Classroom.');
      }
      
      // Check if it's a Firebase internal error
      if (error.message && error.message.includes('internal')) {
        message.warning('There was an internal server error. Please check the Firebase logs for more details.');
      }
    } finally {
      setLoading(false);
    }
  }, [selectedCourseId, assignment, classroomService, onAssignmentUpdated]);

  const handlePublishAssignment = useCallback(async () => {
    if (!assignment.googleClassroomCourseId || !assignment.googleClassroomAssignmentId) {
      message.warning('Assignment has not been created in Google Classroom yet.');
      return;
    }

    try {
      setLoading(true);
      
      // Publish the assignment in Google Classroom using our Firebase Function
      const publishedAssignment = await classroomService.publishAssignment(
        assignment.googleClassroomCourseId,
        assignment.googleClassroomAssignmentId
      );
      
      if (publishedAssignment) {
        // Update the assignment as published
        const updatedAssignment = {
          ...assignment,
          published: true
        };
        
        onAssignmentUpdated(updatedAssignment);
        message.success('Assignment published to Google Classroom successfully!');
        setPublishModalVisible(false);
      } else {
        message.error('Failed to publish assignment in Google Classroom.');
      }
    } catch (error: any) {
      console.error('Error publishing assignment:', error);
      message.error(`Error publishing assignment to Google Classroom: ${error.message || 'Unknown error'}`);
    } finally {
      setLoading(false);
    }
  }, [assignment, classroomService, onAssignmentUpdated]);

  // const handleDebugCourses = async () => {
  //   try {
  //     setLoading(true);
  //     console.log('Running courses debug test');
      
  //     const debugResult = await classroomService.debugGetCourses();
  //     console.log('Debug result:', debugResult);
      
  //     if (debugResult.error) {
  //       message.error(`Debug error: ${debugResult.error}`);
  //     } else if (debugResult.success) {
  //       message.success(`Found ${debugResult.totalCoursesCount} courses. Teacher: ${debugResult.teacherCoursesCount}, Student: ${debugResult.studentCoursesCount}`);
        
  //       if (debugResult.totalCoursesCount > 0) {
  //         // Update courses with the debug result
  //         const formattedCourses = debugResult.courses.map((course: any) => ({
  //           id: course.id,
  //           name: course.name,
  //           section: course.section
  //         }));
          
  //         setCourses(formattedCourses);
  //         message.info('Updated course list with debug results');
  //       }
  //     }
  //   } catch (error: any) {
  //     console.error('Error in debug function:', error);
  //     message.error(`Debug function error: ${error.message || 'Unknown error'}`);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleDirectCheck = async () => {
  //   try {
  //     setLoading(true);
  //     message.info('Running direct check for courses...');
      
  //     const result = await classroomService.directCheckClassroomCourses();
  //     console.log('Direct check result:', result);
      
  //     if (result.error) {
  //       message.error(`Direct check error: ${result.error}`);
  //       if (result.message) {
  //         message.error(`Error details: ${result.message}`);
  //       }
        
  //       // Check if we have scope information
  //       if (result.scopes) {
  //         console.log('User has the following scopes:', result.scopes);
          
  //         const hasCourseworkScope = result.scopes.some((scope: string) => 
  //           scope.includes('classroom.coursework.students') || 
  //           scope.includes('classroom.coursework.me')
  //         );
          
  //         if (!hasCourseworkScope) {
  //           message.warning('Missing permissions to create assignments. Please reconnect to Google Classroom.');
  //         }
  //       }
  //     } else if (result.success) {
  //       // Set Google connected to true when direct check is successful
  //       setGoogleConnected(true);
        
  //       if (result.hasTeacherCourses) {
  //         message.success(`Found ${result.teacherCourseCount} teacher courses!`);
          
  //         // Update courses with the result
  //         const formattedCourses = result.teacherCourses.map((course: any) => ({
  //           id: course.id,
  //           name: course.name,
  //           section: course.section || ''
  //         }));
          
  //         setCourses(formattedCourses);
  //         message.info('Updated course list with direct check results');
          
  //         // Display coursework permissions status
  //         if (result.hasCourseworkPermissions === false) {
  //           message.warning('You have permission to view courses, but may not have permission to create assignments. Consider reconnecting to Google Classroom.');
            
  //           if (result.courseworkTestError) {
  //             console.error('Coursework permission test error:', result.courseworkTestError);
  //           }
  //         } else if (result.hasCourseworkPermissions === true) {
  //           message.success('You have all required permissions to create assignments.');
  //         }
  //       } else {
  //         message.warning('No teacher courses found. Please create a course in Google Classroom first.');
  //       }
  //     }
  //   } catch (error: any) {
  //     console.error('Error in direct check:', error);
  //     message.error(`Direct check error: ${error.message || 'Unknown error'}`);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleReconnect = useCallback(async () => {
  //   if (hasReconnected) {
  //     message.info('You have already reconnected in this session. Please try again later.');
  //     return;
  //   }

  //   try {
  //     setLoading(true);
  //     message.info('Disconnecting from Google Classroom...');
      
  //     // First, clear the credentials
  //     const clearResult = await classroomService.clearGoogleCredentials();
      
  //     if (clearResult.success) {
  //       message.success('Successfully disconnected from Google Classroom');
  //       setGoogleConnected(false);
  //       setHasReconnected(true);
        
  //       // Store reconnection status in session storage
  //       sessionStorage.setItem('googleClassroomReconnected', 'true');
        
  //       // Wait a moment before triggering re-connection
  //       setTimeout(() => {
  //         message.info('Please reconnect to Google Classroom with the updated permissions');
  //       }, 1000);
  //     } else {
  //       message.error(`Failed to disconnect: ${clearResult.message}`);
  //     }
  //   } catch (error: any) {
  //     console.error('Error reconnecting:', error);
  //     message.error(`Reconnection error: ${error.message || 'Unknown error'}`);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [classroomService, hasReconnected]);

  // Check session storage for reconnection status on mount
  useEffect(() => {
    const reconnected = sessionStorage.getItem('googleClassroomReconnected');
    if (reconnected === 'true') {
      setHasReconnected(true);
    }
  }, []);

  return (
    <div className="classroom-integration-container">
      <Title level={4}>Google Classroom Integration</Title>
      
      {!googleConnected ? (
        <div className="connect-google-section">
          <Text>Connect to Google Classroom to share this assignment with your students.</Text>
          <Button 
            type="primary" 
            onClick={handleGoogleSignIn} 
            loading={loading}
            style={{ marginTop: '10px' }}
          >
            Connect to Google Classroom
          </Button>
        </div>
      ) : (
        <div className="classroom-integration-controls">
          <Form layout="vertical">
            <Form.Item label="Select a classroom course">
              <Select
                placeholder="Select a course"
                onChange={handleCourseChange}
                value={selectedCourseId || undefined}
                style={{ width: '100%' }}
                loading={loading}
                notFoundContent={loading ? <Spin size="small" /> : 'No courses found'}
              >
                {courses.map(course => (
                  <Option key={course.id} value={course.id}>
                    {course.name} {course.section ? `(${course.section})` : ''}
                  </Option>
                ))}
              </Select>
              {courses.length === 0 && !loading && (
                <Text type="warning" style={{ display: 'block', marginTop: '8px' }}>
                  No courses found. You may need to create a course in Google Classroom first or refresh the connection.
                </Text>
              )}
            </Form.Item>

            {assignment.content && (
              <Alert
                message="Content Preview" 
                description={
                  <div style={{ maxHeight: '100px', overflow: 'auto' }}>
                    {assignment.content.substring(0, 200)}
                    {assignment.content.length > 200 ? '...' : ''}
                  </div>
                }
                type="info"
                showIcon
                style={{ marginBottom: '16px' }}
              />
            )}
            
            <Button
              type="primary"
              onClick={handlePublishToClassroom}
              disabled={!selectedCourseId || loading}
              loading={loading}
            >
              Create in Google Classroom
            </Button>
            
            {/* <Button
              onClick={fetchCourses}
              style={{ marginLeft: '10px' }}
              loading={loading}
            >
              Refresh Courses
            </Button> */}
            
            {/* <Button
              onClick={handleDebugCourses}
              style={{ marginLeft: '10px' }}
              loading={loading}
              danger
            >
              Debug Courses
            </Button> */}
            
            {/* <Button
              onClick={handleDirectCheck}
              style={{ marginLeft: '10px' }}
              loading={loading}
              type="primary"
              danger
            >
              Direct Check
            </Button>
            
            <Button
              onClick={handleReconnect}
              style={{ marginLeft: '10px', marginTop: '10px' }}
              loading={loading}
              type="dashed"
              danger
              disabled={hasReconnected}
            >
              Reconnect to Google Classroom
            </Button> */}
          </Form>
        </div>
      )}

      {/* <Modal
        title="Publish Assignment to Students"
        open={publishModalVisible}
        onCancel={() => setPublishModalVisible(false)}
        destroyOnClose={false}
        maskClosable={false}
        footer={[
          <Button key="back" onClick={() => setPublishModalVisible(false)}>
            Save as Draft
          </Button>,
          <Button 
            key="submit" 
            type="primary" 
            onClick={handlePublishAssignment}
            loading={loading}
          >
            Publish Now
          </Button>
        ]}
      >
        <p>
          Your assignment has been created in Google Classroom as a draft.
        </p>
        <p>
          Would you like to publish it now to make it visible to students? 
          Or save it as a draft for later publishing?
        </p>
      </Modal> */}

      <Modal
        title="Assignment Published"
        open={successModalVisible}
        onCancel={() => {
          setSuccessModalVisible(false);
        }}
        maskClosable={false}
        keyboard={false}
        closable={true}
        footer={[
          <Button 
            key="close" 
            type="primary" 
            onClick={() => {
              setSuccessModalVisible(false);
              if (onSuccessClose) onSuccessClose();
            }}
          >
            Close
          </Button>
        ]}
      >
        <p>
          Your assignment has been published to Google Classroom successfully!
        </p>
      </Modal>
    </div>
  );
};

export default GoogleClassroomIntegration; 