import React from 'react';
import { Spin, Progress } from 'antd';

interface LoadingStateProps {
  type: 'initial' | 'similar';
  progress?: { current: number; total: number };
}

const LoadingState: React.FC<LoadingStateProps> = ({ type, progress }) => {
  const initialLoadingMessages = [
    "Preparing your quiz questions...",
    "Setting up your learning experience...",
    "Gathering knowledge for you..."
  ];
  
  const similarQuestionMessages = [
    "Finding a similar question for you...",
    "Generating a related challenge...",
    "Creating a variation to test your understanding..."
  ];
  
  const messages = type === 'initial' ? initialLoadingMessages : similarQuestionMessages;
  const randomMessage = messages[Math.floor(Math.random() * messages.length)];
  
  return (
    <div className="loading-state-container">
      <div className="loading-content">
        {type === 'initial' && progress && (
          <div className="loading-progress">
            <Progress 
              type="circle" 
              percent={Math.round((progress.current / progress.total) * 100)} 
              width={80}
              strokeColor="#21b573"
            />
          </div>
        )}
        
        {type === 'similar' && (
          <div className="loading-spinner">
            <Spin size="large" />
          </div>
        )}
        
        <div className="loading-message">
          <h3>{randomMessage}</h3>
          <p>{type === 'initial' 
            ? `Loading ${progress?.current || 0} of ${progress?.total || 5} questions` 
            : "This should only take a moment..."}</p>
        </div>
      </div>
      
      <div className="loading-tips">
        <div className="tip-container">
          <span className="tip-icon">💡</span>
          <span className="tip-text">
            {type === 'initial' 
              ? "Read each question carefully before selecting your answer." 
              : "Similar questions help reinforce your understanding of the concept."}
          </span>
        </div>
      </div>
    </div>
  );
};

export default LoadingState;