import React from 'react';
import { Card, Typography, Layout } from 'antd';
import { Link } from 'react-router-dom';
import LoginFromBackgroundImage from '../assets/images/login-from-background-image.png';
import '../styles.css';

// Import the card icons
import AssignmentIcon from '../assets/images/our-key-features-icon6.png';
import ExploreIcon from '../assets/images/our-key-features-icon2.png';

const { Title } = Typography;
const { Content } = Layout;

const LandingView: React.FC = () => {
  return (
    <section className='views-card-section'>
      <div className="login-from-background-image">
        <img src={LoginFromBackgroundImage} alt="" />
      </div>
      <div className="container">
        <div className="row">
          <Layout>
            <Content>
              <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                <Title level={2} style={{ fontWeight: 'bold', marginBottom: '40px' }}>
                  What would you like to do today?
                </Title>
              </div>

              <div className='row justify-content-center'>
                {/* Generate Assignment/Quiz Card */}
                <div className='col-xl-5 col-lg-5 col-md-6'>
                  <Link to={`/detail/assignment?title=Assignment%20Generator`} style={{ textDecoration: 'none' }} aria-label={`Generate Assignment`}>
                    <Card className='view-cards'
                      hoverable
                      style={{
                        transition: 'transform 0.3s, box-shadow 0.3s',
                        height: '100%',
                        cursor: 'pointer',
                        backgroundColor: '#21b573',
                        padding: '20px 15px'
                      }}
                    >
                      <div className='key-features-slider-card-icon-title'>
                        <div className="key-features-slider-icon">
                          <img src={AssignmentIcon} alt="" />
                        </div>
                        <Card.Meta
                          title={<Title className='card-title-text' level={5} style={{ color: 'white' }}>Generate Assignment</Title>}
                        />
                      </div>
                      <Card.Meta
                        description={
                          <div>
                            <p style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>Create interactive quizzes instantly. Generate customized assessments tailored to your curriculum needs with minimal effort.</p>
                            <ul style={{ listStyle: 'disc', paddingLeft: '20px', color: 'white', fontSize: '16px' }}>
                              <li>Multiple question formats</li>
                              <li>Auto-grading system</li>
                              <li>Google Classroom integration</li>
                            </ul>
                          </div>
                        }
                      />
                    </Card>
                  </Link>
                </div>

                {/* Explore Other Features Card */}
                <div className='col-xl-5 col-lg-5 col-md-6'>
                  <Link to={`/views`} style={{ textDecoration: 'none' }} aria-label={`Explore Other Features`}>
                    <Card
                      hoverable
                      style={{
                        // transition: 'transform 0.3s, box-shadow 0.3s',
                        height: '100%',
                        cursor: 'pointer',
                        backgroundColor: '#4285f4',
                        padding: '20px 15px'
                      }}
                    >
                      <div className='key-features-slider-card-icon-title'>
                        <div className="key-features-slider-icon">
                          <img src={ExploreIcon} alt="" />
                        </div>
                        <Card.Meta
                          title={<Title className='card-title-text' level={5} style={{ color: 'white' }}>Explore Other Features</Title>}
                        />
                      </div>
                      <Card.Meta
                        description={
                          <div>
                            <p style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>Explore additional teaching tools designed to save time and enhance your classroom experience with just a few clicks.</p>
                            <ul style={{ listStyle: 'disc', paddingLeft: '20px', color: 'white', fontSize: '16px' }}>
                              <li>Lesson Plan Generator</li>
                              <li>Fun Activities Creator</li>
                              <li>Case Study Builder</li>
                            </ul>
                          </div>
                        }
                      />
                    </Card>
                  </Link>
                </div>
              </div>
            </Content>
          </Layout>
        </div>
      </div>
    </section>
  );
};

export default LandingView; 