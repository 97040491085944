import React from 'react';
import ResponseCard from '../components/common/ResponseCard';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Button } from 'antd';
import LoginFromBackgroundImage from '../assets/images/login-from-background-image.png';

const GenerateDetails: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { loading, response, topic, grade, id, standard, additionalDetails, setResponse } = location.state || {};

  const handleGoBack = () => {
    navigate('/views', { state: { refresh: true } });
  };

  return (
    <div className="login-form-section response-page">
      <div className="login-from-background-image">
        <img src={LoginFromBackgroundImage} alt="" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Card className='login-form-card'>
            <Button
              onClick={handleGoBack}
              className="button details-goback-btn"
            >
              <i className="fa-solid fa-angle-left"></i>&nbsp; Go Back
            </Button>
              <ResponseCard
                isLoading={loading}
                initialResponse={response}
                topic={topic}
                grade={grade}
                card_id={id || ''}
                standard={standard}
                additionalDetails={additionalDetails}
                setResponse={setResponse}
              />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateDetails;
