import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Card, Button, Dropdown, Menu } from 'antd';
import { ArrowLeftOutlined, LogoutOutlined, EditOutlined } from '@ant-design/icons';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth, signOut } from 'firebase/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';
import DetailForm from '../components/forms/DetailForm';
import ResponseCard from '../components/common/ResponseCard';
import './detail.css';
import LoginFromBackgroundImage from '../assets/images/login-from-background-image.png'

const Detail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const db = getFirestore();
  const auth = getAuth();
  const analytics = getAnalytics();
  const [response, setResponse] = useState('');
  const [userName, setUserName] = useState<string | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [topic, setTopic] = useState<string>('');
  const [grade, setGrade] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [standard, setStandard] = useState<string>('');
  const [additionalDetails, setAdditionalDetails] = useState<string>('');
  const [questionComplexity, setQuestionComplexity] = useState<string>('mix');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!id) {
      navigate('/views');
    } else {
      const searchParams = new URLSearchParams(location.search);
      let titleParam = searchParams.get('title');
      if (titleParam) {
        titleParam = (titleParam == 'Assignment Generator') ? 'Assignments' : titleParam;
        setTitle(titleParam);
        document.title = `Generate ${titleParam}`;
        logEvent(analytics, 'page_view', { page: 'Detail', id, title: titleParam });
      }
    }
  }, [id, navigate, analytics, location.search]);

  useEffect(() => {
    console.log("Auth state changed");
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          console.log("Fetching user data for:", user.uid);
          const userDoc = doc(db, 'users', user.uid);
          const userSnapshot = await getDoc(userDoc);
          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            console.log("Fetched user data:", userData);
            setUserName(userData.name || '');
            setGrade(userData.grade || '');
            setSubject(userData.subject || '');
            setStandard(userData.standard || '');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        navigate('/');
      }
    });
  
    return () => unsubscribe();
  }, []);  // Remove dependencies to ensure it only runs once

  const handleFormSubmit = () => {
    logEvent(analytics, 'submit_form', { id, title });
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleEditInfo = () => {
    navigate('/edit-info');
  };

  const userInitials = userName ? userName.split(' ').map(name => name[0]).join('') : 'U';

  const userMenu = (
    <Menu>
      <Menu.Item key="1" icon={<EditOutlined />} onClick={handleEditInfo}>
        Edit Info
      </Menu.Item>
      <Menu.Item key="2" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <section className="login-form-section detail-form-section">
      <div className="login-from-background-image">
        <img src={LoginFromBackgroundImage} alt="" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Button
              onClick={() => navigate(-1)}
              className="button details-goback-btn"
            >
              <i className="fa-solid fa-angle-left"></i>&nbsp; Go Back
            </Button>
          </div>
        </div>
        <div className="row justify-content-lg-center">
          <div className="col-md-12">
            <div className="row justify-content-md-center">
              <div className="col-md-9">
                <Card className='login-form-card'>
                  <div className="detail-form-card-title-section">
                    <h2 className='login-from-card-title' style={{textAlign: "center"}}>{`Generate ${title}`}</h2>
                  </div>
                  {id && (
                    <DetailForm
                    id={id}
                    initialGrade={grade}
                    initialSubject={subject}
                    initialStandard={standard}
                    setResponse={setResponse}
                    setExportLoading={setLoading}
                    setTopic={setTopic}
                    setGrade={setGrade}
                    setSubject={setSubject}
                    setStandard={setStandard}
                    onSubmit={handleFormSubmit}
                    setAdditionalDetails={setAdditionalDetails}
                    setQuestionComplexity={setQuestionComplexity}
                  />
                  )}
                  <ResponseCard
                    isLoading={loading}
                    initialResponse={response}
                    topic={topic}
                    grade={grade}
                    card_id={id || ''}
                    standard={standard}
                    additionalDetails={additionalDetails}
                    setResponse={setResponse}
                  />
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Detail;