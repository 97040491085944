import React, { useState, useEffect, CSSProperties } from 'react';
import { Card, Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { Space, Input, message, Modal } from 'antd';
import { getAuth } from '@firebase/auth';
import { useNavigate } from 'react-router-dom';
import { ResponseCardProps, Response } from '../../types/responseCard.types';
import { useResponseCard } from '../../hooks/useResponseCard';
import FeedbackSection from './FeedbackSection';
import ExportDialog from './ExportDialog';
import ResponseContent from './ResponseContent';
import FeedbackForm from '../../components/forms/FeedbackForm';
import { Assignment, createNewAssignment } from '../../models/Assignment';
import FirebaseClassroomService from '../../services/FirebaseClassroomService';
import GoogleClassroomConsentFlow from '../../features/classroom/GoogleClassroomConsentFlow';
import GoogleClassroomIntegration from '../../features/classroom/GoogleClassroomIntegration';
import './responsive.css';

const { TextArea } = Input;

// Add responsive styles
const styles: Record<string, CSSProperties> = {
  responseCard: {
    width: '100%', 
    marginTop: '20px', 
    padding: '20px',
    overflowX: 'hidden'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '10px',
    marginBottom: '15px'
  },
  actionButton: {
    marginBottom: '8px',
    minWidth: '160px'
  },
  contentContainer: {
    maxWidth: '100%',
    overflowX: 'auto',
    wordBreak: 'break-word'
  }
};

const ResponseCard: React.FC<ResponseCardProps> = ({
  initialResponse,
  topic,
  grade,
  card_id,
  standard,
  additionalDetails,
  isLoading,
  setResponse
}) => {
  const auth = getAuth();
  const navigate = useNavigate();
  
  // Add state for classroom integration
  const [classroomAuthorized, setClassroomAuthorized] = useState<boolean>(false);
  const [consentModalVisible, setConsentModalVisible] = useState<boolean>(false);
  const [classroomModalVisible, setClassroomModalVisible] = useState<boolean>(false);
  const [selectedResponse, setSelectedResponse] = useState<Response | null>(null);
  const [createdAssignment, setCreatedAssignment] = useState<Assignment | null>(null);
  const [classroomService] = useState(new FirebaseClassroomService());
  const [publishingToClassroom, setPublishingToClassroom] = useState<boolean>(false);
  // Add state for screen size
  const [isMobile, setIsMobile] = useState<boolean>(false);
  
  const {
    feedbackOpen,
    setFeedbackOpen,
    exportDialogOpen,
    setExportDialogOpen,
    exportLink,
    loading,
    refineInput,
    setRefineInput,
    responses,
    setResponses,
    showRefinementInput,
    handleExportGoogleDrive,
    handleRefineSubmit
  } = useResponseCard(
    initialResponse,
    topic,
    isLoading,
    auth,
    card_id,
    grade,
    standard
  );

  // Check if Google Classroom is authorized
  useEffect(() => {
    const checkClassroomAuth = async () => {
      if (auth.currentUser) {
        try {
          const accessResult = await classroomService.checkClassroomAccess();
          setClassroomAuthorized(accessResult.hasAccess);
        } catch (error) {
          console.error('Error checking classroom authorization:', error);
          setClassroomAuthorized(false);
        }
      }
    };
    
    checkClassroomAuth();
  }, [auth.currentUser, classroomService]);

  // Add responsive listener for screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    // Set initial value
    handleResize();
    
    // Add event listener
    window.addEventListener('resize', handleResize);
    
    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleFeedbackOpen = () => {
    if (auth.currentUser) {
      setFeedbackOpen(true);
    }
  };

  const saveSignal = async (event: React.MouseEvent, signalType: 'like' | 'dislike', index: number) => {
    event.preventDefault();
    setResponses(prevResponses => {
      const newResponses = [...prevResponses];
      if (newResponses[index]) {
        newResponses[index].feedback = signalType === newResponses[index].feedback ? null : signalType;
      }
      return newResponses;
    });
  };

  // Handle publishing to Google Classroom
  const handlePublishToClassroom = async (res: Response, index: number) => {
    if (!auth.currentUser) {
      message.error('You must be logged in to publish to Google Classroom');
      return;
    }
    
    if (!classroomAuthorized) {
      // Show the consent flow
      setConsentModalVisible(true);
      setSelectedResponse(res);
      return;
    }
    
    try {
      setPublishingToClassroom(true);
      setSelectedResponse(res);
      
      // Create an assignment from the response content
      const newAssignment = createNewAssignment(auth.currentUser.uid);
      newAssignment.title = topic || 'Generated Assignment';
      newAssignment.description = `${standard ? `Standard: ${standard}\n` : ''}${additionalDetails ? `Additional details: ${additionalDetails}\n` : ''}`;
      
      // Make sure description is not empty
      if (!newAssignment.description || newAssignment.description.trim() === '') {
        newAssignment.description = `${card_id === 'quiz' ? 'Quiz' : 'Assignment'} on ${topic || 'this topic'}`;
      }
      
      newAssignment.subject = card_id === 'quiz' ? 'Quiz' : card_id === 'assignment' ? 'Assignment' : card_id;
      newAssignment.gradeLevel = grade;
      
      // Store the response content in the assignment
      newAssignment.content = res.content;
      
      // If this is an assignment or quiz, try to parse questions
      if (card_id === 'assignment' || card_id === 'quiz') {
        // Simple parsing logic could be improved
        const lines = res.content.split('\n');
        let currentQuestion: any = null;
        
        for (const line of lines) {
          // Very basic parsing - this should be much more sophisticated
          if (line.match(/^\d+\.\s/)) {
            // New question
            if (currentQuestion) {
              newAssignment.questions.push(currentQuestion);
            }
            currentQuestion = {
              id: `q${newAssignment.questions.length + 1}`,
              text: line.replace(/^\d+\.\s/, ''),
              type: 'short-answer' as const,
              points: 5
            };
          } else if (currentQuestion && line.match(/^[A-D]\.\s/)) {
            // This is a multiple choice option
            if (currentQuestion.type === 'short-answer') {
              currentQuestion.type = 'multiple-choice' as const;
              currentQuestion.options = [];
            }
            currentQuestion.options.push(line.replace(/^[A-D]\.\s/, ''));
          }
        }
        
        // Add the last question
        if (currentQuestion) {
          newAssignment.questions.push(currentQuestion);
        }
      }
      
      setCreatedAssignment(newAssignment);
      setClassroomModalVisible(true);
    } catch (error) {
      console.error('Error preparing to publish to classroom:', error);
      message.error('Failed to prepare assignment for Google Classroom');
    } finally {
      setPublishingToClassroom(false);
    }
  };

  // Handle consent completion
  const handleConsentComplete = (success: boolean) => {
    setConsentModalVisible(false);
    if (success) {
      setClassroomAuthorized(true);
      message.success('Google Classroom connected successfully!');
      
      // If we were trying to publish, continue with the process
      if (selectedResponse) {
        handlePublishToClassroom(selectedResponse, responses.indexOf(selectedResponse));
      }
    }
  };

  // Handle assignment update after Google Classroom integration
  const handleAssignmentUpdated = (updatedAssignment: Assignment) => {
    setCreatedAssignment(updatedAssignment);
  };

  // Handle close of classroom modals and redirect if needed
  const closeClassroomModals = () => {
    setClassroomModalVisible(false);
    setConsentModalVisible(false);
    
    // If we have a published assignment, we could redirect here
    if (createdAssignment && createdAssignment.googleClassroomAssignmentId) {
      // No need to reload, just close the modal
      message.success('Assignment successfully published to Google Classroom!');
    }
  };

  const renderResponseCard = (res: Response, index: number) => {
    return (
      <Card key={index} className="markdown-container" style={styles.responseCard}>
        <div className="query-text-export-btn-section">
          <div className="export-text-export-drive-btn" style={styles.buttonContainer}>
            <Button
              onClick={() => handleExportGoogleDrive(res.content, index)}
              disabled={!res.isComplete || loading || res.isExportingDrive}
              style={styles.actionButton}
              fullWidth={isMobile}
              size={isMobile ? "small" : "medium"}
            >
              {res.isExportingDrive ? 'Generating...' : 'Edit Before Publishing'}
            </Button>
            
            {/* Add Google Classroom button */}
            <Button
              onClick={() => handlePublishToClassroom(res, index)}
              disabled={!res.isComplete || loading || res.isExportingDrive || publishingToClassroom}
              color="primary"
              style={styles.actionButton}
              fullWidth={isMobile}
              size={isMobile ? "small" : "medium"}
            >
              {publishingToClassroom && selectedResponse === res ? 'Publishing...' : 'Publish to Google Classroom'}
            </Button>
          </div>
        </div>

        <div style={styles.contentContainer}>
          <ResponseContent
            card_id={card_id}
            content={res.content}
            grade={grade}
            standard={standard}
          />
        </div>

        {res.isComplete && (
          <FeedbackSection
            feedback={res.feedback}
            onSaveSignal={(event: any, type: any ) => saveSignal(event, type, index)}
          />
        )}
      </Card>
    );
  };

  // Calculate modal width based on screen size
  const getModalWidth = () => {
    return isMobile ? '95%' : 700;
  };

  return (
    <Space direction="vertical" style={{ marginTop: 20, width: '100%' }}>
      {responses.map((res, index) => renderResponseCard(res, index))}

      {showRefinementInput && !loading && (
        <>
          <div className="login-form-input-section">
            <TextArea
              className='login-form-input'
              value={refineInput}
              onChange={(e) => setRefineInput(e.target.value)}
              placeholder="Enter any refinements or further details for the response"
              rows={isMobile ? 2 : 1}
              style={{ marginTop: 20, width: '100%' }}
            />
          </div>
          <div className="ask-praro-feedback-btn" style={isMobile ? { flexDirection: 'column', gap: '10px' } : {}}>
            <button className="feedback-button" onClick={handleFeedbackOpen} style={isMobile ? { marginBottom: '10px', width: '100%' } : {}}>
              Give Feedback
            </button>
            <Button
              className='ask-praro-btn'
              variant="contained"
              onClick={handleRefineSubmit}
              style={isMobile ? { width: '100%' } : {}}
            >
              Ask Praro &nbsp; <i className="fa-solid fa-arrow-right-long"></i>
            </Button>
          </div>
        </>
      )}

      <ExportDialog
        open={exportDialogOpen}
        onClose={() => setExportDialogOpen(false)}
        exportLink={exportLink}
        onViewContent={() => {
          setExportDialogOpen(false);
          navigate('/my-content');
        }}
      />

      <Dialog 
        open={feedbackOpen} 
        onClose={() => setFeedbackOpen(false)} 
        fullWidth 
        maxWidth="sm"
        fullScreen={isMobile}
      >
        <DialogTitle>Feedback</DialogTitle>
        <DialogContent>
          <FeedbackForm 
            userTopic={topic} 
            userGrade={grade} 
            onClose={() => setFeedbackOpen(false)} 
            auth={auth} 
          />
        </DialogContent>
      </Dialog>
      
      {/* Google Classroom Consent Modal */}
      <Modal
        title="Connect to Google Classroom"
        open={consentModalVisible}
        footer={null}
        closable={true}
        onCancel={() => setConsentModalVisible(false)}
        width={getModalWidth()}
        destroyOnClose={true}
        maskClosable={false}
      >
        <GoogleClassroomConsentFlow onComplete={handleConsentComplete} />
      </Modal>
      
      {/* Google Classroom Integration Modal */}
      <Modal
        title="Publish to Google Classroom"
        open={classroomModalVisible}
        onCancel={closeClassroomModals}
        footer={null}
        width={getModalWidth()}
        destroyOnClose={true}
        maskClosable={false}
      >
        {createdAssignment && (
          <GoogleClassroomIntegration
            assignment={createdAssignment}
            onAssignmentUpdated={handleAssignmentUpdated}
            onSuccessClose={closeClassroomModals}
          />
        )}
      </Modal>
    </Space>
  );
};

export default ResponseCard;