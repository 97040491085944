import React, { useEffect, useState } from 'react';
import { Card, Steps, Button, Typography, List, Alert, Spin, message, Switch, Tooltip } from 'antd';
import FirebaseClassroomService from '../../services/FirebaseClassroomService';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

const { Title, Text, Paragraph } = Typography;
const { Step } = Steps;

interface GoogleClassroomConsentFlowProps {
  onComplete: (success: boolean, scopes?: string[]) => void;
}

const GoogleClassroomConsentFlow: React.FC<GoogleClassroomConsentFlowProps> = ({ onComplete }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [classroomService] = useState(new FirebaseClassroomService());
  const auth = getAuth();
  const [retryCount, setRetryCount] = useState(0);
  const [courses, setCourses] = useState<any[]>([]);
  const [persistentConnection, setPersistentConnection] = useState(false);
  

    // Add a function to check if existing tokens are valid
    const checkExistingTokens = async () => {
      try {
        setLoading(true);
        const tokenStatus = await classroomService.checkTokens();
        
        console.log("Token status check result:", tokenStatus);
        
        if (tokenStatus.hasTokens && !tokenStatus.isExpired) {
          console.log("Existing tokens are valid, proceeding to verification step");
          setCurrentStep(1); // Skip to verification step
          setLoading(false);
        } else {
          console.log("Existing tokens are invalid or expired, will request new ones");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error checking existing tokens:", error);
        setLoading(false);
      }
    };

  // Clear any existing tokens on mount
  useEffect(() => {
    // Don't clear tokens on mount - this causes reconnect issues
    // Instead, check if we already have valid tokens
    const hasExistingTokens = sessionStorage.getItem('googleAccessToken') || 
                             localStorage.getItem('googleAccessToken');
    
    if (hasExistingTokens) {
      console.log("Found existing tokens, checking if they're valid");
      checkExistingTokens();
    } else {
      console.log("No existing tokens found, will request new ones");
    }
    
    // Set retry counter only once
    setRetryCount(prev => prev + 1);
  }, []);

  // Add a useEffect to check for persistent connection status
  useEffect(() => {
    const isPersistent = localStorage.getItem('googleClassroomPersistentConnection') === 'true';
    setPersistentConnection(isPersistent);
  }, [currentStep]);

  const requiredScopes = [
    {
      scope: 'https://www.googleapis.com/auth/classroom.courses',
      description: 'View and manage your Google Classroom courses'
    },
    {
      scope: 'https://www.googleapis.com/auth/classroom.coursework.me',
      description: 'View and manage coursework in your Google Classroom courses'
    },
    {
      scope: 'https://www.googleapis.com/auth/classroom.coursework.students',
      description: 'View and manage coursework and grades for your students'
    }
  ];

  const handleConnectToClassroom = async () => {
    try {
      setLoading(true);
      setError(null);
      
      console.log("Starting Google Classroom connection flow");
      
      // Don't clear tokens by default, only if we need a fresh connection
      const forceReconnect = false; // Can be set via props if needed
      if (forceReconnect) {
        console.log("Force reconnect requested, clearing existing tokens");
        sessionStorage.removeItem('googleAccessToken');
        localStorage.removeItem('googleRefreshToken');
        localStorage.removeItem('googleTokenExpiry');
      }
      
      // Keep the scopes if they exist
      const existingScopes = localStorage.getItem('googleScopes');
      
      // Always reauthenticate to ensure we get fresh tokens
      const provider = new GoogleAuthProvider();
      
      // Add the required scopes - make sure to add ALL necessary scopes explicitly
      console.log("Adding the following scopes:");
      requiredScopes.forEach(scope => {
        provider.addScope(scope.scope);
        console.log(`- ${scope.scope}: ${scope.description}`);
      });
      
      // Need to get both ID token and access token
      // Ensure we get a refresh token by forcing consent
      provider.setCustomParameters({ 
        access_type: 'offline',
        prompt: 'consent',  // Always force the consent screen to appear
        include_granted_scopes: 'true'
      });
      
      console.log("Opening Google sign-in popup with offline access to request refresh token");
      
      try {
        // Sign in with popup to get credentials
        const result = await signInWithPopup(auth, provider);
        
        // Get the Google OAuth credential from the result
        const credential = GoogleAuthProvider.credentialFromResult(result);
        console.log("Google Auth Result successful");
        
        // Log credential structure for debugging (without sensitive data)
        console.log("Credential structure:", {
          hasAccessToken: !!credential?.accessToken,
          hasIdToken: !!credential?.idToken,
          hasRefreshToken: !!(credential as any)?.refreshToken,
          credentialType: credential?.providerId || 'unknown'
        });
        
        // Log user structure for debugging (without sensitive data)
        console.log("User structure:", {
          uid: result.user.uid,
          providerIds: result.user.providerData.map(p => p.providerId),
          hasTokens: !!result.user.getIdToken
        });
        
        if (credential && credential.accessToken) {
          // Save the access token to session storage for direct use
          sessionStorage.setItem('googleAccessToken', credential.accessToken);
          console.log("Saved access token to session storage");
          
          // Get the refresh token from the credential
          // Using a more reliable way to get the refresh token
          let refreshToken = null;
          
          // Try several known paths where refresh token might be stored
          try {
            // Check if available directly on credential
            if ((credential as any).refreshToken) {
              refreshToken = (credential as any).refreshToken;
              console.log("Found refresh token on credential object");
            } 
            // Check if it's in the provider data
            else if (result.user.providerData && result.user.providerData.length > 0) {
              const googleProvider = result.user.providerData.find(p => p.providerId === 'google.com');
              if (googleProvider && (googleProvider as any).refreshToken) {
                refreshToken = (googleProvider as any).refreshToken;
                console.log("Found refresh token in provider data");
              }
            }
            // Try other known locations
            else if ((result as any)._tokenResponse && (result as any)._tokenResponse.refreshToken) {
              refreshToken = (result as any)._tokenResponse.refreshToken;
              console.log("Found refresh token in _tokenResponse");
            }
          } catch (tokenError) {
            console.warn("Error extracting refresh token:", tokenError);
          }
          
          // Store the refresh token if found
          if (refreshToken) {
            localStorage.setItem('googleRefreshToken', refreshToken);
            console.log("Saved refresh token to local storage");
          } else {
            console.warn("No refresh token received from Google - will try server-side authentication");
            
            try {
              // Get the ID token
              const idToken = await result.user.getIdToken();
              
              // Use Firebase Functions SDK to get the refresh token
              const functions = getFunctions(undefined, 'us-central1');
              const getServerRefreshTokenFn = httpsCallable(functions, 'getGoogleServerRefreshToken');
              
              // Call the function with the ID token
              const serverTokenResult = await getServerRefreshTokenFn({ idToken });
              
              console.log("Server refresh token result:", serverTokenResult.data);
              
              // Store the server-provided refresh token if available
              if (serverTokenResult.data && (serverTokenResult.data as any).refreshToken) {
                refreshToken = (serverTokenResult.data as any).refreshToken;
                localStorage.setItem('googleRefreshToken', refreshToken);
                console.log("Saved server-provided refresh token to local storage");
              } else {
                console.warn("Server could not provide a refresh token either");
              }
            } catch (serverTokenError) {
              console.error("Error getting server refresh token:", serverTokenError);
            }
          }
          
          // Also save to local storage as a backup mechanism
          localStorage.setItem('googleAccessToken', credential.accessToken);
          localStorage.setItem('googleTokenExpiry', (Date.now() + 3600 * 1000).toString());
          
          // Save granted scopes for verification
          const scopeString = requiredScopes.map(scope => scope.scope).join(' ');
          localStorage.setItem('googleScopes', scopeString);
          console.log("Saved scopes to local storage:", scopeString);
          
          console.log("Also saved tokens to local storage");
          
          // Create the credentials object to store
          const credentialsToStore: any = {
            access_token: credential.accessToken,
            id_token: await result.user.getIdToken(),
            token_type: 'Bearer',
            expiry_date: Date.now() + 3600 * 1000, // Estimate 1 hour
            scope: scopeString
          };
          
          // Only include refresh token if we have one
          if (refreshToken) {
            credentialsToStore.refresh_token = refreshToken;
          }
          
          console.log("Storing credentials in Firebase...");
          
          try {
            // Use the Firebase Functions SDK properly to avoid CORS issues
            const functions = getFunctions(undefined, 'us-central1');
            const storeGoogleCredentialsFn = httpsCallable(functions, 'storeGoogleCredentials');
            
            console.log("Calling storeGoogleCredentials function with credentials");
            
            // Store credentials in Firebase
            const storeResult = await storeGoogleCredentialsFn({
              credentials: credentialsToStore,
              persistConnection: true // Set flag to maintain persistent connection
            });
            
            console.log("Store result:", storeResult.data);
            
            // Set a flag in localStorage indicating persistent connection is enabled
            localStorage.setItem('googleClassroomPersistentConnection', 'true');
            
            // Add a short delay to ensure token is properly saved
            await new Promise(resolve => setTimeout(resolve, 1000));
            
            // Move to next step on success
            setCurrentStep(1);
            message.success('Successfully connected to Google!');
          } catch (storeError) {
            console.error('Error storing credentials:', storeError);
            
            // Don't fail completely, try to proceed with verification
            console.log("Proceeding to verification despite storage error");
            setCurrentStep(1);
            message.warning('Connected to Google, but there was an issue storing your credentials. Proceeding to verification...');
          }
        } else {
          throw new Error('Failed to get Google access token');
        }
      } catch (error: any) {
        console.error('Error during Google sign-in:', error);
        setError(error.message || 'Failed to connect to Google. Please try again.');
      }
    } catch (error: any) {
      console.error('Error in handleConnectToClassroom:', error);
      setError(error.message || 'An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyAccess = async () => {
    setLoading(true);
    setError(null);
    
    try {
      console.log("Verifying Google Classroom access");
      
      // First check if we have tokens in session/local storage to use as fallback
      const sessionToken = sessionStorage.getItem('googleAccessToken');
      const localToken = localStorage.getItem('googleAccessToken');
      
      console.log("Session token exists:", !!sessionToken);
      console.log("Local storage token exists:", !!localToken);
      
      // If we have a session token but not in local storage, sync them
      if (sessionToken && !localToken) {
        localStorage.setItem('googleAccessToken', sessionToken);
        localStorage.setItem('googleTokenExpiry', (Date.now() + 3600 * 1000).toString());
        console.log("Synchronized token from session to local storage");
      }
      
      // If we have a local token but not in session, sync that too
      if (localToken && !sessionToken) {
        sessionStorage.setItem('googleAccessToken', localToken);
        console.log("Synchronized token from local storage to session");
      }
      
      // Check tokens through the service
      console.log("Checking tokens through service");
      const tokenCheck = await classroomService.checkTokens();
      console.log("Token check result:", tokenCheck);
      
      // Enhanced token check with fallback
      if (!tokenCheck.hasTokens) {
        console.warn("No tokens found from service check, checking local availability");
        
        // If we have tokens locally but the service doesn't see them, there's a sync issue
        if (sessionToken || localToken) {
          console.log("Tokens found locally but not in service, attempting to use local tokens");
          
          // Try to proceed with local tokens anyway
          console.log("Attempting to proceed with local tokens");
        } else {
          // No tokens anywhere
          throw new Error('No Google tokens found. Please try connecting again.');
        }
      }
      
      if (tokenCheck.isExpired) {
        // Even if the service thinks tokens are expired, check our local expiry
        const localExpiry = localStorage.getItem('googleTokenExpiry');
        const now = Date.now();
        
        if (localExpiry && parseInt(localExpiry) > now) {
          console.log("Service reports expired token but local expiry suggests it's still valid");
        } else {
          throw new Error('Your Google token has expired. Please reconnect to refresh it.');
        }
      }
      
      // Check for required scopes - if we only need the classroom.courses.readonly scope
      if (!tokenCheck.hasClassroomCoursesScope) {
        console.error("Missing required scope:", tokenCheck);
        
        // Check if we have local scope data that might be more current
        const localScopes = localStorage.getItem('googleScopes');
        console.log("Local scopes:", localScopes);
        
        // If we have local scopes, check if they include what we need
        if (localScopes) {
          const hasCoursesScope = localScopes.includes('https://www.googleapis.com/auth/classroom.courses') || 
                                  localScopes.includes('https://www.googleapis.com/auth/classroom.courses.readonly');
          
          console.log("Local scope check: courses =", hasCoursesScope);
          
          // If local scope check passes, proceed even if server check failed
          if (hasCoursesScope) {
            console.log("Local scopes look good, proceeding despite server scope check failure");
          } else {
            // We're missing required scope even in local storage
            throw new Error('Missing required scope: view your Google Classroom courses');
          }
        } else {
          // No local scope data, use server-provided info
          throw new Error('Missing required scope: view your Google Classroom courses');
        }
      }
      
      // Try to fetch courses to verify access
      console.log("Fetching courses to verify access");
      try {
        // Add a test to fetch classes to verify the connection is working
        const courses = await classroomService.getCourses();
        console.log(`Successfully fetched ${courses.length} courses:`, courses);
        
        // Show course info in UI - store in state
        setCourses(courses);
        
        // Success! Move to the next step
        setCurrentStep(2);
        message.success('Verification successful! You have access to Google Classroom.');
      } catch (fetchError: any) {
        console.error("Error fetching courses:", fetchError);
        
        // Try direct check as fallback
        console.log("Trying direct check as fallback");
        try {
          const directCheckResult = await classroomService.directCheckClassroomCourses();
          console.log("Direct check result:", directCheckResult);
          
          if (directCheckResult.error) {
            throw new Error(`Direct check failed: ${directCheckResult.message || directCheckResult.error}`);
          }
          
          if (directCheckResult.success) {
            // Success with direct check
            setCurrentStep(2);
            message.success('Verification successful! You have access to Google Classroom.');
          } else {
            throw new Error('Could not verify access to Google Classroom.');
          }
        } catch (directCheckError: any) {
          console.error("Direct check error:", directCheckError);
          throw new Error(`Verification failed. Could not access Google Classroom: ${fetchError.message}`);
        }
      }
    } catch (error: any) {
      console.error('Error verifying access:', error);
      setError(`Verification failed: ${error.message || 'Unknown error'}`);
    } finally {
      setLoading(false);
    }
  };

  // Add a function to handle persistent connection toggle
  const handlePersistentConnectionToggle = async (checked: boolean) => {
    setLoading(true);
    try {
      let success = false;
      
      if (checked) {
        // Enable persistent connection
        success = await classroomService.enablePersistentConnection();
        if (success) {
          message.success('Persistent connection enabled');
          setPersistentConnection(true);
        } else {
          message.error('Failed to enable persistent connection');
        }
      } else {
        // Disable persistent connection
        success = await classroomService.disablePersistentConnection();
        if (success) {
          message.success('Persistent connection disabled');
          setPersistentConnection(false);
        } else {
          message.error('Failed to disable persistent connection');
        }
      }
    } catch (error: any) {
      console.error('Error toggling persistent connection:', error);
      message.error(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleComplete = () => {
    // Pass the scopes from localStorage back to parent
    const scopesString = localStorage.getItem('googleScopes');
    let scopes: string[] = [];
    
    if (scopesString) {
      try {
        scopes = scopesString.split(' ');
      } catch (e) {
        console.error('Error parsing scopes:', e);
      }
    }
    
    onComplete(true, scopes);
  };
  
  const handleRetry = () => {
    // Clear tokens and increment retry counter
    sessionStorage.removeItem('googleAccessToken');
    localStorage.removeItem('classroomAuthorized');
    
    // Also clear persistent connection flag if set
    localStorage.removeItem('googleClassroomPersistentConnection');
    setPersistentConnection(false);
    
    setRetryCount(prev => prev + 1);
    
    // Reset to first step
    setCurrentStep(0);
    setError(null);
  };

  // Add a function to render detailed scope error guidance
  const renderScopeErrorGuidance = () => {
    return (
      <div style={{ marginTop: 16 }}>
        <h4>Why this happens:</h4>
        <p>Google Classroom integration requires permission to view your courses. You may have denied this permission during sign-in.</p>
        
        <h4>How to fix this:</h4>
        <ol>
          <li>Click the "Retry Connection" button below</li>
          <li>When Google asks for permission, make sure to allow the app to <strong>view your Google Classroom courses</strong></li>
          <li>Click "Allow" to grant this permission</li>
        </ol>
        
        <p>Note: We've reduced the permissions requested to only the minimum required for basic functionality.</p>
        
        <Button type="primary" onClick={handleRetry} style={{ marginRight: 8 }}>
          Retry Connection
        </Button>
        <Button 
          onClick={() => window.open('https://myaccount.google.com/permissions', '_blank')}
        >
          Manage Google Permissions
        </Button>
      </div>
    );
  };
  
  // Update the renderErrorActions function to show detailed guidance for scope errors
  const renderErrorActions = () => {
    // Check if the error is about missing permissions/scopes
    const isScopeError = error?.includes('Missing required scope');
    
    if (isScopeError) {
      return renderScopeErrorGuidance();
    }
    
    // Default error actions
    return (
      <div style={{ marginTop: 16 }}>
        <Button type="primary" onClick={handleRetry}>
          Retry Connection
        </Button>
        <Button 
          style={{ marginLeft: 8 }} 
          onClick={() => window.open('https://myaccount.google.com/permissions', '_blank')}
        >
          Check Google Permissions
        </Button>
      </div>
    );
  };

  // Add a component to display course information
  const renderCoursesList = () => {
    if (courses.length === 0) {
      return (
        <div>
          <p>No courses found. You might not have any courses in your Google Classroom account.</p>
        </div>
      );
    }

    return (
      <div>
        <h4>Your Courses:</h4>
        <ul>
          {courses.map((course) => (
            <li key={course.id}>
              <strong>{course.name}</strong>
              {course.section && ` - ${course.section}`}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  // Update the step 3 (complete step) to show the courses
  const renderComplete = () => {
    return (
      <div>
        <Alert
          message="Connection Successful"
          description="Your Google Classroom account has been successfully connected and all permissions verified. You can now use Google Classroom features in Praro."
          type="success"
          showIcon
          style={{ marginBottom: 16 }}
        />

        {renderCoursesList()}

        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <Tooltip title="When enabled, your connection to Google Classroom will automatically renew even after your session expires">
            <span style={{ marginRight: 10 }}>
              <Switch 
                checked={persistentConnection} 
                onChange={handlePersistentConnectionToggle}
                loading={loading}
              /> Keep me connected to Google Classroom
            </span>
          </Tooltip>
          <p style={{ fontSize: '12px', color: '#888', marginTop: 5 }}>
            {persistentConnection ? 
              'Your connection will automatically refresh to keep you signed in.' : 
              'You may need to reconnect to Google Classroom when your session expires.'}
          </p>
        </div>

        <Button type="primary" onClick={handleComplete}>
          Done
        </Button>
      </div>
    );
  };

  // Step 0 content - Connect
  const renderConnectStep = () => (
    <div className="consent-step">
      <Paragraph>
        To connect to Google Classroom, you'll need to authorize access to your Google account.
        This will allow us to access your Google Classroom courses.
      </Paragraph>
      
      <Paragraph>
        <strong>Required permissions:</strong>
        <ul>
          {requiredScopes.map((scope, index) => (
            <li key={index}>{scope.description}</li>
          ))}
        </ul>
      </Paragraph>
      
      <Button 
        type="primary" 
        onClick={handleConnectToClassroom}
        loading={loading}
        size="large"
      >
        Connect to Google Classroom
      </Button>
    </div>
  );

  // Step 1 content - Verify
  const renderVerifyStep = () => (
    <div className="consent-step">
      <Paragraph>
        We need to verify your Google Classroom access.
        Click the button below to verify and complete the setup.
      </Paragraph>
      
      <Button 
        type="primary" 
        onClick={handleVerifyAccess}
        loading={loading}
        size="large"
      >
        Verify Access
      </Button>
    </div>
  );

  // Update the renderStepContent function to include the complete step with course list
  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return renderConnectStep();
      case 1:
        return renderVerifyStep();
      case 2:
        return renderComplete();
      default:
        return <div>Unknown step</div>;
    }
  };

  return (
    <>
      <div style={{ marginBottom: '20px' }}>
        <Title level={2} style={{ width: '100%', marginBottom: 20, fontWeight: 'bold' }}>Connect to Google Classroom</Title>
      </div>
      <Steps current={currentStep} style={{ marginBottom: 20 }}>
        <Step title="Connect" description="Connect your Google account" />
        <Step title="Verify" description="Verify classroom access" />
        <Step title="Complete" description="Ready to use" />
      </Steps>
      
      {error && (
        <Alert 
          message="Connection Error" 
          description={
            <>
              <Text>{error}</Text>
              {renderErrorActions()}
            </>
          }
          type="error" 
          showIcon 
          style={{ marginBottom: 20 }}
        />
      )}
      
      {renderStepContent()}
    </>
  );
};

export default GoogleClassroomConsentFlow; 