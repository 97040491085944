import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';

interface CustomOptionsProps {
  options: Record<string, string>;
  selectedOption: string | null;
  correctAnswer?: string;
  showFeedback: boolean;
  onSelectOption: (key: string) => void;
}

const CustomOptions: React.FC<CustomOptionsProps> = ({
  options,
  selectedOption,
  correctAnswer,
  showFeedback,
  onSelectOption,
}) => {
  const renderMathContent = (text: string) => (
    <ReactMarkdown
      remarkPlugins={[remarkGfm, remarkMath]}
      rehypePlugins={[rehypeKatex]}
      components={{
        p: ({node, ...props}) => <span {...props} />
      }}
    >
      {text}
    </ReactMarkdown>
  );

  return (
    <div className="custom-options-container">
      {Object.entries(options).map(([key, text]) => {
        const isSelected = key === selectedOption;
        const isCorrect = showFeedback && key === correctAnswer;
        const isIncorrect = showFeedback && isSelected && key !== correctAnswer;
        
        let optionClass = "custom-option";
        if (isSelected) optionClass += " selected";
        if (isCorrect) optionClass += " correct";
        if (isIncorrect) optionClass += " incorrect";
        
        return (
          <div 
            key={key}
            className={optionClass}
            onClick={() => !showFeedback && onSelectOption(key)}
          >
            <input 
              type="radio"
              checked={isSelected}
              onChange={() => !showFeedback && onSelectOption(key)}
              className="custom-radio"
              disabled={showFeedback}
            />
            <div className="option-content">
              <span className="option-key">{key}</span>
              <span className="option-text">{renderMathContent(text)}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CustomOptions;