import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Response, UseResponseCardReturn } from "../types/responseCard.types";
import { DocumentLinksService } from "../services/documentLinksService";

const api_uri = process.env.REACT_APP_API_URI ?? "";

export const useResponseCard = (
  initialResponse: string,
  topic: string,
  isLoading: boolean,
  auth: any,
  card_id: string,
  grade: string,
  standard: string
): UseResponseCardReturn => {
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [exportLink, setExportLink] = useState("");
  const [loading, setLoading] = useState(isLoading);
  const [refineInput, setRefineInput] = useState("");
  const [responses, setResponses] = useState<Response[]>([]);
  const [showRefinementInput, setShowRefinementInput] = useState(false);
  const websocketRef = useRef<WebSocket | null>(null);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (initialResponse) {
      if (
        responses.length === 0 ||
        (responses.length === 1 && responses[0].content !== initialResponse)
      ) {
        const initialResponseObj: Response = {
          query: topic,
          content: initialResponse,
          isComplete: true,
          isExportingDrive: false,
          feedback: null, // Initialize with null
        };
        setResponses([initialResponseObj]);
      }
    }
  }, [initialResponse, topic, responses]);

  useEffect(() => {
    const shouldShowRefinementInput = responses.some((res) => res.isComplete);
    if (showRefinementInput !== shouldShowRefinementInput) {
      setShowRefinementInput(shouldShowRefinementInput);
    }
  }, [responses, showRefinementInput]);

  const handleExportGoogleDrive = async (text: string, index: number) => {
    let user = auth?.currentUser;

    setResponses((prev) => {
      const newResponses = [...prev];
      if (newResponses[index]) {
        newResponses[index] = {
          ...newResponses[index],
          isExportingDrive: true,
        };
      }
      return newResponses;
    });

    try {
      if (user) {
        const serverResponse = await axios.post(`${api_uri}/create-doc`, {
          firebase_user_id: user.uid,
          markdown_text: text,
          card_id: card_id,
          topic: topic,
        });

        const { document_url } = serverResponse.data;
        setExportLink(document_url);

        const documentLinksService = new DocumentLinksService();
        await documentLinksService.saveDocumentLink({
          userId: user.uid,
          documentUrl: document_url,
          topic: topic,
          cardId: card_id,
          title: `Generated content for ${topic}`,
        });

        setExportDialogOpen(true);
      }
    } catch (error) {
      console.error("Error during Google Drive export:", error);
    } finally {
      setResponses((prev) => {
        const newResponses = [...prev];
        if (newResponses[index]) {
          newResponses[index] = {
            ...newResponses[index],
            isExportingDrive: false,
          };
        }
        return newResponses;
      });
    }
  };

  const handleRefineSubmit = () => {
    if (!refineInput.trim()) return;

    setLoading(true);
    setShowRefinementInput(false);

    const wsUri = process.env.REACT_APP_WS_URI || "";
    if (!wsUri) {
      console.error("WebSocket URI is not configured.");
      setLoading(false);
      setShowRefinementInput(true);
      return;
    }

    const websocket = new WebSocket(wsUri);
    websocketRef.current = websocket;

    const newResponse: Response = {
      query: refineInput,
      content: "",
      isComplete: false,
      isExportingDrive: false,
      feedback: null,
    };

    setResponses((prev) => [...prev, newResponse]);

    websocket.onopen = () => {
      const message = JSON.stringify({
        topic,
        grade,
        card_id,
        standard,
        refineInput,
      });
      websocket.send(message);
    };

    websocket.onmessage = (event) => {
      const data = event.data.replaceAll("^^^", "\n");
      setResponses((prev) => {
        const newResponses = [...prev];
        const lastResponse = newResponses[newResponses.length - 1];
        lastResponse.content += data;
        return newResponses;
      });
    };

    websocket.onerror = () => {
      setLoading(false);
      setShowRefinementInput(true);
    };

    websocket.onclose = () => {
      setResponses((prev) => {
        const newResponses = [...prev];
        const lastResponse = newResponses[newResponses.length - 1];
        lastResponse.isComplete = true;
        return newResponses;
      });
      setLoading(false);
      setRefineInput("");
      setShowRefinementInput(true);
    };
  };

  return {
    feedbackOpen,
    setFeedbackOpen,
    exportDialogOpen,
    setExportDialogOpen,
    exportLink,
    loading,
    refineInput,
    setRefineInput,
    responses,
    setResponses,
    showRefinementInput,
    handleExportGoogleDrive,
    handleRefineSubmit,
  };
};
