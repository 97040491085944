import React from 'react';
import { Form, Input, Button, Divider, Spin, Alert, Typography, Modal, Card } from 'antd';
import AdditionalInfoForm, { AdditionalInfo } from '../../components/forms/AdditionalInfoForm';
import LoginFormImage from '../../assets/images/login-from-right-side-image.png'
// import LoginFormImage from '../../assets/images/login-from-right-side-image.png'
import GoogleIconImage from '../../assets/images/google-icon.png'
import LoginFromBackgroundImage from '../../assets/images/login-from-background-image.png'
const { Title } = Typography;

interface LoginFormProps {
  error: string | null;
  success: string | null;
  loading: boolean;
  showAdditionalInfo: boolean;
  additionalInfo: AdditionalInfo;
  setAdditionalInfo: React.Dispatch<React.SetStateAction<AdditionalInfo>>;
  isRegistering: boolean;
  setIsRegistering: React.Dispatch<React.SetStateAction<boolean>>;
  handleEmailLogin: (values: { email: string; password: string }) => void;
  handleEmailRegister: (values: { email: string; password: string }) => void;
  handleGoogleLogin: () => void;
  handleAdditionalInfoSubmit: (info: AdditionalInfo) => void;
  isForgotPasswordModalVisible: boolean;
  setIsForgotPasswordModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  forgotPasswordEmail: string;
  setForgotPasswordEmail: React.Dispatch<React.SetStateAction<string>>;
  handleForgotPassword: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({
  error,
  success,
  loading,
  showAdditionalInfo,
  additionalInfo,
  setAdditionalInfo,
  isRegistering,
  setIsRegistering,
  handleEmailLogin,
  handleEmailRegister,
  handleGoogleLogin,
  handleAdditionalInfoSubmit,
  isForgotPasswordModalVisible,
  setIsForgotPasswordModalVisible,
  forgotPasswordEmail,
  setForgotPasswordEmail,
  handleForgotPassword
}) => {
  return (
    <>
    <section className="login-form-section">
      <div className="login-from-background-image">
        <img src={LoginFromBackgroundImage} alt="" />
      </div>
      <div className="container">
        <div className="login-from-image-total-section">
          <div className="row align-items-center login-from-row-section">
            <div className="col-md-6">
              <Card className="card-custom login-form-card">
                <Title level={1} className="brand-highlight login-from-card-title">Welcome to Praro</Title>
                {error && <Alert message={error} type="error" showIcon style={{ marginBottom: 16 }} />}
                {success && <Alert message={success} type="success" showIcon style={{ marginBottom: 16 }} />}
                {loading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spin size="large" />
                  </div>
                ) : (
                  !showAdditionalInfo ? (
                    <>
                      <Form
                        name="email_login"
                        initialValues={{ remember: true }}
                        onFinish={isRegistering ? handleEmailRegister : handleEmailLogin}
                        style={{ marginTop: 16 }}
                      >
                        <Title level={3} className="auth-title">
                          {isRegistering ? 'Create a New Account' : 'Login to Your Account'}
                        </Title>
                        <div className="login-form-input-section">
                          <div className="input-field-icon">
                            <i className="fa-light fa-envelope"></i>
                          </div>
                          <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Please input your Email!' }]}
                          >
                            <Input className='login-form-input' placeholder="Email" />
                          </Form.Item>
                        </div>
                        <div className="login-form-input-section">
                          <div className="input-field-icon">
                            <i className="fa-light fa-lock-keyhole"></i>
                          </div>
                          <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your Password!' }]}
                            style={{ marginBottom: 8 }}
                          >
                            <Input.Password className='login-form-input' placeholder="Password" />
                          </Form.Item>
                        </div>
                        {!isRegistering && (
                          <Button className='login-from-forgot-password-btn' type="link" block onClick={() => setIsForgotPasswordModalVisible(true)} style={{ marginBottom: 16 }}>
                            Forgot your password?
                          </Button>
                        )}
                        <Form.Item>
                          <Button className='login-from-login-btn' type="primary" htmlType="submit" block>
                            {isRegistering ? 'Create Account' : 'Login'}
                            &nbsp; <i className="fa-solid fa-arrow-right-long"></i>
                          </Button>
                        </Form.Item>
                      </Form>
                      <div className="create-acount-google-sign-in-btn">
                        <Button className='create-acount-btn' type="link" block onClick={() => setIsRegistering(!isRegistering)} style={{ marginTop: 8 }}>
                          {isRegistering ? 'Already have an account? Login' : 'Create Account'}
                        </Button>
                        <Button className='google-sign-in-btn' type="default" block onClick={handleGoogleLogin} style={{ marginTop: 16 }}>
                          <img src={GoogleIconImage} alt="" />&nbsp;
                            Sign in with google
                        </Button>
                      </div>
                    </>
                  ) : (
                    <AdditionalInfoForm
                      additionalInfo={additionalInfo}
                      setAdditionalInfo={setAdditionalInfo}
                      handleAdditionalInfoSubmit={handleAdditionalInfoSubmit}
                    />
                  )
                )}
              </Card>
            </div>
            <div className="col-md-6">
              <div className="login-form-right-side-image">
                <img src={LoginFormImage} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='forgot-password-modal'>
      <Modal
        title="Forgot Password"
        open={isForgotPasswordModalVisible}
        onOk={handleForgotPassword}
        onCancel={() => setIsForgotPasswordModalVisible(false)}
        okText="Send Reset Email"
      >
        <Form>
          <div className="login-form-input-section">
            <div className="input-field-icon">
              <i className="fa-light fa-envelope"></i>
            </div>
            <Form.Item
              name="forgotPasswordEmail"
              rules={[{ required: true, message: 'Please input your Email!' }]}
            >
              <Input 
                className='login-form-input'
                placeholder="Email"
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </section>
    </>
  );
};

export default LoginForm;
