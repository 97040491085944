import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../features/auth/AuthProvider';
import { logWithTimestamp } from '../../utils/logging';

interface ProtectedRouteProps {
  element: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const { user } = useAuth();
  const location = useLocation();
  
  logWithTimestamp('ProtectedRoute', 'Initializing', location.pathname);
  
  useEffect(() => {
    logWithTimestamp('ProtectedRoute', 'Effect running', location.pathname, 'User:', !!user);
  }, [location.pathname, user]);
  
  if (!user) {
    logWithTimestamp('ProtectedRoute', 'No user, redirecting to login');
    // Save the attempted route location
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  
  // Always allow the protected route to render when user is authenticated
  logWithTimestamp('ProtectedRoute', 'Rendering element for path', location.pathname);
  return element;
};

export default ProtectedRoute; 