import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/font-awesome-6/css/all.min.css';
import './assets/css/menu.css';
import './assets/css/style.css';
// import './styles.css';
import './assets/css/fixing.css';
import './assets/css/responsive.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, useNavigationType, useLocation } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { User, getAuth, onAuthStateChanged } from 'firebase/auth';
import { AuthProvider } from './features/auth/AuthProvider';

// Utility for logging with timestamps
const logWithTimestamp = (prefix: string, ...args: any[]) => {
  const now = new Date();
  const timestamp = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}.${now.getMilliseconds()}`;
  console.log(`[${timestamp}][${prefix}]`, ...args);
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
};
const app = initializeApp(firebaseConfig);

// Component to prevent navigation away from /classroom
const RouteGuard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigationType = useNavigationType();
  const location = useLocation();
  const previousPathRef = useRef<string>(window.location.pathname);
  
  useEffect(() => {
    // No longer blocking navigation from /classroom
    logWithTimestamp('RouteGuard', `Navigation to ${location.pathname}`);
    previousPathRef.current = location.pathname;
    
    return () => {
      // No event listeners to clean up
    };
  }, [location]);
  
  return <>{children}</>;
};

const Root: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  // Monitor actual URL changes in the browser
  useEffect(() => {
    // Keep track of all URL changes
    const observeUrlChanges = () => {
      logWithTimestamp('Root', 'URL changed to:', window.location.pathname);
      
      // No longer adding protection for /classroom path
      // This allows links to be clickable throughout the application
    };
    
    // Observe both popstate and hashchange events
    window.addEventListener('popstate', observeUrlChanges);
    window.addEventListener('hashchange', observeUrlChanges);
    
    // Initial call
    observeUrlChanges();
    
    return () => {
      window.removeEventListener('popstate', observeUrlChanges);
      window.removeEventListener('hashchange', observeUrlChanges);
    };
  }, []);

  return (
    <BrowserRouter>
      <RouteGuard>
        <AuthProvider app={app} user={user}>
          <App />
        </AuthProvider>
      </RouteGuard>
    </BrowserRouter>
  );
};

root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
