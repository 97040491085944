/**
 * Utility for consistent logging with timestamps
 * @param prefix The prefix to use for the log message
 * @param args The arguments to log
 */
export const logWithTimestamp = (prefix: string, ...args: any[]) => {
  const now = new Date();
  const timestamp = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}.${now.getMilliseconds()}`;
  console.log(`[${timestamp}][${prefix}]`, ...args);
};
