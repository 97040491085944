import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import { ExportDialogProps } from '../../types/responseCard.types';

const ExportDialog: React.FC<ExportDialogProps> = ({ 
  open, 
  onClose, 
  onViewContent 
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <div className="dialog-cancel-btn">
        <button onClick={onClose}>
          <i className="fa-solid fa-circle-xmark"></i>
        </button>
      </div>
      <DialogTitle>
        <div className="dialog-title-section">
          {/* <div className="success-icon">
            <i className="fa-solid fa-check-circle" style={{ color: '#044800', fontSize: '48px' }}></i>
          </div> */}
          <h2 className="dialog-title" style={{fontSize: '24px', fontWeight: '500', color: '#1677ff'}}>Draft ready! Add your personal touch before sharing</h2>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="export-dialog-content">
          <div className="access-info-section">
            <p style={{fontSize: '16px', fontWeight: '500', color: '#757575'}}>Your assignment is saved in your content library.</p>
            
            <div className="dialog-actions" style={{ gap: '15px', display: 'flex', marginTop: '20px', justifyContent: 'space-between' }}>
              <Button
                onClick={onClose}
                variant="outlined"
                className="dialog-button dismiss-button"
                style={{ borderColor: '#044800', color: '#044800' }}
              >
                LATER
              </Button>
              <Button
                onClick={onViewContent}
                variant="contained"
                className="dialog-button view-content-button"
                style={{ backgroundColor: '#044800', color: 'white' }}
              >
                GO TO MY CONTENT
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ExportDialog;