import React, { useState, useEffect, CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import QuizPage from '../../features/quiz/QuizPage';
import ImprovedQuizResults from '../../features/quiz/ImprovedQuizResults';
import '../../features/quiz/ImprovedQuizResults.css';  // Add the CSS import
import StreamingQuiz from '../../features/quiz/StreamingQuiz';
import 'katex/dist/katex.min.css';
import './responsive.css';

interface ResponseContentProps {
  card_id: string;
  content: string;
  grade: string;
  standard: string;
  topic?: string; // Add topic prop
  setResponse?: (response: string) => void;
}

// Add responsive styles
const styles: Record<string, CSSProperties> = {
  markdownContent: {
    maxWidth: '100%',
    overflowX: 'auto',
    wordBreak: 'break-word',
    lineHeight: 1.6,
    fontSize: '16px'
  },
  tableContainer: {
    overflowX: 'auto',
    maxWidth: '100%',
    display: 'block',
    marginBottom: '1rem'
  },
  codeBlock: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    overflowX: 'auto',
    backgroundColor: '#f6f8fa',
    padding: '16px',
    borderRadius: '6px',
    maxWidth: '100%'
  },
  listItem: {
    marginLeft: '10px',
    paddingLeft: '10px'
  }
};

const ResponseContent: React.FC<ResponseContentProps> = ({
  card_id,
  content,
  grade,
  standard,
  topic = "Current Topic", // Default value
  setResponse
}) => {
  const navigate = useNavigate();
  const [showResults, setShowResults] = useState(false);
  const [quizResults, setQuizResults] = useState<any>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  // Add responsive listener for screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    // Set initial value
    handleResize();
    
    // Add event listener
    window.addEventListener('resize', handleResize);
    
    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleQuizComplete = (results: any) => {
    setQuizResults(results);
    setShowResults(true);
  };

  const handleRetakeQuiz = () => {
    setShowResults(false);
    setQuizResults(null);
  };

  // Mock function for onRequestNewQuiz to satisfy prop requirements
  const handleRequestNewQuiz = (difficulty: string) => {
    console.log(`Request for new quiz with difficulty: ${difficulty}`);
    // In a real implementation, this would request a new quiz
    handleRetakeQuiz();
  };

  // Custom renderer components for mobile-friendly markdown
  const renderers = {
    table: ({ node, ...props }: any) => (
      <div style={styles.tableContainer}>
        <table {...props} style={{ borderCollapse: 'collapse', width: '100%' }} />
      </div>
    ),
    code: ({ node, inline, ...props }: any) => 
      inline ? 
        <code {...props} style={{ backgroundColor: '#f0f0f0', padding: '2px 4px', borderRadius: '3px' }} /> : 
        <div style={styles.codeBlock}>
          <code {...props} />
        </div>
  };

  // Handle quiz type specially
  if (card_id === 'quiz') {
    if (showResults && quizResults) {
      return (
        <ImprovedQuizResults  // Changed from QuizResults
          results={quizResults}
          onRetake={handleRetakeQuiz}
          questions={[]} 
          topic={topic}
          onRequestNewQuiz={handleRequestNewQuiz}
        />
      );
    }

    return (
      <StreamingQuiz 
        content={content}
        setResponse={setResponse}
        topic={topic}
        grade={grade}
        standard={standard}
      />
    );
  }

  // All other types use standard markdown rendering with custom styles
  return (
    <div className="markdown-content" style={styles.markdownContent}>
      <ReactMarkdown
        remarkPlugins={[remarkGfm, remarkMath]}
        rehypePlugins={[rehypeKatex]}
        components={renderers}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default ResponseContent;