import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import { useAuth } from '../src/features/auth/AuthProvider';
import Header from '../src/components/layout/Header/Header';
import Footer from '../src/components/layout/Footer/Footer';
import ScrollToTop from '../src/components/common/ScrollToTop';
import { AppRoutes, specialRoutes } from './routes';
import { logWithTimestamp } from './utils/logging';

const { Content } = Layout;

const App: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const lastPathRef = useRef<string>(location.pathname);
  const isOnClassroomPage = useRef<boolean>(location.pathname === specialRoutes.classroom);
  const isInitialRender = useRef<boolean>(true);
  
  // First useEffect to run - highest priority - capture and preserve classroom path
  useEffect(() => {
    // Set a flag in session storage immediately if we're on classroom path
    if (location.pathname === specialRoutes.classroom) {
      logWithTimestamp('App', 'Setting classroom flag in session storage');
      sessionStorage.setItem('on_classroom_page', 'true');
      isOnClassroomPage.current = true;
    }
    
    // Check if we need to restore classroom path immediately
    const onClassroomPage = sessionStorage.getItem('on_classroom_page') === 'true';
    
    if (onClassroomPage && location.pathname !== specialRoutes.classroom && isInitialRender.current) {
      logWithTimestamp('App', 'CRITICAL: Restoring classroom path after refresh');
      // Use replace state directly to avoid any potential issues with navigate
      window.history.replaceState(null, '', specialRoutes.classroom);
      isOnClassroomPage.current = true;
    }
    
    isInitialRender.current = false;
  }, [location.pathname]);
  
  // Add global path monitoring without forcing navigation
  useEffect(() => {
    logWithTimestamp('App', 'Path changed:', location.pathname, 'from:', lastPathRef.current);
    
    // Track if we're on classroom page
    if (location.pathname === specialRoutes.classroom) {
      logWithTimestamp('App', 'Classroom page detected, setting flags');
      isOnClassroomPage.current = true;
      sessionStorage.setItem('on_classroom_page', 'true');
    } else if (lastPathRef.current === specialRoutes.classroom && location.pathname !== specialRoutes.classroom) {
      // We just navigated away from classroom
      logWithTimestamp('App', 'Navigated away from classroom page, clearing flags');
      isOnClassroomPage.current = false;
      sessionStorage.removeItem('on_classroom_page');
    }
    
    lastPathRef.current = location.pathname;
  }, [location.pathname]);
  
  // Main navigation effect - run after path monitoring
  useEffect(() => {
    logWithTimestamp('Navigation', 'Starting navigation check');
    logWithTimestamp('Navigation', 'Current location:', location.pathname);
    logWithTimestamp('Navigation', 'Location state:', location.state);
    logWithTimestamp('Navigation', 'User authenticated:', !!user);
    logWithTimestamp('Navigation', 'isOnClassroomPage:', isOnClassroomPage.current);
    
    // CRITICAL: Skip all redirects if we're on classroom page or coming from classroom
    if (isOnClassroomPage.current || location.pathname === specialRoutes.classroom || 
        location.pathname.startsWith(`${specialRoutes.classroom}/`)) {
      logWithTimestamp('Navigation', 'On classroom page - BLOCKING ALL REDIRECTS');
      return;
    }
    
    if (user) {
      const state = location.state as { from?: { pathname: string } };
      logWithTimestamp('Navigation', 'Navigation state:', state);
      
      if (location.pathname === specialRoutes.login) {
        const targetPath = state?.from?.pathname || specialRoutes.default;
        // Don't redirect to /views if coming from /classroom
        if (targetPath === specialRoutes.classroom || targetPath.startsWith(`${specialRoutes.classroom}/`)) {
          logWithTimestamp('Navigation', 'Redirecting from login to classroom:', targetPath);
          navigate(targetPath, { replace: true });
        } else {
          logWithTimestamp('Navigation', 'Redirecting from login to:', targetPath);
          navigate(targetPath, { replace: true });
        }
      } else if (state?.from && location.pathname !== state.from.pathname) {
        // Don't redirect from /classroom
        if (state.from.pathname === specialRoutes.classroom || 
            state.from.pathname.startsWith(`${specialRoutes.classroom}/`)) {
          logWithTimestamp('Navigation', 'Not redirecting from classroom');
          return;
        }
        
        logWithTimestamp('Navigation', 'Redirecting to saved location:', state.from.pathname);
        navigate(state.from.pathname, { replace: true });
      } else {
        logWithTimestamp('Navigation', 'No redirection needed');
      }
    }
  }, [location.pathname, user, navigate]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header />
      <Content className='main-wrapper'>
        <AppRoutes />
        <Footer />
      </Content>
    </Layout>
  );
};

const AppWrapper = () => {
  // Check if we're on the classroom path
  const pathname = window.location.pathname;
  
  // Log for debugging
  logWithTimestamp('AppWrapper', 'Initializing with path:', pathname);
  
  // Check if we're on classroom path and set it directly
  useEffect(() => {
    if (pathname === specialRoutes.classroom) {
      logWithTimestamp('AppWrapper', 'Found classroom path on mount, ensuring it stays');
      // Add a listener to prevent location changes
      const handleBeforeUnload = (e: BeforeUnloadEvent) => {
        logWithTimestamp('AppWrapper', 'Page transition detected');
      };
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [pathname]);
  
  return (
    <>
      <ScrollToTop />
      <App />
    </>
  );
};

export default AppWrapper;
