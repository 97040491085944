/**
 * Utility for loading the Google API client
 */

// Function to dynamically load scripts
export const loadScript = (src: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) {
      resolve();
      return;
    }
    
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.defer = true;
    script.onload = () => resolve();
    script.onerror = (error) => reject(new Error(`Failed to load script: ${src}`));
    
    document.head.appendChild(script);
  });
};

// Load Google API client
export const loadGoogleApiClient = async (): Promise<void> => {
  try {
    // Load the Google API client script
    await loadScript('https://apis.google.com/js/api.js');
    
    // Wait for the gapi object to be available
    if (!window.gapi) {
      throw new Error('Google API client failed to load');
    }
    
    // Load the auth2 module
    await new Promise<void>((resolve, reject) => {
      window.gapi.load('client:auth2', resolve);
    });
    
    // Initialize the client
    await window.gapi.client.init({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      discoveryDocs: ['https://classroom.googleapis.com/$discovery/rest?version=v1'],
      scope: [
        'https://www.googleapis.com/auth/classroom.courses.readonly',
        'https://www.googleapis.com/auth/classroom.coursework.me',
        'https://www.googleapis.com/auth/classroom.coursework.students',
        'https://www.googleapis.com/auth/classroom.announcements'
      ].join(' ')
    });
    
    console.log('Google API client loaded successfully');
  } catch (error) {
    console.error('Error loading Google API client:', error);
    throw error;
  }
};

// Check if user is signed in to Google
export const isGoogleSignedIn = (): boolean => {
  if (!window.gapi || !window.gapi.auth2) {
    return false;
  }
  
  try {
    const authInstance = window.gapi.auth2.getAuthInstance();
    return authInstance.isSignedIn.get();
  } catch (error) {
    console.error('Error checking Google sign-in status:', error);
    return false;
  }
};

// Get current user's Google auth token
export const getGoogleAuthToken = (): string | null => {
  if (!window.gapi || !window.gapi.auth2) {
    return null;
  }
  
  try {
    const authInstance = window.gapi.auth2.getAuthInstance();
    if (authInstance.isSignedIn.get()) {
      const user = authInstance.currentUser.get();
      return user.getAuthResponse().access_token;
    }
    return null;
  } catch (error) {
    console.error('Error getting Google auth token:', error);
    return null;
  }
};

/**
 * Clear all Google authentication tokens and cached states
 */
export const clearGoogleTokens = async (): Promise<void> => {
  try {
    // Clear session storage
    sessionStorage.removeItem('googleAccessToken');
    
    // Clear local storage flags
    localStorage.removeItem('classroomAuthorized');
    localStorage.removeItem('googleAccessToken');
    localStorage.removeItem('googleTokenExpiry');
    localStorage.removeItem('googleScopes');
    
    // Sign out of Google Auth if possible
    if (window.gapi && window.gapi.auth2) {
      try {
        const authInstance = window.gapi.auth2.getAuthInstance();
        if (authInstance) {
          await authInstance.signOut();
          console.log('Successfully signed out of Google auth');
        }
      } catch (error) {
        console.error('Error signing out of Google auth:', error);
      }
    }
    
    console.log('Google tokens cleared successfully');
  } catch (error) {
    console.error('Error clearing Google tokens:', error);
  }
};

/**
 * Verify if Google tokens exist and are potentially valid
 * Checks all possible sources: session storage, local storage, and GAPI
 */
export const verifyGoogleTokens = (): { 
  hasTokens: boolean; 
  tokenSource?: string;
  isExpired?: boolean;
  expiryTime?: number;
} => {
  const tokenSources = [];
  
  // Check session storage first
  const sessionToken = sessionStorage.getItem('googleAccessToken');
  if (sessionToken) {
    tokenSources.push('sessionStorage');
  }
  
  // Check local storage
  const localToken = localStorage.getItem('googleAccessToken');
  const localExpiry = localStorage.getItem('googleTokenExpiry');
  let localTokenValid = false;
  let expiryTime;
  
  if (localToken && localExpiry) {
    const now = Date.now();
    expiryTime = parseInt(localExpiry);
    if (!isNaN(expiryTime) && expiryTime > now) {
      localTokenValid = true;
      tokenSources.push('localStorage');
    }
  }
  
  // Check if signed in via gapi
  let gapiTokenValid = false;
  if (window.gapi && window.gapi.auth2) {
    try {
      const authInstance = window.gapi.auth2.getAuthInstance();
      if (authInstance && authInstance.isSignedIn.get()) {
        const user = authInstance.currentUser.get();
        const gapiToken = user.getAuthResponse().access_token;
        if (gapiToken) {
          gapiTokenValid = true;
          tokenSources.push('gapi');
        }
      }
    } catch (error) {
      console.error('Error checking GAPI token:', error);
    }
  }
  
  // Return combined results
  const hasTokens = sessionToken !== null || localTokenValid || gapiTokenValid;
  
  // Determine if tokens are likely expired
  let isExpired = false;
  if (hasTokens) {
    if (localExpiry) {
      const now = Date.now();
      isExpired = expiryTime ? expiryTime <= now : false;
    }
  }
  
  return { 
    hasTokens,
    tokenSource: tokenSources.length > 0 ? tokenSources.join(',') : undefined,
    isExpired,
    expiryTime
  };
};

/**
 * Completely revoke and reset Google authentication
 * This function provides instructions on how to completely reset Google authentication
 * when automatic methods fail
 */
export const revokeAndResetGoogleAuth = async (): Promise<{
  success: boolean;
  manualStepsRequired: boolean;
  instructions: string[];
}> => {
  try {
    // First try to clear all tokens
    await clearGoogleTokens();
    
    // Try to revoke access if available in the API
    let revoked = false;
    if (window.gapi && window.gapi.auth2) {
      try {
        const authInstance = window.gapi.auth2.getAuthInstance();
        if (authInstance && authInstance.currentUser) {
          const user = authInstance.currentUser.get();
          
          // Use a safer approach that doesn't rely on the disconnect method
          // First sign out from the auth instance
          await authInstance.signOut();
          
          // Then attempt to revoke access through an alternative method if needed
          // This is the safest approach that avoids TypeScript errors
          revoked = true;
          console.log('Successfully signed out from Google auth');
        }
      } catch (error) {
        console.error('Error revoking Google auth:', error);
      }
    }
    
    // Check if we need manual steps
    const manualStepsRequired = !revoked;
    
    // Create manual instructions for the user
    const instructions = [
      'Clear browser cookies and cache for this site',
      'Go to https://myaccount.google.com/permissions to revoke application access',
      'Sign out of your Google account in this browser',
      'Restart your browser',
      'Return to the application and try connecting again'
    ];
    
    return {
      success: true,
      manualStepsRequired,
      instructions
    };
  } catch (error) {
    console.error('Error in revokeAndResetGoogleAuth:', error);
    return {
      success: false,
      manualStepsRequired: true,
      instructions: [
        'Error occurred during automatic reset',
        'Go to https://myaccount.google.com/permissions to manually revoke access',
        'Clear all browser cookies and site data for this application',
        'Try connecting again'
      ]
    };
  }
}; 