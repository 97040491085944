import { getFunctions, httpsCallable } from "firebase/functions";
import { getApp } from "firebase/app";
import { verifyGoogleTokens } from "../utils/googleApiLoader";

// Define types for our responses
interface ClassroomCourse {
  id: string;
  name: string;
  section?: string;
  description?: string;
}

interface ClassroomAssignment {
  id: string;
  title: string;
  description?: string;
  dueDate?: {
    year: number;
    month: number;
    day: number;
  };
  dueTime?: {
    hours: number;
    minutes: number;
  };
  maxPoints?: number;
  materials?: {
    link?: {
      url: string;
      // title?: string;
    };
  }[];
}

interface TokenRefreshResult {
  success: boolean;
  refreshed: boolean;
  accessToken?: string;
  expiryDate?: number;
  message?: string;
}

class FirebaseClassroomService {
  private functions: any;
  private tokenRefreshTimer: any = null;

  constructor() {
    try {
      this.functions = getFunctions(getApp(), "us-central1");
      console.log("Firebase functions initialized with region: us-central1");

      // Start token refresh monitoring if persistent connection is enabled
      this.initTokenRefreshMonitoring();
    } catch (error) {
      console.error("Error initializing Firebase functions:", error);
      // Continue without functions - we'll handle errors when methods are called
    }
  }

  /**
   * Initialize token refresh monitoring if persistent connection is enabled
   */
  private initTokenRefreshMonitoring() {
    try {
      const isPersistentConnection =
        localStorage.getItem("googleClassroomPersistentConnection") === "true";

      if (isPersistentConnection) {
        console.log(
          "Initializing token refresh monitoring for persistent Google Classroom connection"
        );

        // Clear any existing timer
        if (this.tokenRefreshTimer) {
          clearInterval(this.tokenRefreshTimer);
        }

        // Check token every 15 minutes (900000 ms)
        this.tokenRefreshTimer = setInterval(() => {
          this.checkAndRefreshTokenIfNeeded();
        }, 900000);

        // Also check immediately
        this.checkAndRefreshTokenIfNeeded();
      }
    } catch (error) {
      console.error("Error initializing token refresh monitoring:", error);
    }
  }

  /**
   * Check if token needs refreshing and refresh if needed
   */
  private async checkAndRefreshTokenIfNeeded() {
    try {
      console.log("Checking if token needs refreshing");

      // Get the current token expiry time
      const tokenExpiry = localStorage.getItem("googleTokenExpiry");

      if (!tokenExpiry) {
        console.log("No token expiry found, cannot check if refresh is needed");
        return;
      }

      const expiryTime = parseInt(tokenExpiry);
      const now = Date.now();

      // If token expires in less than 30 minutes (1800000 ms), refresh it
      if (expiryTime - now < 1800000) {
        console.log("Token is expiring soon, refreshing");
        await this.refreshToken();
      } else {
        console.log(
          `Token is still valid for ${Math.round(
            (expiryTime - now) / 60000
          )} minutes`
        );
      }
    } catch (error) {
      console.error("Error checking token refresh:", error);
    }
  }

  /**
   * Refresh the Google access token using the stored refresh token
   */
  public async refreshToken(): Promise<TokenRefreshResult> {
    try {
      console.log("Refreshing Google access token");

      // Call the Firebase function to refresh the token
      const result = await this.callFunction("refreshGoogleToken", {});

      if (result.success && result.refreshed) {
        console.log("Token refreshed successfully");

        // Update local storage with the new token
        sessionStorage.setItem("googleAccessToken", result.accessToken);
        localStorage.setItem("googleAccessToken", result.accessToken);
        localStorage.setItem("googleTokenExpiry", result.expiryDate.toString());

        return result;
      } else {
        console.log(
          "Token refresh not needed or not successful:",
          result.message
        );
        return result;
      }
    } catch (error: any) {
      console.error("Error refreshing token:", error);
      return {
        success: false,
        refreshed: false,
        message: error.message || "Unknown error refreshing token",
      };
    }
  }

  /**
   * Enable persistent connection
   */
  public async enablePersistentConnection(): Promise<boolean> {
    try {
      // Get the current credentials from localStorage
      const accessToken = localStorage.getItem("googleAccessToken");
      const refreshToken = localStorage.getItem("googleRefreshToken");
      const tokenExpiry = localStorage.getItem("googleTokenExpiry");
      const scopes = localStorage.getItem("googleScopes");

      if (!accessToken || !refreshToken) {
        console.error("Cannot enable persistent connection - missing tokens");
        return false;
      }

      // Create credentials object
      const credentials = {
        access_token: accessToken,
        refresh_token: refreshToken,
        expiry_date: tokenExpiry
          ? parseInt(tokenExpiry)
          : Date.now() + 3600 * 1000,
        scope: scopes || "",
        token_type: "Bearer",
      };

      // Call Firebase function to store with persistent flag
      const result = await this.callFunction("storeGoogleCredentials", {
        credentials,
        persistConnection: true,
      });

      if (result.success) {
        console.log("Persistent connection enabled successfully");
        localStorage.setItem("googleClassroomPersistentConnection", "true");

        // Start token refresh monitoring
        this.initTokenRefreshMonitoring();

        return true;
      } else {
        console.error("Failed to enable persistent connection");
        return false;
      }
    } catch (error) {
      console.error("Error enabling persistent connection:", error);
      return false;
    }
  }

  /**
   * Disable persistent connection
   */
  public async disablePersistentConnection(): Promise<boolean> {
    try {
      // Get the current credentials from localStorage
      const accessToken = localStorage.getItem("googleAccessToken");
      const refreshToken = localStorage.getItem("googleRefreshToken");
      const tokenExpiry = localStorage.getItem("googleTokenExpiry");
      const scopes = localStorage.getItem("googleScopes");

      if (!accessToken || !refreshToken) {
        console.error("Cannot disable persistent connection - missing tokens");
        return false;
      }

      // Create credentials object
      const credentials = {
        access_token: accessToken,
        refresh_token: refreshToken,
        expiry_date: tokenExpiry
          ? parseInt(tokenExpiry)
          : Date.now() + 3600 * 1000,
        scope: scopes || "",
        token_type: "Bearer",
      };

      // Call Firebase function to store without persistent flag
      const result = await this.callFunction("storeGoogleCredentials", {
        credentials,
        persistConnection: false,
      });

      if (result.success) {
        console.log("Persistent connection disabled successfully");
        localStorage.removeItem("googleClassroomPersistentConnection");

        // Stop token refresh monitoring
        if (this.tokenRefreshTimer) {
          clearInterval(this.tokenRefreshTimer);
          this.tokenRefreshTimer = null;
        }

        return true;
      } else {
        console.error("Failed to disable persistent connection");
        return false;
      }
    } catch (error) {
      console.error("Error disabling persistent connection:", error);
      return false;
    }
  }

  /**
   * Helper method to safely call Firebase functions with robust error handling
   */
  private async callFunction(
    functionName: string,
    data: any = {}
  ): Promise<any> {
    try {
      console.log(`Calling Firebase function: ${functionName}`);

      if (!this.functions) {
        this.functions = getFunctions(getApp(), "us-central1");
      }

      const functionCall = httpsCallable(this.functions, functionName);
      const result = await functionCall(data);

      console.log(`Function ${functionName} result:`, result.data);
      return result.data;
    } catch (error: any) {
      console.error(`Error calling function ${functionName}:`, error);

      // Check for common error types
      if (error.message && error.message.includes("CORS")) {
        console.error(
          "CORS error detected. This is likely a configuration issue with Firebase Functions."
        );
        return {
          error: "CORS_ERROR",
          message:
            "Network access blocked by browser security. Please check Firebase Functions configuration.",
        };
      }

      if (error.code === "functions/internal") {
        console.error("Internal server error in Firebase Function");
        return {
          error: "INTERNAL_SERVER_ERROR",
          message:
            "The server encountered an error. Please try again later or contact support.",
        };
      }

      // Rethrow the error for the calling function to handle
      throw error;
    }
  }

  /**
   * Check if the user has access to Google Classroom
   */
  public async checkClassroomAccess(): Promise<{
    hasAccess: boolean;
    error?: string;
  }> {
    try {
      // First check client-side tokens
      const sessionToken = sessionStorage.getItem("googleAccessToken");
      const localToken = localStorage.getItem("googleAccessToken");

      // If we have client-side tokens, check if they're still valid
      if (sessionToken || localToken) {
        console.log("Client-side tokens found, checking validity");

        // Check with server if possible
        try {
          const result = await this.callFunction("checkClassroomAccess", {});
          console.log("Server classroom access check result:", result);
          return result as { hasAccess: boolean; error?: string };
        } catch (serverError) {
          console.warn(
            "Server check failed, falling back to token presence check",
            serverError
          );

          // If server check fails but we have client tokens, be optimistic
          return {
            hasAccess: true,
            error: "Using client-side tokens, server verification failed",
          };
        }
      } else {
        // No client tokens, try server check
        console.log("No client-side tokens, checking with server");
        const result = await this.callFunction("checkClassroomAccess", {});
        return result as { hasAccess: boolean; error?: string };
      }
    } catch (error: any) {
      console.error("Error checking Classroom access:", error);
      return { hasAccess: false, error: error.message || "Unknown error" };
    }
  }

  /**
   * Get a list of Google Classroom courses
   */
  public async getCourses(): Promise<ClassroomCourse[]> {
    try {
      // First, check if we need to refresh the token
      await this.checkAndRefreshTokenIfNeeded();

      console.log("Calling Firebase function: getClassroomCourses");
      const result = await this.callFunction("getClassroomCourses", {});

      if (!result) {
        console.error("No data returned from getClassroomCourses function");
        return [];
      }

      if (result.error) {
        console.error(
          "Error returned from getClassroomCourses function:",
          result.error
        );
        throw new Error(result.message || "Failed to fetch courses");
      }

      const courseData = result as { courses: ClassroomCourse[] };
      console.log(
        `Received ${
          courseData.courses?.length || 0
        } courses from Firebase function`
      );

      if (!courseData.courses || courseData.courses.length === 0) {
        console.log("No courses returned from Firebase function");
      }

      return courseData.courses || [];
    } catch (error: any) {
      console.error("Error fetching courses:", error);
      // Extract more detailed error information if available
      const errorMessage = error.message || "Unknown error";
      const errorCode = error.code || "unknown";
      const errorDetails = error.details
        ? JSON.stringify(error.details)
        : "No details";

      console.error(
        `Error Code: ${errorCode}, Message: ${errorMessage}, Details: ${errorDetails}`
      );
      throw new Error(
        `Failed to fetch Google Classroom courses: ${errorMessage} (${errorCode})`
      );
    }
  }

  /**
   * Create a new assignment in a Google Classroom course
   */
  public async createAssignment(
    courseId: string,
    assignment: Omit<ClassroomAssignment, "id">
  ): Promise<ClassroomAssignment | null> {
    try {
      // First, check if we need to refresh the token
      await this.checkAndRefreshTokenIfNeeded();

      console.log(`Creating assignment in course ${courseId}:`, assignment);
      const result = await this.callFunction("createClassroomAssignment", {
        courseId,
        assignment,
      });

      if (result.error) {
        throw new Error(
          `Error creating assignment: ${result.message || result.error}`
        );
      }

      return result as ClassroomAssignment;
    } catch (error: any) {
      console.error("Error creating assignment:", error);
      // Instead of returning null, throw the error so it can be caught and displayed
      throw new Error(
        `Error creating assignment: ${error.message || "Unknown error"}`
      );
    }
  }

  /**
   * Publish an assignment in Google Classroom
   */
  public async publishAssignment(
    courseId: string,
    courseWorkId: string
  ): Promise<ClassroomAssignment | null> {
    try {
      console.log(
        "Publishing assignment in Google Classroom: ",
        courseId,
        courseWorkId
      );
      const result = await this.callFunction("publishClassroomAssignment", {
        courseId,
        courseWorkId,
      });

      if (result.error) {
        throw new Error(
          `Error publishing assignment: ${result.message || result.error}`
        );
      }

      return result as ClassroomAssignment;
    } catch (error: any) {
      console.error("Error publishing assignment:", error);
      throw new Error(
        `Error publishing assignment: ${error.message || "Unknown error"}`
      );
    }
  }

  /**
   * Create a new Google Classroom
   */
  public async createClassroom(classroom: {
    name: string;
    section?: string;
    description?: string;
  }): Promise<ClassroomCourse | null> {
    try {
      console.log("Creating new Google Classroom:", classroom);
      const result = await this.callFunction("createNewClassroom", classroom);

      if (result.error) {
        throw new Error(
          `Error creating classroom: ${result.message || result.error}`
        );
      }

      return result as ClassroomCourse;
    } catch (error: any) {
      console.error("Error creating classroom:", error);
      throw new Error(
        `Error creating classroom: ${error.message || "Unknown error"}`
      );
    }
  }

  /**
   * Check the status of Google Classroom tokens
   */
  public async checkTokens(): Promise<{
    hasTokens: boolean;
    isExpired?: boolean;
    hasClassroomCoursesScope?: boolean;
    hasClassroomCourseworkScope?: boolean;
    scopes?: string[];
    tokenValidated?: boolean;
    message: string;
  }> {
    try {
      // First check client-side token status (browser storage)
      const sessionToken = sessionStorage.getItem("googleAccessToken");
      const localToken = localStorage.getItem("googleAccessToken");
      const localExpiry = localStorage.getItem("googleTokenExpiry");
      const localScopes = localStorage.getItem("googleScopes");

      console.log("Client-side tokens check:");
      console.log("- Session token exists:", !!sessionToken);
      console.log("- Local token exists:", !!localToken);
      console.log("- Local expiry exists:", !!localExpiry);
      console.log("- Local scopes exist:", !!localScopes);

      // Parse scope information if available
      let hasClassroomCoursesScope = false;
      let scopesList: string[] = [];

      if (localScopes) {
        scopesList = localScopes.split(" ");
        hasClassroomCoursesScope =
          localScopes.includes(
            "https://www.googleapis.com/auth/classroom.courses"
          ) ||
          localScopes.includes(
            "https://www.googleapis.com/auth/classroom.courses.readonly"
          );

        console.log("- Local scope check - courses:", hasClassroomCoursesScope);
      }

      // Check if local tokens are still valid
      let localTokensValid = false;
      if (localToken && localExpiry) {
        const now = Date.now();
        const expiryTime = parseInt(localExpiry);
        localTokensValid = !isNaN(expiryTime) && expiryTime > now;
        console.log("- Local tokens valid:", localTokensValid);
      }

      // Use the verifyGoogleTokens utility which checks gapi state
      const clientTokenCheck = verifyGoogleTokens();
      console.log("- GAPI token check:", clientTokenCheck.hasTokens);

      // First try with server-side check
      console.log("Calling Firebase function: checkClassroomTokens");
      const result = await this.callFunction("checkClassroomTokens", {});
      console.log("Token check result from server:", result);

      // If server doesn't have tokens but we have them client-side, let's assume they're valid
      if (
        !result.hasTokens &&
        (localTokensValid || clientTokenCheck.hasTokens)
      ) {
        console.warn(
          "Server missing tokens, but client has valid tokens. Using client tokens."
        );

        return {
          hasTokens: true,
          isExpired: false,
          hasClassroomCoursesScope: hasClassroomCoursesScope,
          scopes: scopesList,
          tokenValidated: false, // Indicate we couldn't validate with server
          message: "Using client-side tokens. Server tokens not available.",
        };
      }

      // If server has tokens but is missing scope info, supplement with local scope info
      if (result.hasTokens && !result.hasClassroomCoursesScope && localScopes) {
        console.log(
          "Server missing scope info, supplementing with client-side scope data"
        );

        return {
          ...result,
          hasClassroomCoursesScope:
            result.hasClassroomCoursesScope || hasClassroomCoursesScope,
          scopes: result.scopes || scopesList,
          tokenValidated: true,
          message:
            result.message + " (Supplemented with client-side scope data)",
        };
      }

      return result;
    } catch (error: any) {
      console.error("Error checking tokens:", error);

      // If server check fails but we have client tokens, return optimistic result
      const sessionToken = sessionStorage.getItem("googleAccessToken");
      const localToken = localStorage.getItem("googleAccessToken");
      const localScopes = localStorage.getItem("googleScopes");

      if (sessionToken || localToken) {
        console.log(
          "Server token check failed, but client tokens exist. Using client tokens."
        );

        // Try to determine scope information from local storage
        let hasClassroomCoursesScope = false;
        let scopes: string[] = [];

        if (localScopes) {
          scopes = localScopes.split(" ");
          hasClassroomCoursesScope =
            localScopes.includes(
              "https://www.googleapis.com/auth/classroom.courses"
            ) ||
            localScopes.includes(
              "https://www.googleapis.com/auth/classroom.courses.readonly"
            );
        }

        return {
          hasTokens: true,
          isExpired: false,
          hasClassroomCoursesScope,
          scopes,
          tokenValidated: false,
          message:
            "Using client-side tokens. Server check failed: " +
            (error.message || "Unknown error"),
        };
      }

      return {
        hasTokens: false,
        message: `Error checking tokens: ${error.message || "Unknown error"}`,
      };
    }
  }

  /**
   * Debug function to directly test course fetching
   */
  public async debugGetCourses(): Promise<any> {
    try {
      console.log("Calling debug function: debugGetCourses");
      const result = await this.callFunction("debugGetCourses", {});
      console.log("Debug result:", result);
      return result;
    } catch (error: any) {
      console.error("Debug error:", error);
      return {
        error: error.message || "Unknown error",
        stack: error.stack,
      };
    }
  }

  /**
   * Direct check for classroom courses
   */
  public async directCheckClassroomCourses(): Promise<any> {
    try {
      console.log("Calling direct check function");
      const result = await this.callFunction("directCheckClassroomCourses", {});
      console.log("Direct check result:", result);
      return result;
    } catch (error: any) {
      console.error("Direct check error:", error);
      return {
        error: error.message || "Unknown error",
      };
    }
  }

  async clearTokens() {
    // Logic to clear tokens, e.g., remove them from local storage or reset them in your application state
    localStorage.removeItem("google_access_token");
    localStorage.removeItem("google_refresh_token");
    // Add any other necessary cleanup logic
  }

  /**
   * Clear Google Classroom credentials and disable persistent connection
   */
  public async clearGoogleCredentials(): Promise<{
    success: boolean;
    message: string;
  }> {
    try {
      console.log("Calling Firebase function: clearGoogleCredentials");

      // First, disable persistent connection
      localStorage.removeItem("googleClassroomPersistentConnection");

      // Stop token refresh monitoring
      if (this.tokenRefreshTimer) {
        clearInterval(this.tokenRefreshTimer);
        this.tokenRefreshTimer = null;
      }

      // Remove tokens from local storage
      sessionStorage.removeItem("googleAccessToken");
      localStorage.removeItem("googleAccessToken");
      localStorage.removeItem("googleRefreshToken");
      localStorage.removeItem("googleTokenExpiry");
      localStorage.removeItem("googleScopes");

      const result = await this.callFunction("clearGoogleCredentials", {});

      console.log("Clear credentials result:", result);
      return result as {
        success: boolean;
        message: string;
      };
    } catch (error: any) {
      console.error("Error clearing credentials:", error);
      return {
        success: false,
        message: `Error clearing credentials: ${
          error.message || "Unknown error"
        }`,
      };
    }
  }

  /**
   * Check if persistent connection is enabled
   */
  public async isPersistentConnectionEnabled(): Promise<boolean> {
    try {
      // First check local storage
      const localPersistent =
        localStorage.getItem("googleClassroomPersistentConnection") === "true";

      if (localPersistent) {
        // Double-check with server
        try {
          const result = await this.callFunction(
            "isPersistentConnectionEnabled",
            {}
          );

          if (result && typeof result.enabled === "boolean") {
            if (result.enabled !== localPersistent) {
              // Update local storage to match server state
              if (result.enabled) {
                localStorage.setItem(
                  "googleClassroomPersistentConnection",
                  "true"
                );
                // Start token refresh monitoring
                this.initTokenRefreshMonitoring();
              } else {
                localStorage.removeItem("googleClassroomPersistentConnection");
                // Stop token refresh monitoring
                if (this.tokenRefreshTimer) {
                  clearInterval(this.tokenRefreshTimer);
                  this.tokenRefreshTimer = null;
                }
              }
            }
            return result.enabled;
          }
        } catch (error) {
          console.error(
            "Error checking persistent connection with server:",
            error
          );
          // If server check fails, rely on local storage
        }
      } else {
        // Local storage says not enabled, verify with server
        try {
          const result = await this.callFunction(
            "isPersistentConnectionEnabled",
            {}
          );

          if (result && result.enabled) {
            // Server says enabled but local storage says no - sync them
            localStorage.setItem("googleClassroomPersistentConnection", "true");
            // Start token refresh monitoring
            this.initTokenRefreshMonitoring();
            return true;
          }
        } catch (error) {
          console.error(
            "Error checking persistent connection with server:",
            error
          );
        }
      }

      return localPersistent;
    } catch (error) {
      console.error("Error checking persistent connection status:", error);
      return false;
    }
  }
}

export default FirebaseClassroomService;
