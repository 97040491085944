import axios from "axios";
import {
  loadGoogleApiClient,
  isGoogleSignedIn,
  getGoogleAuthToken,
} from "../utils/googleApiLoader";

// Define types for our services
interface ClassroomCourse {
  id: string;
  name: string;
  section?: string;
  description?: string;
}

interface ClassroomAssignment {
  id: string;
  title: string;
  description?: string;
  dueDate?: {
    year: number;
    month: number;
    day: number;
  };
  dueTime?: {
    hours: number;
    minutes: number;
  };
  maxPoints?: number;
  materials?: {
    link?: {
      url: string;
      title?: string;
    };
  }[];
}

interface CourseWorkData {
  title: string;
  description: string;
  workType: string;
  state: string;
  maxPoints: number;
  materials?: any[];
  dueDate?: {
    year: number;
    month: number;
    day: number;
  };
  dueTime?: {
    hours: number;
    minutes: number;
  };
}

class GoogleClassroomService {
  private static SCOPES = [
    "https://www.googleapis.com/auth/classroom.courses.readonly",
    "https://www.googleapis.com/auth/classroom.coursework.me",
    "https://www.googleapis.com/auth/classroom.coursework.students",
    "https://www.googleapis.com/auth/classroom.announcements",
  ];

  private token: string | null = null;
  private initialized: boolean = false;

  constructor() {
    // We'll initialize on-demand instead of in constructor
  }

  private async ensureInitialized(): Promise<boolean> {
    if (this.initialized) {
      return true;
    }

    try {
      await loadGoogleApiClient();
      this.initialized = true;

      // If user is already signed in, get the token
      if (isGoogleSignedIn()) {
        this.token = getGoogleAuthToken();
      }

      return true;
    } catch (error) {
      console.error("Failed to initialize Google Classroom service:", error);
      return false;
    }
  }

  public async signIn(): Promise<boolean> {
    try {
      // Make sure API is initialized
      if (!(await this.ensureInitialized())) {
        return false;
      }

      const authInstance = window.gapi.auth2.getAuthInstance();
      const isSignedIn = authInstance.isSignedIn.get();

      if (!isSignedIn) {
        // Force the consent screen to appear to ensure all scopes are granted
        // Use the auth2.GoogleAuth.SignInOptions type properly
        const user = await authInstance.signIn();

        // Trigger a scope authorization review if needed
        await window.gapi.client.init({
          scope: GoogleClassroomService.SCOPES.join(" "),
        });

        this.token = user.getAuthResponse().access_token;
      } else {
        const user = authInstance.currentUser.get();
        this.token = user.getAuthResponse().access_token;
      }

      return true;
    } catch (error) {
      console.error("Error signing in with Google", error);
      return false;
    }
  }

  public async signOut(): Promise<void> {
    try {
      // Make sure API is initialized
      if (!(await this.ensureInitialized())) {
        return;
      }

      const authInstance = window.gapi.auth2.getAuthInstance();
      await authInstance.signOut();
      this.token = null;
    } catch (error) {
      console.error("Error signing out from Google", error);
    }
  }

  public async getCourses(): Promise<ClassroomCourse[]> {
    try {
      // Make sure API is initialized
      if (!(await this.ensureInitialized())) {
        return [];
      }

      // Make sure user is signed in
      if (!this.token && !isGoogleSignedIn()) {
        await this.signIn();
      }

      const response = await window.gapi.client.classroom.courses.list({
        pageSize: 100,
        teacherId: "me",
      });

      return response.result.courses || [];
    } catch (error) {
      console.error("Error fetching courses", error);
      return [];
    }
  }

  public async createAssignment(
    courseId: string,
    assignment: Omit<ClassroomAssignment, "id">
  ): Promise<ClassroomAssignment | null> {
    try {
      // Make sure API is initialized
      if (!(await this.ensureInitialized())) {
        return null;
      }

      // Make sure user is signed in
      if (!this.token && !isGoogleSignedIn()) {
        await this.signIn();
      }

      // Format the assignment for Google Classroom API
      const courseWork: CourseWorkData = {
        title: assignment.title,
        description:
          assignment.description ||
          assignment.title ||
          "Assignment created from TeacherBot",
        workType: "ASSIGNMENT",
        state: "DRAFT",
        maxPoints: assignment.maxPoints || 100,
      };

      // Add materials if they exist
      if (assignment.materials && assignment.materials.length > 0) {
        courseWork.materials = assignment.materials;
      }

      // Add due date if it exists
      if (assignment.dueDate) {
        courseWork.dueDate = assignment.dueDate;
        if (assignment.dueTime) {
          courseWork.dueTime = assignment.dueTime;
        }
      }

      const response =
        await window.gapi.client.classroom.courses.courseWork.create(
          {
            courseId: courseId,
          },
          courseWork
        );

      return response.result;
    } catch (error) {
      console.error("Error creating assignment", error);
      return null;
    }
  }

  public async publishAssignment(
    courseId: string,
    courseWorkId: string
  ): Promise<ClassroomAssignment | null> {
    try {
      // Make sure API is initialized
      if (!(await this.ensureInitialized())) {
        return null;
      }

      // Make sure user is signed in
      if (!this.token && !isGoogleSignedIn()) {
        await this.signIn();
      }

      const response =
        await window.gapi.client.classroom.courses.courseWork.patch(
          {
            courseId: courseId,
            id: courseWorkId,
            updateMask: "state",
          },
          {
            state: "PUBLISHED",
          }
        );

      return response.result;
    } catch (error) {
      console.error("Error publishing assignment", error);
      return null;
    }
  }

  public async getAssignments(
    courseId: string
  ): Promise<ClassroomAssignment[]> {
    try {
      // Make sure API is initialized
      if (!(await this.ensureInitialized())) {
        return [];
      }

      // Make sure user is signed in
      if (!this.token && !isGoogleSignedIn()) {
        await this.signIn();
      }

      const response =
        await window.gapi.client.classroom.courses.courseWork.list({
          courseId: courseId,
          courseWorkStates: ["DRAFT", "PUBLISHED"],
        });

      return response.result.courseWork || [];
    } catch (error) {
      console.error("Error fetching assignments", error);
      return [];
    }
  }
}

export default new GoogleClassroomService();
export type { ClassroomCourse, ClassroomAssignment };
