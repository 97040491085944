import React, { useEffect, useState } from 'react';
import { db } from '../../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import { TextField, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Select, MenuItem, Typography, Box, Modal } from '@mui/material';
import { Auth } from '@firebase/auth';

interface FeedbackFormProps {
  onClose?: () => void;
  userTopic: string;
  userGrade: string;
  auth: Auth;
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const FeedbackForm: React.FC<FeedbackFormProps> = ({ userTopic, userGrade, onClose, auth }) => {
  const [firstName, setFirstName] = useState('');
  const [topic, setTopic] = useState('');
  const [grade, setGrade] = useState('');
  const [satisfaction, setSatisfaction] = useState<number | null>(null);
  const [additionalFeedback, setAdditionalFeedback] = useState('');
  const [generateNewOutput, setGenerateNewOutput] = useState<string | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    setTopic(userTopic);
    setGrade(userGrade);
  }, [userTopic, userGrade]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const user = auth.currentUser;
      if (!user) {
        console.error("User not authenticated");
        return; // Prevent submission if user is not authenticated
      }
      const userId = user.uid;
      await addDoc(collection(db, 'feedbacks', userId, 'userFeedbacks'), {
        firstName,
        topic,
        grade,
        satisfaction,
        additionalFeedback,
        generateNewOutput,
      });
      if (generateNewOutput === 'Sure, let\'s try again') {
        setModalIsOpen(false);
        if (onClose) onClose();
      } else {
        setModalIsOpen(true); // Open modal after successful submission
      }
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setFirstName('');
    setTopic('');
    setGrade('');
    setSatisfaction(null);
    setAdditionalFeedback('');
    setGenerateNewOutput(null);
    if(onClose){
      onClose();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography variant="h6" gutterBottom>Feedback Form</Typography>
        <Typography variant="body1" gutterBottom>Thank you {firstName} for taking the time to give us your detailed feedback! Feedback like this is critical for us to build the right product.</Typography>
        <TextField
          fullWidth
          label="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Topic you were exploring"
          value={topic}
          onChange={(e) => setTopic(e.target.value)}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Grade you were looking for"
          value={grade}
          onChange={(e) => setGrade(e.target.value)}
          margin="normal"
          required
        />
        <FormControl fullWidth margin="normal" required>
          <FormLabel>On a scale of 1-5: how satisfied were you with the output:</FormLabel>
          <Select
            value={satisfaction ?? ''}
            onChange={(e) => setSatisfaction(Number(e.target.value))}
          >
            <MenuItem value="" disabled>Select...</MenuItem>
            {[1, 2, 3, 4, 5].map((num) => (
              <MenuItem key={num} value={num}>{num}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {satisfaction && satisfaction <= 3 && (
          <>
            <Typography variant="body1" gutterBottom>We value your input and want to make sure we're meeting the high expectations of educators. Tell us more about what you were hoping for, and we'll use your feedback to make our platform even more helpful.</Typography>
            <TextField
              fullWidth
              label="Additional Feedback"
              value={additionalFeedback}
              onChange={(e) => setAdditionalFeedback(e.target.value)}
              margin="normal"
              multiline
              rows={4}
            />
            <Typography variant="body1" gutterBottom>We're sorry we missed the mark this time and want to make things right. Would you like to generate a new set of output?</Typography>
            <RadioGroup
              value={generateNewOutput}
              onChange={(e) => setGenerateNewOutput(e.target.value)}
              row
            >
              <FormControlLabel value="No, not for now" control={<Radio />} label="No, not for now" />
              <FormControlLabel value="Sure, let's try again" control={<Radio />} label="Sure, let's try again" />
            </RadioGroup>
          </>
        )}
        <Button type="submit" variant="contained" color="primary">Submit</Button>
      </form>
      <Modal
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6" component="h2">Thank You!</Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>Thank you for your feedback! We appreciate your input and will use it to improve our platform.</Typography>
          <Button onClick={closeModal} variant="contained" color="primary" sx={{ mt: 2 }}>Close</Button>
        </Box>
      </Modal>
    </>
  );
};

export default FeedbackForm;
