import React from 'react';
import { Button, Spin } from 'antd';

interface NavigationProps {
  currentQuestionIndex: number;
  questionsLength: number;
  totalExpectedQuestions: number; // New prop to track total expected questions
  isAnswered: boolean;
  showingFeedback: boolean;
  loadingSimilarQuestion: boolean;
  isLastQuestion: boolean;
  allQuestionsLoaded: boolean; // New prop to check if all questions are loaded
  onPrevious: () => void;
  onNext: () => void;
  onCheckAnswer: () => void;
  onRequestSimilarQuestion: () => void;
}

const QuizNavigation: React.FC<NavigationProps> = ({
  currentQuestionIndex,
  questionsLength,
  totalExpectedQuestions,
  isAnswered,
  showingFeedback,
  loadingSimilarQuestion,
  isLastQuestion,
  allQuestionsLoaded,
  onPrevious,
  onNext,
  onCheckAnswer,
  onRequestSimilarQuestion
}) => {
  // Before answering - centered prominent Check Answer button with subtle Previous
  if (!showingFeedback) {
    return (
      <div className="navigation-container">
        <Button 
          onClick={onPrevious}
          disabled={currentQuestionIndex === 0}
          className="secondary-button"
          icon={<span className="nav-icon">←</span>}
        >
          Previous
        </Button>
        
        <div className="primary-button-container">
          <Button 
            type="primary"
            onClick={onCheckAnswer}
            disabled={!isAnswered}
            className="primary-button check-button"
            size="large"
          >
            Check Answer
          </Button>
        </div>
      </div>
    );
  }
  
  // After answering - show previous, similar question and next buttons
  return (
    <div className="navigation-buttons-feedback">
      <Button 
        onClick={onPrevious}
        disabled={currentQuestionIndex === 0}
        className="left-button"
        icon={<span className="nav-icon">←</span>}
      >
        Previous
      </Button>
      
      <div className="right-buttons">
        <Button 
          type="default"
          onClick={onRequestSimilarQuestion}
          disabled={loadingSimilarQuestion}
          className="similar-button"
        >
          Try Similar Question
        </Button>
        
        {/* Show different button states based on question loading status */}
        {isLastQuestion && !allQuestionsLoaded ? (
          <Button 
            type="primary"
            className="next-button"
            disabled={true}
          >
            <Spin size="small" style={{ marginRight: 8 }} /> Loading More Questions...
          </Button>
        ) : (
          <Button 
            type="primary"
            onClick={onNext}
            className="next-button"
          >
            {isLastQuestion && allQuestionsLoaded ? 'Finish Quiz' : 'Next Question'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default QuizNavigation;