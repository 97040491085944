/**
 * Central configuration for application routes
 */

// Define all valid routes in the application
export const validRoutes = [
  "/",
  "/login",
  "/views",
  "/features",
  "/classroom",
  "/my-content",
  "/detail",
  "/generate-details",
  "/edit-info",
  "/student/assignment",
  "/quiz",
];

// Define routes that should be protected (require authentication)
export const protectedRoutes = [
  "/views",
  "/features",
  "/detail",
  "/generate-details",
  "/edit-info",
  "/my-content",
  "/student/assignment",
  "/quiz",
];

// Define special routes with special handling
export const specialRoutes = {
  classroom: "/classroom",
  login: "/login",
  home: "/",
  default: "/", // Changed default route after login to landing
};

/**
 * Check if a path is valid based on our route configuration
 * @param pathname The current path to check
 * @returns boolean indicating if the path is valid
 */
export const isValidRoute = (pathname: string): boolean => {
  return validRoutes.some((route) => {
    if (pathname === route) return true;
    if (route === "/detail" && pathname.startsWith("/detail/")) return true;
    if (route === "/quiz" && pathname.startsWith("/quiz/")) return true;
    if (
      route === "/student/assignment" &&
      pathname.startsWith("/student/assignment/")
    )
      return true;
    return false;
  });
};

/**
 * Get the appropriate redirect path based on authentication status
 * @param isAuthenticated Whether the user is authenticated
 * @returns The path to redirect to
 */
export const getRedirectPath = (isAuthenticated: boolean): string => {
  return isAuthenticated ? specialRoutes.default : specialRoutes.login;
};
