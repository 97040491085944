import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Col, Row, Typography, Spin, Alert, Layout, Menu, Dropdown, Button, Affix } from 'antd';
import axios from 'axios';
import { getAuth, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { BookOutlined, CheckCircleOutlined, CustomerServiceOutlined, ReadOutlined, SmileOutlined, FileTextOutlined, LogoutOutlined, EditOutlined, UserOutlined, BulbOutlined } from '@ant-design/icons';
import AdditionalInfoForm, { AdditionalInfo } from '../components/forms/AdditionalInfoForm';
import LoginFromBackgroundImage from '../assets/images/login-from-background-image.png'
import ViewCardImage1 from '../assets/images/our-key-features-icon1.png'
// import ViewCardImage1 from './assets/images/our-key-features-icon1.png'
import ViewCardImage2 from '../assets/images/our-key-features-icon2.png'
import ViewCardImage3 from '../assets/images/our-key-features-icon3.png'
import ViewCardImage4 from '../assets/images/our-key-features-icon4.png'
import ViewCardImage5 from '../assets/images/our-key-features-icon5.png'
import ViewCardImage6 from '../assets/images/our-key-features-icon6.png'
import ViewCardImage7 from '../assets/images/our-key-features-icon7.png'
const { Title, Paragraph } = Typography;
const { Content } = Layout;

interface CardData {
  id: string;
  title: string;
  description: string;
  type: string;
  gradeLevel: string;
  numberOfQuestions: number;
  additionalInfo: string;
}
const iconMap: { [key: string]: { icon: JSX.Element; bgColor: string } } = {
  lesson_plan: { icon:  <img src={ViewCardImage1} alt="" />, bgColor: '#21b573' }, // DodgerBlue
  MCQ: { icon: <img src={ViewCardImage5} alt="" />, bgColor: '#21b573' }, // LimeGreen
  song: { icon: <img src={ViewCardImage4} alt="" />, bgColor: '#21b573' }, // OrangeRed
  summarizer: { icon: <img src={ViewCardImage2} alt="" />, bgColor: '#21b573' }, // Gold
  activity: { icon: <img src={ViewCardImage3} alt="" />, bgColor: '#21b573' }, // HotPink
  assignment: { icon: <img src={ViewCardImage6} alt="" />, bgColor: '#21b573' }, // BlueViolet
  case_study: { icon: <img src={ViewCardImage7} alt="" />, bgColor: '#21b573' }, // DarkOrange
};

const View: React.FC = () => {
  const [cardData, setCardData] = useState<CardData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [userName, setUserName] = useState<string | null>(null);
  const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfo | null>(null);
  const api_uri = process.env.REACT_APP_API_URI ?? "";
  //const api_uri = "http://127.0.0.1:8000"; // Local development server --- DELETE LATER
  const navigate = useNavigate();
  const analytics = getAnalytics();

  useEffect(() => {
    document.title = 'Cards View';
    logEvent(analytics, 'page_view', { page: 'Cards View' });

    const fetchCardData = async () => {
      try {
        const response = await axios.get(`${api_uri}/cards`);
        setCardData(response.data);
      } catch (error) {
        console.error('Error fetching card data:', error);
        setError('Failed to load data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchCardData();
  }, [api_uri, analytics]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const auth = getAuth();
      const db = getFirestore();
      if (auth.currentUser) {
        const userDoc = doc(db, 'users', auth.currentUser.uid);
        const userSnapshot = await getDoc(userDoc);
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setUserName(userData.name);
        }
      }
    };

    fetchUserInfo();
  }, []);

  const handleEditInfo = async () => {
    const auth = getAuth();
    const db = getFirestore();
    if (auth.currentUser) {
      const userDoc = doc(db, 'users', auth.currentUser.uid);
      const userSnapshot = await getDoc(userDoc);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data() as AdditionalInfo;
        setAdditionalInfo(userData);
        navigate('/edit-info', { state: { additionalInfo: userData } });
      }
    }
  };

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      setError('Error logging out.');
      console.error('Error logging out:', error);
    }
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <Alert message={error} type="error" />
      </div>
    );
  }

  return (
    <section className='views-card-section'>
        <div className="login-from-background-image">
          <img src={LoginFromBackgroundImage} alt="" />
        </div>
        <div className="container">
            <div className="row">
              <Layout >
                <Content>
                  <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                    <Title className='views-welcome-title-text' level={2} style={{ fontWeight: 'bold', color: '#1890ff', fontSize: '24px' }}>
                      Welcome {userName}
                    </Title>
                  </div>

                  <Title className='title-bottom-text' level={4} style={{ textAlign: 'center', marginBottom: '20px', fontWeight: 'bold', color: '#1890ff', fontSize: '18px' }}>
                    What would you like to do today?
                  </Title>

                  <div className='row justify-content-center'>
                    {cardData.map((card) => {
                      const cardTypeInfo = iconMap[card.id] || { icon: null, bgColor: '#fff' }; // Default bg color if type is not in iconMap
                      return (
                        <div className='co-xl-3 col-lg-3 col-md-6 view-card-call-section'  key={card.id}>
                          <Link to={`/detail/${card.id}?title=${card.title}`} style={{ textDecoration: 'none' }} aria-label={`View details for ${card.title}`}>
                            <Card className='view-cards'
                              hoverable
                              style={{
                                transition: 'transform 0.3s, box-shadow 0.3s',
                                height: '100%',
                                cursor: 'pointer',
                                backgroundColor: card.id === 'assignment' ? '#4285f4' : cardTypeInfo.bgColor,
                              }}
                            >
                              <div className='key-features-slider-card-icon-title'>
                                <div className="key-features-slider-icon">{cardTypeInfo.icon}</div>
                              <Card.Meta
                                title={<Title className='card-title-text' level={5} style={{ color: 'white' }}>{card.title}</Title>}
                              />
                              </div>
                              <Card.Meta
                                description={<Paragraph className='card-paragraph-text' style={{ color: 'white' }} ellipsis={{ rows: 6, expandable: false }}>{card.description}</Paragraph>}
                              />
                              <div className="key-features-slider-card-bottom-icon"><i className="fa-solid fa-arrow-right-long"></i></div>
                            </Card>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </Content>
              </Layout>
            </div>
        </div>
    </section>
  );
};

export default View;
