import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import QuizRoute from '../../features/quiz/QuizRoute';

interface QuizRouteWrapperProps {
  isClassroomAssignment?: boolean;
}

const QuizRouteWrapper: React.FC<QuizRouteWrapperProps> = ({ isClassroomAssignment }) => {
  const location = useLocation();
  const navigate = useNavigate();
  
  return (
    <QuizRoute
      location={location}
      navigate={navigate}
      isClassroomAssignment={isClassroomAssignment}
    />
  );
};

export default QuizRouteWrapper; 