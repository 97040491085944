import React, { useState } from 'react';
import { Card, Progress, Button, Typography, Space, Tag, Row, Col, Divider, Tooltip } from 'antd';
import { RocketOutlined, BookOutlined, BulbOutlined, ArrowLeftOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Title, Text, Paragraph } = Typography;

interface QuizResultsProps {
  results: {
    answers: Record<number, string>;
    totalQuestions: number;
    score: number;
  };
  questions: any[];
  topic: string;
  onRetake: () => void;
  onRequestNewQuiz: (difficulty: string) => void;
}

// Performance tier definitions
const PERFORMANCE_TIERS = {
  CHALLENGE_SEEKER: { min: 80, max: 100, name: 'Challenge Seeker', color: 'green' },
  STEADY_LEARNER: { min: 60, max: 79.99, name: 'Steady Learner', color: 'blue' },
  BUILDING_FOUNDATIONS: { min: 0, max: 59.99, name: 'Building Foundations', color: 'orange' }
};

const ImprovedQuizResults: React.FC<QuizResultsProps> = ({ 
  results, 
  questions, 
  topic,
  onRetake,
  onRequestNewQuiz 
}) => {
  const { answers, totalQuestions, score } = results;
  const percentage = (score / totalQuestions) * 100;
  const navigate = useNavigate();
  const [showDetailedAnalysis, setShowDetailedAnalysis] = useState(false);

  // Get performance tier based on percentage
  const getPerformanceTier = () => {
    if (percentage >= PERFORMANCE_TIERS.CHALLENGE_SEEKER.min) 
      return PERFORMANCE_TIERS.CHALLENGE_SEEKER;
    if (percentage >= PERFORMANCE_TIERS.STEADY_LEARNER.min) 
      return PERFORMANCE_TIERS.STEADY_LEARNER;
    return PERFORMANCE_TIERS.BUILDING_FOUNDATIONS;
  };

  const performanceTier = getPerformanceTier();

  const getFeedbackMessage = () => {
    if (percentage === 100) return "Perfect score! Outstanding performance! 🏆";
    if (percentage >= PERFORMANCE_TIERS.CHALLENGE_SEEKER.min) 
      return "Excellent work! You've got a strong grasp of the material! 🌟";
    if (percentage >= PERFORMANCE_TIERS.STEADY_LEARNER.min) 
      return "Good job! Keep practicing to improve further! 💪";
    return "Keep learning! Every attempt helps you grow! 📚";
  };

  const getRecommendationMessage = () => {
    if (percentage >= PERFORMANCE_TIERS.CHALLENGE_SEEKER.min) {
      return `Your performance shows you've mastered the basics of ${topic}. Let's challenge you with more advanced concepts and deepen your understanding.`;
    }
    if (percentage >= PERFORMANCE_TIERS.STEADY_LEARNER.min) {
      return `You're showing good understanding of ${topic}. Let's continue building your knowledge with a mix of concepts, focusing on your weaker areas.`;
    }
    return `Let's focus on strengthening your understanding of the core concepts in ${topic} before moving to more complex ideas. We'll tailor practice to help you build confidence.`;
  };

  // Analyze performance to find strengths and areas for improvement
  const analyzePerformance = () => {
    const complexityMap: Record<string, { total: number, correct: number }> = {};
    const categoryMap: Record<string, { total: number, correct: number }> = {};
    
    questions.forEach(question => {
      // Track by complexity
      if (!complexityMap[question.complexity]) {
        complexityMap[question.complexity] = { total: 0, correct: 0 };
      }
      complexityMap[question.complexity].total++;
      if (answers[question.number] === question.correctAnswer) {
        complexityMap[question.complexity].correct++;
      }
      
      // Track by category
      if (!categoryMap[question.category]) {
        categoryMap[question.category] = { total: 0, correct: 0 };
      }
      categoryMap[question.category].total++;
      if (answers[question.number] === question.correctAnswer) {
        categoryMap[question.category].correct++;
      }
    });
    
    const strengths: string[] = [];
    const weaknesses: string[] = [];
    // This will be sent to the backend for personalization
    const weakCategories: string[] = [];
    
    // Check complexities
    Object.entries(complexityMap).forEach(([complexity, stats]) => {
      const percentCorrect = (stats.correct / stats.total) * 100;
      if (percentCorrect >= 80) {
        strengths.push(`${complexity} difficulty questions`);
      } else if (percentCorrect <= 50 && stats.total >= 2) {
        weaknesses.push(`${complexity} difficulty questions`);
      }
    });
    
    // Check categories
    Object.entries(categoryMap).forEach(([category, stats]) => {
      const percentCorrect = (stats.correct / stats.total) * 100;
      if (percentCorrect >= 80) {
        strengths.push(`${category} questions`);
      } else if (percentCorrect <= 50 && stats.total >= 2) {
        weaknesses.push(`${category} questions`);
        weakCategories.push(category); // Store weak categories for backend
      }
    });
    
    // Determine recommended difficulty level based on performance
    let recommendedDifficulty = 'Mix';
    if (percentage >= PERFORMANCE_TIERS.CHALLENGE_SEEKER.min) {
      recommendedDifficulty = 'Hard';
    } else if (percentage >= PERFORMANCE_TIERS.STEADY_LEARNER.min) {
      recommendedDifficulty = 'Medium';
    } else {
      recommendedDifficulty = 'Easy';
    }
    
    return {
      strengths,
      weaknesses,
      weakCategories, // Added for personalization
      recommendedDifficulty,
      complexityMap,
      categoryMap
    };
  };

  const { strengths, weaknesses, weakCategories, recommendedDifficulty, complexityMap, categoryMap } = analyzePerformance();

  return (
    <Card className="quiz-results-container" bordered={false}>
      <Row gutter={[24, 24]} align="middle">
        {/* Left column with score and basic actions */}
        <Col xs={24} md={8} className="text-center">
          <div className="score-section">
            <Progress
              type="circle"
              percent={Math.round(percentage)}
              format={() => `${score}/${totalQuestions}`}
              width={120}
              status={percentage >= 70 ? "success" : percentage >= 40 ? "normal" : "exception"}
            />
            <Title level={3} className="mt-4">{getFeedbackMessage()}</Title>
            
            <Space direction="vertical" className="mt-4 w-full">
              <Button onClick={onRetake} icon={<BookOutlined />} size="large" block>
                Retake Quiz
              </Button>
              <Button 
                onClick={() => navigate('/views')}
                icon={<ArrowLeftOutlined />}
                size="large"
                block
              >
                Back to Dashboard
              </Button>
              <Button 
                onClick={() => setShowDetailedAnalysis(!showDetailedAnalysis)} 
                icon={<InfoCircleOutlined />}
                size="large"
                block
              >
                {showDetailedAnalysis ? "Hide Detailed Analysis" : "Show Detailed Analysis"}
              </Button>
            </Space>
          </div>
        </Col>
        
        {/* Right column with learning path and recommendations */}
        <Col xs={24} md={16}>
          <div className="learning-path-section">
            <div className="flex items-center">
              <Title level={2} className="mb-0">Your Personalized Learning Path</Title>
              <Tag color={performanceTier.color} className="ml-3 text-base">
                {performanceTier.name}
              </Tag>
            </div>
            
            <Paragraph className="recommendation-message">
              {getRecommendationMessage()}
            </Paragraph>
            
            <Row gutter={[16, 16]}>
              <Col xs={24} md={weaknesses.length > 0 ? 12 : 24}>
                {strengths.length > 0 && (
                  <div className="insight-section">
                    <Text strong>Your Strengths:</Text>
                    <ul className="mt-2">
                      {strengths.map((strength, index) => (
                        <li key={`strength-${index}`}>{strength}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </Col>
              
              {weaknesses.length > 0 && (
                <Col xs={24} md={12}>
                  <div className="insight-section">
                    <Text strong className="improvement-title">Focus Areas:</Text>
                    <Tooltip title="Your next quiz will be personalized to help you improve in these areas">
                      <InfoCircleOutlined style={{ marginLeft: 8 }} />
                    </Tooltip>
                    <ul className="mt-2">
                      {weaknesses.map((weakness, index) => (
                        <li key={`weakness-${index}`} className="focus-area-item">{weakness}</li>
                      ))}
                    </ul>
                  </div>
                </Col>
              )}
            </Row>
            
            {showDetailedAnalysis && (
              <div className="detailed-analysis">
                <Divider orientation="left">Detailed Performance Analysis</Divider>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Card title="Performance by Category" size="small">
                      {Object.entries(categoryMap).map(([category, stats]) => {
                        const percentCorrect = (stats.correct / stats.total) * 100;
                        return (
                          <div key={category} className="performance-item">
                            <Text>{category}</Text>
                            <Progress 
                              percent={Math.round(percentCorrect)} 
                              size="small"
                              status={percentCorrect >= 70 ? "success" : percentCorrect >= 40 ? "normal" : "exception"}
                            />
                            <Text type="secondary">{stats.correct}/{stats.total} correct</Text>
                          </div>
                        );
                      })}
                    </Card>
                  </Col>
                  <Col span={24}>
                    <Card title="Performance by Difficulty" size="small">
                      {Object.entries(complexityMap).map(([complexity, stats]) => {
                        const percentCorrect = (stats.correct / stats.total) * 100;
                        return (
                          <div key={complexity} className="performance-item">
                            <Text>{complexity}</Text>
                            <Progress 
                              percent={Math.round(percentCorrect)} 
                              size="small"
                              status={percentCorrect >= 70 ? "success" : percentCorrect >= 40 ? "normal" : "exception"}
                            />
                            <Text type="secondary">{stats.correct}/{stats.total} correct</Text>
                          </div>
                        );
                      })}
                    </Card>
                  </Col>
                </Row>
              </div>
            )}
            
            <Divider orientation="left">
              Recommended Next Steps
              <Tooltip title="These recommendations are personalized based on your performance">
                <InfoCircleOutlined style={{ marginLeft: 8 }} />
              </Tooltip>
            </Divider>
            
            <Row gutter={[16, 16]}>
              <Col xs={24} md={8}>
                <Button 
                  type="primary" 
                  icon={<RocketOutlined />}
                  onClick={() => onRequestNewQuiz('hard')}
                  className="recommendation-btn challenge-btn"
                  block
                  disabled={percentage < 60}
                >
                  Challenge Me
                </Button>
                {percentage < 60 && (
                  <Text type="secondary" className="recommendation-note">
                    Master the basics first
                  </Text>
                )}
              </Col>
              <Col xs={24} md={8}>
                <Button 
                  type="primary" 
                  icon={<BookOutlined />}
                  onClick={() => onRequestNewQuiz('medium')}
                  className="recommendation-btn balanced-btn"
                  block
                >
                  Balanced Practice
                </Button>
                <Text type="secondary" className="recommendation-note">
                  Recommended for your level
                </Text>
              </Col>
              <Col xs={24} md={8}>
                <Button 
                  type="primary" 
                  icon={<BulbOutlined />}
                  onClick={() => onRequestNewQuiz('easy')}
                  className="recommendation-btn foundations-btn"
                  block
                >
                  Build Foundations
                </Button>
                <Text type="secondary" className="recommendation-note">
                  Focus on core concepts
                </Text>
              </Col>
            </Row>
            
            <div className="personalization-note mt-4">
              <Text type="secondary">
                <InfoCircleOutlined style={{ marginRight: 8 }} />
                Your next quiz will be personalized based on your performance and focus areas
              </Text>
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default ImprovedQuizResults;