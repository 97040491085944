import React from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import FooterLogo from '../../../assets/images/logo.png'
function Footer() {
    const navigate = useNavigate();
    // State to track if we need to scroll after navigation
    const [scrollToSection, setScrollToSection] = React.useState<string | null>(null);
    
    const scrollToSectionOnLoad = (sectionId: string) => {
        console.log('scrollToSectionOnLoad', sectionId);
        let element = null;
        element = document.getElementById(sectionId);
        // if(sectionId == 'key-features'){
        //   element = document.getElementById('key-features-title');
        // }else{
        //   element = document.getElementById(sectionId);
        // }
        console.log(element);
        const headerOffset = 250; // Default header height offset for scrolling

        // Check if the target element is on the same page
        const isSamePage = window.location.pathname === '/'; // Adjust with your actual page path

        // Adjust offset based on whether the navigation is within the same page or a new one
        const dynamicOffset = isSamePage ? headerOffset : 100; // Example: Adjust the value for different pages

        if (element) {
            console.log('element.getBoundingClientRect().top', element.getBoundingClientRect().top);
            console.log('window.scrollY', window.scrollY);
            const extrascroll = (sectionId == 'key-features') ? 400 : 0;
            const elementPosition = element.getBoundingClientRect().top + window.scrollY + extrascroll;
            const offsetPosition = elementPosition - dynamicOffset; // Use dynamicOffset based on the page

            console.log('scrollToSectionOnLoad elementPosition', elementPosition);
            console.log('scrollToSectionOnLoad offsetPosition', offsetPosition);

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }
    };
    const handleClick = (sectionId: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (location.pathname !== '/') {
            // Set the section to scroll to after navigating to home page

            setScrollToSection(sectionId);
            navigate('/');

        } else {
            // If already on home page, scroll to the section directly
            scrollToSectionOnLoad(sectionId);
        }
    };
    const location = useLocation();
    return (
        <section className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="footer-logo">
                            <Link to="/"><img src={FooterLogo} alt="" /></Link>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="footer-quick-link">
                            <ul>
                                <li><Link className={`${location.pathname === '/' ? 'footer-active-link' : ''}`} to="/" onClick={handleClick('home-banner')}>Home</Link></li>
                                <li><Link className={`${location.pathname === '/about' ? 'footer-active-link' : ''}`} to="/" onClick={handleClick('about-us')}>About us</Link></li>
                                <li><Link className={`${location.pathname === '/service' ? 'footer-active-link' : ''}`} to="/service" onClick={handleClick('key-features')}>Key Features</Link></li>
                                <li><Link className={`${location.pathname === '/contact' ? 'footer-active-link' : ''}`} to="/contact" onClick={handleClick('contact-us')}>Contact</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="footer-support-email">
                            <Link to="mailto:Support@praro.ai">
                                <div className="footer-support-mail-icon">
                                    <i className="fa-solid fa-envelope"></i>
                                </div>
                                <div className="footer-support-mail-text">
                                    <h5>Support@praro.ai</h5>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copy-right-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="footer-copy-right-text">
                                <p>Copyright © 2024 All rights reserved.Praro</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Footer;