import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../features/auth/AuthProvider';
import { 
  ProtectedRoute, 
  ClassroomRoute, 
  ClassroomPageWrapper,
  QuizRouteWrapper,
  AdditionalInfoFormWrapper
} from '../components/routing';
import { logWithTimestamp } from '../utils/logging';
import { isValidRoute, getRedirectPath, specialRoutes } from './routeConfig';

// Components
import Login from '../features/auth/Login';
import Views from '../pages/View';
import Detail from '../pages/Detail';
import HomePage from '../Template/HomePage';
import GenerateDetails from '../pages/GenerateDetails';
import MyContent from '../pages/MyContent';
import LandingView from '../pages/LandingView';

const AppRoutes: React.FC = () => {
  const { user } = useAuth();
  const location = useLocation();
  
  // Add logging to catch-all route
  const handleUnmatchedRoute = () => {
    logWithTimestamp('UnmatchedRoute', 'Starting check');
    logWithTimestamp('UnmatchedRoute', 'Current path:', location.pathname);
    logWithTimestamp('UnmatchedRoute', 'User authenticated:', !!user);
    
    // Check if we're on classroom page or should be on classroom page
    const onClassroomPage = sessionStorage.getItem('on_classroom_page') === 'true';
    logWithTimestamp('UnmatchedRoute', 'onClassroomPage flag:', onClassroomPage);
    
    // If we have the classroom flag set but somehow got to another route, go back to classroom
    if (onClassroomPage && location.pathname !== specialRoutes.classroom) {
      logWithTimestamp('UnmatchedRoute', 'CRITICAL: Classroom flag is set but path is wrong, restoring');
      // Use history API for immediate effect
      window.history.replaceState(null, '', specialRoutes.classroom);
      // And return the wrapper to ensure correct rendering
      return <ClassroomPageWrapper />;
    }
    
    // Special case: exact match for classroom route or subpaths
    if (location.pathname === specialRoutes.classroom || location.pathname.startsWith(`${specialRoutes.classroom}/`)) {
      logWithTimestamp('UnmatchedRoute', 'On classroom path - returning without redirect');
      // For classroom paths, we just render the page directly without redirects
      return <ClassroomPageWrapper />;
    }
    
    // Check if the route is valid
    if (isValidRoute(location.pathname)) {
      logWithTimestamp('UnmatchedRoute', 'Current path is valid, not redirecting');
      return <></>;
    }
    
    // Only redirect unmatched routes
    const targetPath = getRedirectPath(!!user);
    logWithTimestamp('UnmatchedRoute', `Redirecting to ${targetPath}`);
    
    return (
      <Navigate to={targetPath} state={{ from: location }} replace />
    );
  };

  return (
    <Routes>
      {/* Public routes */}
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<Login />} />
      
      {/* Protected routes */}
      <Route path="/detail/:id" element={<ProtectedRoute element={<Detail />} />} />
      <Route path="/generate-details" element={<ProtectedRoute element={<GenerateDetails />} />} />
      <Route path="/edit-info" element={<ProtectedRoute element={<AdditionalInfoFormWrapper />} />} />
      <Route path="/my-content" element={<ProtectedRoute element={<MyContent />} />} />
      <Route path="/views" element={<ProtectedRoute element={<Views />} />} />
      <Route path="/features" element={<ProtectedRoute element={<LandingView />} />} />
      <Route 
        path="/classroom" 
        element={<ClassroomRoute />} 
      />
      <Route 
        path="/student/assignment/:id" 
        element={<ProtectedRoute element={<QuizRouteWrapper isClassroomAssignment={true} />} />}
      />
      <Route 
        path="/quiz/:id" 
        element={<ProtectedRoute element={<QuizRouteWrapper />} />}
      />
      
      {/* Redirect unmatched routes */}
      <Route path="*" element={handleUnmatchedRoute()} />
    </Routes>
  );
};

export default AppRoutes; 