import React from 'react';
import { IconButton } from '@mui/material';
import { ThumbUp, ThumbDown } from "@mui/icons-material";
import { FeedbackSectionProps } from '../../types/responseCard.types';

const FeedbackSection: React.FC<FeedbackSectionProps> = ({ feedback, onSaveSignal }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
      <div>
        <IconButton
          onClick={(event) => onSaveSignal(event, 'like')}
          aria-label="like"
          style={{
            backgroundColor: feedback === 'like' ? '#e6f7ff' : 'transparent',
            color: feedback === 'like' ? '#1890ff' : 'inherit'
          }}
        >
          <ThumbUp />
        </IconButton>
        <IconButton
          onClick={(event) => onSaveSignal(event, 'dislike')}
          aria-label="dislike"
          style={{
            backgroundColor: feedback === 'dislike' ? '#fff1f0' : 'transparent',
            color: feedback === 'dislike' ? '#ff4d4f' : 'inherit'
          }}
        >
          <ThumbDown />
        </IconButton>
      </div>
    </div>
  );
};

export default FeedbackSection;