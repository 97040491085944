import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Card, Progress, Alert, Button, Typography, Spin } from 'antd';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import ImprovedQuizResults from './ImprovedQuizResults';
import './ImprovedQuizResults.css';  // Add the CSS import
import CustomOptions from '../../components/common/CustomOptions';
import QuizNavigation from './QuizNavigation';
import LoadingState from '../../components/common/LoadingState';
import QuizRecommendations from './QuizRecommendations';
import 'katex/dist/katex.min.css';
import './Quiz.css';
import './QuizRecommendations.css';

const { Text } = Typography;

interface QuizQuestion {
  number: number;
  text: string;
  options: Record<string, string>;
  category: string;
  complexity: string;
  correctAnswer?: string;
  explanation?: string;
}

interface StreamingQuizProps {
  content: string;
  setResponse?: (response: string) => void;
  wsUri?: string;
  topic?: string;
  grade?: string;
  standard?: string;
  subject?: string;
  isClassroomAssignment?: boolean;
}

const StreamingQuiz: React.FC<StreamingQuizProps> = ({ 
  content, 
  setResponse, 
  wsUri,
  topic,
  grade,
  standard,
  subject,
  isClassroomAssignment = false
}) => {
  const [questions, setQuestions] = useState<QuizQuestion[]>([]);
  const [currentProgress, setCurrentProgress] = useState({ current: 0, total: 5 });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState<Record<number, string>>({});
  const [showFeedback, setShowFeedback] = useState<Record<number, boolean>>({});
  const dataBuffer = useRef<string>('');
  const [quizComplete, setQuizComplete] = useState(false);
  const [showingRecommendations, setShowingRecommendations] = useState(false);
  const [quizResults, setQuizResults] = useState({
    answers: {} as Record<number, string>,
    totalQuestions: 0,
    score: 0,
  });
  
  // Personalization debugging
  const [personalizationInfo, setPersonalizationInfo] = useState<any>(null);
  const [showPersonalizationInfo, setShowPersonalizationInfo] = useState(false);
  
  // Similar question states
  const [showingSimilarQuestion, setShowingSimilarQuestion] = useState(false);
  const [similarQuestion, setSimilarQuestion] = useState<QuizQuestion | null>(null);
  const [selectedSimilarAnswer, setSelectedSimilarAnswer] = useState<string>('');
  const [showSimilarFeedback, setShowSimilarFeedback] = useState(false);
  const [loadingSimilarQuestion, setLoadingSimilarQuestion] = useState(false);
  const websocketRef = useRef<WebSocket | null>(null);

  // Store the actual values from props in state to ensure they're preserved
  const [quizTopic, setQuizTopic] = useState(topic || "");
  const [quizGrade, setQuizGrade] = useState(grade || "");
  const [quizStandard, setQuizStandard] = useState(standard || "");
  const [quizSubject, setQuizSubject] = useState(subject || "");

  // Log the actual values when the component mounts
  useEffect(() => {
    console.log("Quiz initialized with:", {
      topic: quizTopic,
      grade: quizGrade,
      standard: quizStandard,
      subject: quizSubject
    });
  }, [quizTopic, quizGrade, quizStandard, quizSubject]);

  // Process incoming quiz data
  useEffect(() => {
    dataBuffer.current += content;

    try {
      let startIdx = dataBuffer.current.indexOf('{');
      while (startIdx !== -1) {
        try {
          const subString = dataBuffer.current.slice(startIdx);
          const parsed = JSON.parse(subString);
          
          if (parsed.type === 'question' && parsed.data) {
            const questionData = {
              ...parsed.data,
              correctAnswer: parsed.data.correctAnswer || 'A',
            };

            setQuestions(prev => {
              if (!prev.some(q => q.number === questionData.number)) {
                return [...prev, questionData];
              }
              return prev;
            });
            setLoading(false);
          } else if (parsed.type === 'progress') {
            setCurrentProgress({
              current: parsed.current || 0,
              total: parsed.total || 5
            });
          } else if (parsed.type === 'debug') {
            // Store personalization debug info
            console.log("Received personalization debug info:", parsed);
            setPersonalizationInfo(parsed);
            setShowPersonalizationInfo(true);
          } else if (parsed.type === 'similar_question' && parsed.data) {
            setSimilarQuestion(parsed.data);
            setLoadingSimilarQuestion(false);
          } else if (parsed.error) {
            setError(parsed.error);
            setLoadingSimilarQuestion(false);
          }

          dataBuffer.current = dataBuffer.current.slice(startIdx + JSON.stringify(parsed).length);
          startIdx = dataBuffer.current.indexOf('{');
        } catch (e) {
          startIdx = dataBuffer.current.indexOf('{', startIdx + 1);
        }
      }
    } catch (error) {
      if (!(error instanceof SyntaxError)) {
        setError('Error processing quiz data');
      }
    }
  }, [content]);

  // Function to render math content
  const renderMathContent = (text: string) => (
    <ReactMarkdown
      remarkPlugins={[remarkGfm, remarkMath]}
      rehypePlugins={[rehypeKatex]}
      components={{
        p: ({node, ...props}) => <span {...props} />
      }}
    >
      {text}
    </ReactMarkdown>
  );

  // Function to request a similar question
  const requestSimilarQuestion = () => {
    if (!wsUri) {
      setError('WebSocket URI is not configured.');
      return;
    }

    setLoadingSimilarQuestion(true);
    
    // Create a new WebSocket connection for the similar question request
    const websocket = new WebSocket(wsUri);
    websocketRef.current = websocket;

    websocket.onopen = () => {
      const currentQuestion = questions[currentQuestionIndex];
      const message = JSON.stringify({
        request_type: "similar_question",
        question: currentQuestion
      });
      websocket.send(message);
    };

    websocket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        
        if (data.type === 'similar_question' && data.data) {
          setSimilarQuestion(data.data);
          setShowingSimilarQuestion(true);
          setLoadingSimilarQuestion(false);
        } else if (data.error) {
          setError(`Error: ${data.error}`);
          setLoadingSimilarQuestion(false);
        }
      } catch (e) {
        // Check if it's the end marker
        if (event.data === "__END__") {
          if (!similarQuestion) {
            setError('No similar question received');
          }
          websocket.close();
        }
      }
    };

    websocket.onerror = () => {
      setError('An error occurred while requesting a similar question.');
      setLoadingSimilarQuestion(false);
    };

    websocket.onclose = () => {
      setLoadingSimilarQuestion(false);
    };
  };

  // Handle answer selection - FIXED to use question.number as key
  const handleAnswerSelect = (value: string) => {
    if (showingSimilarQuestion) {
      setSelectedSimilarAnswer(value);
    } else {
      // Get current question and use its number as the key
      const currentQuestion = questions[currentQuestionIndex];
      setSelectedAnswers(prev => ({
        ...prev,
        [currentQuestion.number]: value
      }));
    }
  };

  // Handle checking the answer
  const handleCheckAnswer = () => {
    if (showingSimilarQuestion) {
      setShowSimilarFeedback(true);
    } else {
      const currentQuestion = questions[currentQuestionIndex];
      setShowFeedback(prev => ({
        ...prev,
        [currentQuestion.number]: true
      }));
    }
  };

  // Handle going to the next question
  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(prev => prev + 1);
      // Use question number for tracking feedback state
      const nextQuestion = questions[currentQuestionIndex + 1];
      if (nextQuestion) {
        setShowFeedback(prev => ({ ...prev, [nextQuestion.number]: false }));
      }
    } else {
      // FIXED: Calculate quiz results using question.number as key
      const correctAnswers = questions.filter(
        (question) => selectedAnswers[question.number] === question.correctAnswer
      ).length;
  
      setQuizResults({
        answers: selectedAnswers,
        totalQuestions: questions.length,
        score: correctAnswers,
      });
      
      console.log("Quiz complete! Score:", correctAnswers, "/", questions.length);
      console.log("Selected answers:", selectedAnswers);
      console.log("Questions:", questions.map(q => ({ 
        number: q.number, 
        correctAnswer: q.correctAnswer,
        userAnswer: selectedAnswers[q.number],
        isCorrect: selectedAnswers[q.number] === q.correctAnswer
      })));
      
      setQuizComplete(true);
    }
  };

  // Handle going to the previous question
  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(prev => prev - 1);
    }
  };

  // Handle returning from a similar question
  const handleReturnFromSimilarQuestion = () => {
    setShowingSimilarQuestion(false);
    setSimilarQuestion(null);
    setSelectedSimilarAnswer('');
    setShowSimilarFeedback(false);

    // Close the websocket if it's still open
    if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
      websocketRef.current.close();
    }
  };

  // ENHANCED: Complete reset for quiz retake
  const handleRetakeQuiz = () => {
    setQuizComplete(false);
    setCurrentQuestionIndex(0);
    setSelectedAnswers({});
    setShowFeedback({});
    setShowingRecommendations(false);
    setQuizResults({
      answers: {},
      totalQuestions: 0,
      score: 0,
    });
    // Clear data buffer to prevent mixing old and new data
    dataBuffer.current = '';
  };

  // Function to handle requesting a new quiz with specified difficulty
  const handleRequestNewQuiz = (difficulty: string) => {
    // Reset the quiz state
    setQuestions([]);
    setCurrentQuestionIndex(0);
    setSelectedAnswers({});
    setShowFeedback({});
    setQuizComplete(false);
    setShowingRecommendations(false);
    setLoading(true);
    // Clear data buffer
    dataBuffer.current = '';
    
    // If we have a WebSocket URI, create a new connection to request questions
    if (wsUri) {      
      // Close any existing connection
      if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
        websocketRef.current.close();
      }
      
      // Create a new WebSocket connection
      const websocket = new WebSocket(wsUri);
      websocketRef.current = websocket;
      
      websocket.onopen = () => {
        // Create performance data for analysis
        const performanceData = {
          score: quizResults.score,
          totalQuestions: quizResults.totalQuestions,
          answeredQuestions: questions.map(question => ({
            number: question.number,
            complexity: question.complexity,
            category: question.category,
            correct: quizResults.answers[question.number] === question.correctAnswer
          }))
        };

        // Analyze strengths and weaknesses
        const categoryMap: Record<string, { total: number, correct: number }> = {};
        const complexityMap: Record<string, { total: number, correct: number }> = {};
        
        performanceData.answeredQuestions.forEach(question => {
          // Track by category
          if (!categoryMap[question.category]) {
            categoryMap[question.category] = { total: 0, correct: 0 };
          }
          categoryMap[question.category].total++;
          if (question.correct) {
            categoryMap[question.category].correct++;
          }
          
          // Track by complexity
          if (!complexityMap[question.complexity]) {
            complexityMap[question.complexity] = { total: 0, correct: 0 };
          }
          complexityMap[question.complexity].total++;
          if (question.correct) {
            complexityMap[question.complexity].correct++;
          }
        });
        
        // Find weak categories
        const weakCategories: string[] = [];
        Object.entries(categoryMap).forEach(([category, stats]) => {
          const percentCorrect = (stats.correct / stats.total) * 100;
          if (percentCorrect <= 50 && stats.total >= 2) {
            weakCategories.push(category);
          }
        });
        
        // Find weak complexity levels
        const weakComplexities: string[] = [];
        Object.entries(complexityMap).forEach(([complexity, stats]) => {
          const percentCorrect = (stats.correct / stats.total) * 100;
          if (percentCorrect <= 50 && stats.total >= 2) {
            weakComplexities.push(complexity);
          }
        });

        // Prepare additional details with focus areas
        let additionalDetails = `Focus on ${difficulty} difficulty questions.`;
        if (weakCategories.length > 0) {
          additionalDetails += ` Pay special attention to these areas: ${weakCategories.join(', ')}.`;
        }

        console.log("Sending personalized quiz request with previous performance data");
        console.log("Weak categories:", weakCategories);
        console.log("Weak complexities:", weakComplexities);
        console.log("Category performance:", Object.entries(categoryMap).map(([name, stats]) => ({
          name,
          correct: stats.correct,
          total: stats.total,
          percentCorrect: (stats.correct / stats.total) * 100
        })));
        
        // Prepare message with the requested difficulty and previous performance data
        const message = JSON.stringify({
          topic: quizTopic, // Use the stored topic value
          grade: quizGrade, // Use the stored grade value
          standard: quizStandard, // Use the stored standard value
          subject: quizSubject, // Use the stored subject value
          additionalDetails,
          questionComplexity: difficulty,
          card_id: 'quiz',
          previousPerformance: {
            score: quizResults.score,
            totalQuestions: quizResults.totalQuestions,
            weakCategories,
            weakComplexities,
            categoryPerformance: Object.entries(categoryMap).map(([name, stats]) => ({
              name,
              correct: stats.correct,
              total: stats.total,
              percentCorrect: (stats.correct / stats.total) * 100
            })),
            complexityPerformance: Object.entries(complexityMap).map(([name, stats]) => ({
              name,
              correct: stats.correct,
              total: stats.total,
              percentCorrect: (stats.correct / stats.total) * 100
            }))
          }
        });
        
        // Log the request payload for debugging
        console.log("Sending quiz request with values:", {
          topic: quizTopic,
          grade: quizGrade,
          standard: quizStandard,
          subject: quizSubject
        });
        
        websocket.send(message);
        console.log("WebSocket message sent successfully");
      };
      
      // Set up message handling (fixed to avoid TypeScript errors)
      websocket.onmessage = (event) => {
        if (setResponse && typeof event.data === 'string') {
          // Directly pass the new data
          setResponse(event.data);
        }
      };
      
      websocket.onerror = () => {
        setError('An error occurred while requesting a new quiz.');
        setLoading(false);
      };
      
      websocket.onclose = () => {
        // Processing will continue via the content prop updates
      };
    } else {
      setError('WebSocket URI is not configured.');
      setLoading(false);
    }
  };

  // Compute if all questions are loaded
  const allQuestionsLoaded = questions.length >= currentProgress.total;

  // Show quiz results and recommendations
  if (quizComplete) {
    return (
      <ImprovedQuizResults
        results={quizResults}
        questions={questions}
        topic={quizTopic} // Use the stored topic value
        onRetake={handleRetakeQuiz}
        onRequestNewQuiz={handleRequestNewQuiz}
      />
    );
  }

  // Show loading spinner when loading a similar question
  if (loadingSimilarQuestion) {
    return <LoadingState type="similar" />;
  }

  // Show loading state when no questions are loaded yet
  const currentQuestion = questions[currentQuestionIndex];
  if (!currentQuestion) {
    return (
      <div>
        <LoadingState type="initial" progress={currentProgress} />
        
        {/* Display personalization information for debugging */}
        {showPersonalizationInfo && personalizationInfo && (
          <Alert
            message="Quiz Personalization Active"
            description={
              <div>
                <p>Your quiz is being personalized based on your previous performance.</p>
                <ul>
                  <li><strong>Focus Areas:</strong> {typeof personalizationInfo.details.focusAreas === 'string' 
                    ? personalizationInfo.details.focusAreas 
                    : Array.isArray(personalizationInfo.details.focusAreas) 
                      ? personalizationInfo.details.focusAreas.join(', ') 
                      : 'None'}</li>
                  <li><strong>Complexity Focus:</strong> {personalizationInfo.details.complexityFocus}</li>
                  <li><strong>Previous Score:</strong> {personalizationInfo.details.previousScore}</li>
                </ul>
                <Button size="small" onClick={() => setShowPersonalizationInfo(false)}>Hide</Button>
              </div>
            }
            type="info"
            showIcon
            style={{ marginTop: 16, marginBottom: 16 }}
          />
        )}
      </div>
    );
  }

  // Compute UI state variables
  const isAnswered = selectedAnswers[currentQuestion.number] !== undefined;
  const showingFeedback = showFeedback[currentQuestion.number];
  const isCorrect = selectedAnswers[currentQuestion.number] === currentQuestion?.correctAnswer;
  const isLastQuestion = currentQuestionIndex === questions.length - 1;

  // Handle the case when showing a similar question
  if (showingSimilarQuestion && similarQuestion) {
    return (
      <div className="quiz-container">
        {error && (
          <Alert
            message="Error"
            description={error}
            type="error"
            showIcon
            closable
            onClose={() => setError(null)}
            className="mb-4"
          />
        )}
        
        <Card>
          <div className="similar-question-header">
            <span className="similar-question-badge">Similar Question</span>
            <Button onClick={handleReturnFromSimilarQuestion} className="return-button">
              Return to Quiz
            </Button>
          </div>
          
          <div className="question-container">
            <div className="question-text">
              {renderMathContent(similarQuestion.text)}
            </div>
          </div>
          
          <CustomOptions
            options={similarQuestion.options}
            selectedOption={selectedSimilarAnswer}
            correctAnswer={showSimilarFeedback ? similarQuestion.correctAnswer : undefined}
            showFeedback={showSimilarFeedback}
            onSelectOption={handleAnswerSelect}
          />
          
          {showSimilarFeedback && (
            <div className={`feedback-banner ${selectedSimilarAnswer === similarQuestion.correctAnswer ? 'correct-feedback' : 'incorrect-feedback'}`}>
              <div className="feedback-header">
                <div className="feedback-icon">
                  {selectedSimilarAnswer === similarQuestion.correctAnswer ? (
                    <svg viewBox="0 0 24 24" fill="none" width="18" height="18">
                      <path d="M5 13l4 4L19 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  ) : (
                    <svg viewBox="0 0 24 24" fill="none" width="18" height="18">
                      <path d="M6 18L18 6M6 6l12 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  )}
                </div>
                <div className="feedback-title">
                  {selectedSimilarAnswer === similarQuestion.correctAnswer ? 'Correct!' : 'Incorrect'}
                </div>
              </div>
              <div className="feedback-content">
                {selectedSimilarAnswer === similarQuestion.correctAnswer ? (
                  <p>Great job! You've correctly answered this similar question.</p>
                ) : (
                  <p>The correct answer is {similarQuestion.correctAnswer}.</p>
                )}
                {similarQuestion.explanation && (
                  <div className="feedback-explanation">
                    <strong>Explanation:</strong> {renderMathContent(similarQuestion.explanation)}
                  </div>
                )}
              </div>
            </div>
          )}
          
          <div className="navigation-buttons-feedback">
            <Button 
              type="default"
              onClick={handleReturnFromSimilarQuestion}
              className="left-button"
            >
              Back to Original Question
            </Button>
            
            <div className="right-buttons">
              {!showSimilarFeedback ? (
                <Button 
                  type="primary"
                  onClick={handleCheckAnswer}
                  disabled={!selectedSimilarAnswer}
                >
                  Check Answer
                </Button>
              ) : (
                <Button 
                  type="primary"
                  onClick={handleReturnFromSimilarQuestion}
                >
                  Continue Quiz
                </Button>
              )}
            </div>
          </div>
        </Card>
      </div>
    );
  }

  // Main quiz interface
  return (
    <div className="quiz-container">
      {error && (
        <Alert
          message="Error"
          description={error}
          type="error"
          showIcon
          closable
          onClose={() => setError(null)}
          className="mb-4"
        />
      )}
      
      <div className="progress-container">
        <div className="progress-info">
          <span className="question-counter">Question {currentQuestion.number} of {currentProgress.total}</span>
          <span className="progress-percentage">{Math.round((currentProgress.current / currentProgress.total) * 100)}%</span>
        </div>
        <Progress 
          percent={(currentProgress.current / currentProgress.total) * 100} 
          size="small" 
          showInfo={false}
          strokeColor="#21b573"
        />
      </div>
      
      <div className="question-container">
        <div className="question-text">
          {renderMathContent(currentQuestion.text)}
        </div>
      </div>
      
      <CustomOptions
        options={currentQuestion.options}
        selectedOption={selectedAnswers[currentQuestion.number]}
        correctAnswer={showingFeedback ? currentQuestion.correctAnswer : undefined}
        showFeedback={showingFeedback}
        onSelectOption={handleAnswerSelect}
      />
      
      {showingFeedback && (
        <div className={`feedback-banner ${isCorrect ? 'correct-feedback' : 'incorrect-feedback'}`}>
          <div className="feedback-header">
            <div className="feedback-icon">
              {isCorrect ? (
                <svg viewBox="0 0 24 24" fill="none" width="18" height="18">
                  <path d="M5 13l4 4L19 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              ) : (
                <svg viewBox="0 0 24 24" fill="none" width="18" height="18">
                  <path d="M6 18L18 6M6 6l12 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              )}
            </div>
            <div className="feedback-title">
              {isCorrect ? 'Correct!' : 'Incorrect'}
            </div>
          </div>
          <div className="feedback-content">
            {isCorrect ? (
              <p>Great job! You've selected the right answer.</p>
            ) : (
              <p>The correct answer is {currentQuestion.correctAnswer}.</p>
            )}
            {currentQuestion.explanation && (
              <div className="feedback-explanation">
                <strong>Explanation:</strong> {renderMathContent(currentQuestion.explanation)}
              </div>
            )}
          </div>
        </div>
      )}
      
      <QuizNavigation
        currentQuestionIndex={currentQuestionIndex}
        questionsLength={questions.length}
        totalExpectedQuestions={currentProgress.total}
        isAnswered={isAnswered}
        showingFeedback={showingFeedback}
        loadingSimilarQuestion={loadingSimilarQuestion}
        isLastQuestion={isLastQuestion}
        allQuestionsLoaded={allQuestionsLoaded}
        onPrevious={handlePrevious}
        onNext={handleNext}
        onCheckAnswer={handleCheckAnswer}
        onRequestSimilarQuestion={requestSimilarQuestion}
      />
    </div>
  );
};

export default StreamingQuiz;