import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { collection, query, where, getDocs, orderBy, getFirestore, doc, deleteDoc } from 'firebase/firestore';
import { Card } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';
import PublishIcon from '@mui/icons-material/Publish';
import './DocumentLinksList.css';
import FirebaseClassroomService from '../../services/FirebaseClassroomService';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

interface DocumentLink {
  id: string;
  userId: string;
  documentUrl: string;
  topic: string;
  cardId: string;
  createdAt: Date;
  title?: string;
}

interface GroupedDocuments {
  [topic: string]: DocumentLink[];
}

interface ClassroomCourse {
  id: string;
  name: string;
  section?: string;
  description?: string;
}

const DocumentLinksList = () => {
  const [documentLinks, setDocumentLinks] = useState<GroupedDocuments>({});
  const [loading, setLoading] = useState(true);
  const [copiedId, setCopiedId] = useState<string | null>(null);
  const [expandedTopics, setExpandedTopics] = useState<Set<string>>(new Set());
  const auth = getAuth();
  const db = getFirestore();
  
  // Google Classroom integration states
  const [publishModalOpen, setPublishModalOpen] = useState(false);
  const [selectedDocumentLink, setSelectedDocumentLink] = useState<DocumentLink | null>(null);
  const [courses, setCourses] = useState<ClassroomCourse[]>([]);
  const [selectedCourseId, setSelectedCourseId] = useState<string>('');
  const [googleConnected, setGoogleConnected] = useState<boolean>(false);
  const [classroomLoading, setClassroomLoading] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [classroomService] = useState(new FirebaseClassroomService());

  const getCardType = (cardId: string) => {
    switch(cardId) {
      case 'lesson_plan':
        return 'Lesson Plan';
      case 'MCQ':
        return 'Multiple Choice Questions';
      case 'assignment':
        return 'Assignment';
      default:
        return cardId;
    }
  };

  const fetchDocuments = async () => {
    if (!auth.currentUser) return;
  
    try {
      setLoading(true);
      const q = query(
        collection(db, 'documentLinks'),
        where('userId', '==', auth.currentUser.uid),
        orderBy('createdAt', 'desc')
      );
  
      const querySnapshot = await getDocs(q);
      const documents = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          userId: data.userId,
          documentUrl: data.documentUrl,
          topic: data.topic,
          cardId: data.cardId,
          createdAt: data.createdAt?.toDate() || new Date(),
          title: data.title
        };
      });
  
      // Group documents by topic (case insensitive)
      const grouped = documents.reduce((acc: GroupedDocuments, doc) => {
        // Normalize the topic case (convert to lowercase for comparison)
        const normalizedTopic = doc.topic.toLowerCase();
        
        // Use the original topic case for display but group by normalized case
        const existingTopic = Object.keys(acc).find(key => key.toLowerCase() === normalizedTopic);
        const topicKey = existingTopic || doc.topic; // Use existing case if found, otherwise use original
  
        if (!acc[topicKey]) {
          acc[topicKey] = [];
        }
        acc[topicKey].push(doc);
        return acc;
      }, {});
  
      // Sort documents within each topic by date
      Object.keys(grouped).forEach(topic => {
        grouped[topic].sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
      });
  
      setDocumentLinks(grouped);
      setExpandedTopics(new Set(Object.keys(grouped)));
    } catch (error) {
      console.error('Error fetching documents:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [auth.currentUser]);

  const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }).format(date);
  };

  const handleCopyLink = async (documentUrl: string, docId: string) => {
    try {
      await navigator.clipboard.writeText(documentUrl);
      setCopiedId(docId);
      setTimeout(() => setCopiedId(null), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleDelete = async (documentId: string) => {
    if (!auth.currentUser) return;
    
    try {
      if (window.confirm('Are you sure you want to delete this document?')) {
        // Log for debugging
        console.log('Deleting document with ID:', documentId);
        await deleteDoc(doc(db, 'documentLinks', documentId));
        console.log('Document deleted successfully');
        fetchDocuments(); // Refresh the document list
      }
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  const handlePublish = (doc: DocumentLink) => {
    setSelectedDocumentLink(doc);
    checkClassroomConnection();
    setPublishModalOpen(true);
  };

  const handleClosePublishModal = () => {
    setPublishModalOpen(false);
    setSelectedDocumentLink(null);
    setSelectedCourseId('');
    setErrorMessage(null);
  };

  const checkClassroomConnection = async () => {
    try {
      setClassroomLoading(true);
      const accessResult = await classroomService.checkClassroomAccess();
      
      if (accessResult.hasAccess) {
        setGoogleConnected(true);
        
        // Check token status
        const tokenStatus = await classroomService.checkTokens();
        console.log('Token status:', tokenStatus);
        
        if (!tokenStatus.hasTokens) {
          console.warn('No Google Classroom tokens found for this user');
          setErrorMessage('Your Google connection needs to be refreshed. Please reconnect to Google Classroom.');
          setGoogleConnected(false);
          return;
        }
        
        if (tokenStatus.isExpired) {
          console.warn('Google Classroom tokens are expired');
          setErrorMessage('Your Google connection has expired. Please reconnect to Google Classroom.');
          setGoogleConnected(false);
          return;
        }
        
        if (!tokenStatus.hasClassroomCoursesScope) {
          console.warn('Missing classroom.courses.readonly scope');
          setErrorMessage('Your Google connection is missing required permissions. Please reconnect to Google Classroom.');
          setGoogleConnected(false);
          return;
        }
        
        // If we get here, the token is valid
        await fetchCourses();
      } else {
        setGoogleConnected(false);
        setErrorMessage('Please connect to Google Classroom first.');
      }
    } catch (error: any) {
      console.error('Error checking Google Classroom connection:', error);
      setErrorMessage(`Error connecting to Google Classroom: ${error.message}`);
      setGoogleConnected(false);
    } finally {
      setClassroomLoading(false);
    }
  };

  const fetchCourses = async () => {
    try {
      setClassroomLoading(true);
      console.log('Fetching courses from Google Classroom');
      const fetchedCourses = await classroomService.getCourses();
      console.log(`Fetched ${fetchedCourses.length} courses from Google Classroom:`, fetchedCourses);
      setCourses(fetchedCourses);
      
      if (fetchedCourses.length === 0) {
        setErrorMessage('No courses found in your Google Classroom account. Please make sure you have created courses in Google Classroom and have the appropriate permissions.');
      }
      
      return fetchedCourses;
    } catch (error: any) {
      console.error('Error fetching courses:', error);
      setErrorMessage(`Error fetching courses from Google Classroom: ${error.message || 'Unknown error'}`);
      return [];
    } finally {
      setClassroomLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setClassroomLoading(true);
      const accessResult = await classroomService.checkClassroomAccess();
      
      if (accessResult.hasAccess) {
        setGoogleConnected(true);
        await fetchCourses();
      } else {
        // Redirect to consent flow or show error
        setErrorMessage('Please complete the Google Classroom authorization flow.');
      }
    } catch (error: any) {
      console.error('Error connecting to Google Classroom:', error);
      setErrorMessage(`Error connecting to Google Classroom: ${error.message || 'Please try again.'}`);
    } finally {
      setClassroomLoading(false);
    }
  };

  const handlePublishToClassroom = async () => {
    if (!selectedDocumentLink || !selectedCourseId) {
      setErrorMessage('Please select a Google Classroom course first.');
      return;
    }

    try {
      setClassroomLoading(true);
      console.log('Publishing to Google Classroom...');

      // Get document link (already copied to clipboard)
      const documentUrl = selectedDocumentLink.documentUrl;
      
      // Create title and description
      const title = selectedDocumentLink.title || selectedDocumentLink.topic || 'Shared Document';
      const description = `${getCardType(selectedDocumentLink.cardId)} shared from TeacherBot.\nTopic: ${selectedDocumentLink.topic}`;

      // Create the assignment in Google Classroom
      const classroomAssignment = await classroomService.createAssignment(selectedCourseId, {
        title: title,
        description: description,
        maxPoints: 100,
        materials: [{ 
          link: { 
            url: documentUrl
          } 
        }]
      });

      console.log('Google Classroom assignment created:', classroomAssignment);

      if (classroomAssignment) {
        setPublishModalOpen(false);
        setSuccessModalOpen(true);
        // Reset states
        setSelectedCourseId('');
        setErrorMessage(null);
      }
    } catch (error: any) {
      console.error('Error publishing to Google Classroom:', error);
      setErrorMessage(`Error creating assignment in Google Classroom: ${error.message || 'Unknown error'}`);
    } finally {
      setClassroomLoading(false);
    }
  };

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  const toggleTopic = (topic: string) => {
    const newExpanded = new Set(expandedTopics);
    if (newExpanded.has(topic)) {
      newExpanded.delete(topic);
    } else {
      newExpanded.add(topic);
    }
    setExpandedTopics(newExpanded);
  };

  // Modal styles
  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  if (loading) {
    return (
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Card className="login-form-card">
            <div className="loading-state">
              <div className="loader"></div>
              <p>Loading your documents...</p>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  return (
    <div className="row justify-content-center">
      <div className="col-md-10">
        <div className="content-header">
          <h2>My Content</h2>
          <p>Access all your educational content in one place</p>
        </div>
        
        {Object.keys(documentLinks).length === 0 ? (
          <div className="no-documents">
            <p>No documents generated yet</p>
          </div>
        ) : (
          Object.entries(documentLinks).map(([topic, docs]) => (
            <div key={topic} className="topic-group">
              <div 
                className="topic-header"
                onClick={() => toggleTopic(topic)}
              >
                <h3>{topic}</h3>
                {expandedTopics.has(topic) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div>
              {expandedTopics.has(topic) && (
                <div className="documents-table">
                  <div className="table-header">
                    <div className="col-type">Type</div>
                    <div className="col-actions">Actions</div>
                    <div className="col-date">Created On</div>
                  </div>
                  
                  {docs.map((doc) => (
                    <div key={doc.id} className="table-row">
                      <div className="col-type">
                        <span className="document-type">{getCardType(doc.cardId)}</span>
                      </div>
                      <div className="col-actions">
                        {/* <button 
                          className="action-btn copy-btn"
                          onClick={() => handleCopyLink(doc.documentUrl, doc.id)}
                          title="Copy link"
                        >
                          {copiedId === doc.id ? (
                            <span className="copied-text">Copied!</span>
                          ) : (
                            <ContentCopyIcon />
                          )}
                        </button> */}
                        <a
                          href={doc.documentUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="action-btn open-btn"
                          title="Open document"
                        >
                          {/* <OpenInNewIcon /> */}
                          <span>Open</span>
                        </a>
                        <button 
                          className="action-btn publish-btn"
                          onClick={() => handlePublish(doc)}
                          title="Publish to classroom"
                        >
                          {/* <PublishIcon /> */}
                          <span>Publish</span>
                        </button>
                        {/* <button 
                          className="action-btn delete-btn"
                          onClick={() => handleDelete(doc.id)}
                          title="Delete document"
                        >
                          <DeleteIcon />
                        </button> */}
                      </div>
                      <div className="col-date">{formatDate(doc.createdAt)}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))
        )}
      </div>

      {/* Google Classroom Publish Modal */}
      <Modal
        open={publishModalOpen}
        onClose={handleClosePublishModal}
        aria-labelledby="google-classroom-modal-title"
      >
        <Box sx={modalStyle}>
          <Typography id="google-classroom-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
            Publish to Google Classroom
          </Typography>
          
          {errorMessage && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errorMessage}
            </Alert>
          )}
          
          {!googleConnected ? (
            <div>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Connect to Google Classroom to share this document with your students.
              </Typography>
              <Button 
                variant="contained" 
                onClick={handleGoogleSignIn}
                disabled={classroomLoading}
                sx={{ mt: 1 }}
              >
                {classroomLoading ? <CircularProgress size={24} /> : 'Connect to Google Classroom'}
              </Button>
            </div>
          ) : (
            <div>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Select a classroom to publish the document: 
                {selectedDocumentLink?.title || selectedDocumentLink?.topic}
              </Typography>
              
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="classroom-select-label">Select Classroom</InputLabel>
                <Select
                  labelId="classroom-select-label"
                  value={selectedCourseId}
                  label="Select Classroom"
                  onChange={(e) => setSelectedCourseId(e.target.value)}
                  disabled={classroomLoading || courses.length === 0}
                >
                  {courses.map((course) => (
                    <MenuItem key={course.id} value={course.id}>
                      {course.name} {course.section ? `(${course.section})` : ''}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              
              {classroomLoading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                  <CircularProgress />
                </Box>
              )}
              
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button onClick={handleClosePublishModal} disabled={classroomLoading}>
                  Cancel
                </Button>
                <Button 
                  variant="contained" 
                  onClick={handlePublishToClassroom}
                  disabled={!selectedCourseId || classroomLoading}
                >
                  Publish
                </Button>
              </Box>
            </div>
          )}
        </Box>
      </Modal>
      
      {/* Success Modal */}
      <Modal
        open={successModalOpen}
        onClose={handleCloseSuccessModal}
        aria-labelledby="success-modal-title"
      >
        <Box sx={modalStyle}>
          <Typography id="success-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
            Published Successfully
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Your document has been published to Google Classroom successfully!
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" onClick={handleCloseSuccessModal}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DocumentLinksList;