import React, { useState, useEffect, useRef } from 'react';
import { Card, Spin, Alert, Button } from 'antd';
import StreamingQuiz from './StreamingQuiz';
import { useParams } from 'react-router-dom';
import axios from 'axios';

interface QuizParams {
  topic: string;
  grade: string;
  standard: string;
  subject: string;
  additionalDetails?: string;
  questionComplexity?: string;
  card_id: string;
}

interface QuizRouteProps {
  location: any;
  navigate: (path: string) => void;
  isClassroomAssignment?: boolean;
}

const QuizRoute: React.FC<QuizRouteProps> = ({ location, navigate, isClassroomAssignment = false }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [quizContent, setQuizContent] = useState<string>('');
  const [wsUri, setWsUri] = useState<string>('');
  const [quizParams, setQuizParams] = useState<QuizParams | null>(null);
  const websocketRef = useRef<WebSocket | null>(null);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    // Handle cases where this is a Google Classroom assignment
    if (isClassroomAssignment && id) {
      fetchClassroomAssignment(id);
      return;
    }
    
    // Original logic for regular quizzes
    const params = location.state?.quizParams as QuizParams;
    
    if (!params) {
      setError('Missing quiz parameters');
      setLoading(false);
      return;
    }
    
    // Store quiz parameters in state
    setQuizParams(params);

    // Get WebSocket URI from environment or use a default
    const uri = process.env.REACT_APP_WS_URI || '';
    setWsUri(uri);

    if (!uri) {
      setError('WebSocket URI is not configured.');
      setLoading(false);
      return;
    }

    const messageData = {
      card_id: 'quiz',
      topic: params.topic.trim(),
      grade: params.grade.trim(),
      standard: params.standard.trim(),
      subject: params.subject.trim(),
      context: params.topic.trim() + (params.additionalDetails ? '. ' + params.additionalDetails.trim() : ''),
      question_complexity: (params.questionComplexity || 'mix').trim(),
      history: [],
      recent_context: ''
    };

    connectWebSocket(messageData, uri);
  }, [location.state, id, isClassroomAssignment]);

  // Function to fetch assignment details from Google Classroom
  const fetchClassroomAssignment = async (assignmentId: string) => {
    try {
      setLoading(true);
      
      // Fetch the assignment details from your backend API
      const response = await axios.get(`/api/assignments/${assignmentId}`);
      
      if (response.data && response.data.assignment) {
        const assignment = response.data.assignment;
        
        // Convert the assignment to quiz format for StreamingQuiz component
        const quizContent = convertAssignmentToQuizFormat(assignment);
        setQuizContent(quizContent);
        
        // Set parameters for recommendations
        setQuizParams({
          topic: assignment.title,
          grade: assignment.gradeLevel,
          standard: '', // Not applicable for classroom assignments
          subject: assignment.subject,
          card_id: assignmentId
        });
      } else {
        setError('Assignment not found');
      }
    } catch (error) {
      console.error('Error fetching classroom assignment:', error);
      setError('Error loading assignment. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Function to convert assignment data to the format expected by StreamingQuiz
  const convertAssignmentToQuizFormat = (assignment: any) => {
    try {
      // Create a formatted string that matches the parsing logic in StreamingQuiz
      let quiz = `# ${assignment.title}\n\n`;
      quiz += `${assignment.description}\n\n`;
      
      assignment.questions.forEach((question: any, index: number) => {
        quiz += `## Question ${index + 1}\n\n`;
        quiz += `${question.text}\n\n`;
        
        if (question.type === 'multiple-choice' && question.options) {
          quiz += `Options:\n`;
          question.options.forEach((option: string, optIndex: number) => {
            const optionKey = String.fromCharCode(65 + optIndex); // A, B, C, etc.
            quiz += `${optionKey}. ${option}\n`;
          });
          
          // If there's a correct answer and explanation (for display after submission)
          if (question.correctOption !== undefined) {
            const correctOptionKey = String.fromCharCode(65 + question.correctOption);
            quiz += `\nCorrect Answer: ${correctOptionKey}\n`;
            
            if (question.explanation) {
              quiz += `\nExplanation: ${question.explanation}\n`;
            }
          }
        }
        
        quiz += `\n`;
      });
      
      return quiz;
    } catch (error) {
      console.error('Error converting assignment to quiz format:', error);
      return '';
    }
  };

  const connectWebSocket = (messageData: any, uri: string) => {
    try {
      const websocket = new WebSocket(uri);
      websocketRef.current = websocket;

      websocket.onopen = () => {
        websocket.send(JSON.stringify(messageData));
      };

      websocket.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.error) {
            setError(data.error);
            setLoading(false);
            return;
          }
          const formattedData = event.data.replaceAll('^^^', '\n');
          setQuizContent(prev => prev + formattedData);
          setLoading(false);
        } catch (e) {
          const formattedData = event.data.replaceAll('^^^', '\n');
          setQuizContent(prev => prev + formattedData);
          setLoading(false);
        }
      };

      websocket.onerror = () => {
        setError('Error connecting to quiz server');
        setLoading(false);
      };

      websocket.onclose = (event) => {
        if (!event.wasClean) {
          setError('Connection closed unexpectedly');
        }
      };
    } catch (err) {
      setError('Failed to connect to quiz server');
      setLoading(false);
    }
  };

  const handleQuizResponse = (response: string): void => {
    setQuizContent(response);
  };

  if (loading && !quizContent) {
    return (
      <div className="quiz-route-container" style={{ padding: '20px' }}>
        <div className="loading-container">
          <Spin size="large" />
          <div style={{ marginTop: '16px' }}>Loading {isClassroomAssignment ? 'assignment' : 'quiz'}...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="quiz-route-container" style={{ padding: '20px' }}>
      {error ? (
        <Alert
          message="Error"
          description={error}
          type="error"
          showIcon
          action={
            <Button type="primary" onClick={() => navigate('/views')}>
              Return to Dashboard
            </Button>
          }
        />
      ) : (
        <StreamingQuiz 
          content={quizContent}
          setResponse={handleQuizResponse}
          wsUri={wsUri}
          topic={quizParams?.topic || ""}
          grade={quizParams?.grade || ""}
          standard={quizParams?.standard || ""}
          subject={quizParams?.subject || ""}
          isClassroomAssignment={isClassroomAssignment}
        />
      )}
    </div>
  );
};

export default QuizRoute;