import React, { useState, useEffect, useLayoutEffect } from "react";
import HomeBanner from "../pages/home/HomeBanner";
// import HomeBanner from "../   ../Pages/Home/HomeBanner";
import HomeAboutus from "../pages/home/HomeAboutus";
import KeyFeatures from "../pages/home/KeyFeatures";
import HomeContactus from "../pages/home/HomeContactus";

function HomePage() {
  

  return (
    <>
      
      <section>
        <HomeBanner />
      </section>
      <section>
        <HomeAboutus/>
      </section>
      <section  id="key-features">
        <KeyFeatures/>
      </section>
      <section>
        <HomeContactus />
      </section>
    </>
  );
}

export default HomePage;
